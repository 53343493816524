import React, {useState, useEffect} from 'react';
import DataTableBase from './reuseable/DataTableBase';
import DatePicker from './reuseable/DatePicker';
import StationGraphCard from './reuseable/StationGraphCard';

import Select from 'react-select';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';

import XLSX from 'xlsx';
 

const ReportRevenue = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);

    // const [stationMatrix, setStationMatrix] = useState(null);
    // const [stationChartMatrix, setStationChartMatrix] = useState(null);
    // const [chargingStations, setChargingStations] = useState(null);

    const [loader, setLoader] = useState(true);
    const [data, setData] = useState(true);
    const [filteredData, setFilteredData] = useState(true);

    const [tableData, setTableData] = useState(null);
    const [filteredTableData, setFilteredTableData] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [checkPoint, setCheckPoint] = useState(false);

    const [filter, setFilter] = useState(null);

    const [dateRange2, setDateRange2] = useState(null);


    console.log("Selected Option : : : ", selectedOption);


    useEffect(() => {
        function filterDataByDateRange(startDateStr, endDateStr, data) {
            const startDate = new Date(startDateStr);
            const endDate = new Date(endDateStr);
        
            const filteredData = data.filter((item) => {
            const itemDate = new Date(item.startDate);
            return itemDate >= startDate && itemDate <= endDate;
            });

            console.log("Filterd data set : ", filteredData);


            for(let a= 0; a < filteredData?.length; a++){
                console.log("date : ", filteredData[a]?.startDate)
            }
        
            const dateRevenueSessionMap = {};
        
            filteredData.forEach((item) => {
            const itemDate = new Date(item.startDate).toLocaleDateString();
        
            if (!dateRevenueSessionMap[itemDate]) {
                dateRevenueSessionMap[itemDate] = {
                date: itemDate,
                revenue: parseFloat(item.revenue),
                session: 1,
                };
            } else {
                dateRevenueSessionMap[itemDate].revenue += parseFloat(item.revenue);
                dateRevenueSessionMap[itemDate].session += 1;
            }
            });
        
            // Convert the map into an array of objects
            const result = Object.values(dateRevenueSessionMap);
        
            return result;
        }

        if(dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today'){
            // Assuming your data is stored in the 'data' array
            
            // Example usage:
            const startDateStr = "2023-01-01";
            const endDateStr = "2023-03-01";
            const filteredData = filterDataByDateRange(dateRange2?.startDate, dateRange2?.endDate, data);
            console.log(filteredData);
            setTableData(filteredData);
            setFilteredTableData(filteredData);

            
        } else {
            const tabData = calculateTableData(data);

            console.log("Table Data : ", tabData);

            const revTabData = tabData.reverse();

    
            setFilteredTableData(revTabData)
            setTableData(revTabData);
        }
    }, [dateRange2?.startDate])

    
    const caseInsensitiveSort = (rowA, rowB) => {
        const a = rowA?.month?.toLowerCase();
        const b = rowB?.month?.toLowerCase();

        if(a){
            return 1;
        } else {
            if (a > b) {
                return 1;
            }
    
            if (b > a) {
                return -1;
            }
            
        }

        return 0;
    };


    const caseInsensitiveSort2 = (rowA, rowB) => {
        const a = Math.ceil((rowA?.revenue / rowA?.session) * 100) / 100;
        const b = Math.ceil((rowB?.revenue / rowB?.session) * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };


    const caseInsensitiveSort3 = (rowA, rowB) => {
        const a = Math.ceil(rowA?.revenue * 100) / 100;
        const b = Math.ceil(rowB?.revenue * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };



    const columns = [
        {
            name: dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? "Date" : "Month",
            selector: row => dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? row?.date : row.month,
            sortable: true,
            sortFunction: caseInsensitiveSort
        },
        {
            name: "Total Sessions(Times)",
            selector: row => row.session,
            sortable: true,
        },
        {
            name: "Total Revenue(THB)",
            selector: row => (Math.ceil(row.revenue * 100) / 100).toLocaleString(),
            // selector: row => row.revenue,
            sortable: true,
            sortFunction: caseInsensitiveSort3
        },
        {
            name: "Avg. Price per Session (THB)",
            selector: row => (Math.ceil((row?.revenue / row?.session) * 100) / 100).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort2
        },
    ]

    
    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' },
    // ];


    useEffect(() => {
        const token = localStorage.getItem('token');
        // console.log("token found value is : ", token);
        axios.get("https://api.pea-cpo.com/api/getChargerListCpo", {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("Get Charger list for options  : ", res);
            const opArr = [];

            res?.data?.data && res?.data?.data?.map((item) => {
                opArr.push({value: item?.locationEN, label: item?.locationTH});
            })

            console.log("Options options : ", opArr)
            setOptions([{value: 'All', label: 'All'}, ...opArr]);
            // setStations(res?.data?.data);
            
        }).catch((err) =>{
        console.log("Error get user : ", err);
        })
    }, [])




    // https://api.pea-cpo.com/api/overviewPageChargingListByID/1

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://api.pea-cpo.com/api/overviewPageChargingList', {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("Get Charger detail test test : ", res);
            
            setData(res?.data?.data);
            setFilteredData(res?.data?.data);

            const ans= calculateMetrics(res?.data?.data);
            // console.log("Ans data : ", ans);
            setMetrics(ans);

            const ans2 = calculateChartData(res?.data?.data);
            console.log("Ans Two data : : ", ans2)

            setChartData(ans2);

            const tabData = calculateTableData(res?.data?.data);

            console.log("Table Data : ", tabData);

            const revTabData = tabData.reverse();

    

            setTableData(revTabData);
            setFilteredTableData(revTabData);
            
            setLoader(false);
        
        }).catch((err) =>{
            console.log("Error get user : ", err);
            setLoader(false);
        })
    }, [])

    useEffect(() => {
        console.log("Filter val 1: ", filter)
        if(filter === 'Today'){
            console.log("Filter val : ", filter);
            const revenueArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            const metrics = {
                session: 0,
                revenue: 0,
            };

            for (let i = 0; i < data.length; i++) {
                const revenue = parseFloat(data[i]?.revenue);
                const startDate = new Date(data[i]?.startDate);
                const startTime = data[i]?.startTime;

                const today = new Date();
                if(filter === 'Today'){
                    if (
                      startDate.getFullYear() !== today.getFullYear() ||
                      startDate.getMonth() !== today.getMonth() ||
                      startDate.getDate() !== today.getDate()
                    ) {
                      // console.log("Wrong today");
                      continue;
                    }
                }


                if(selectedOption){
                    if(selectedOption?.value !== data[i]?.locationEN && selectedOption?.value !== 'All'){
                        continue;
                    }
                }

                const hour = Number(startTime.slice(0,2));

                revenueArr[hour] += revenue; 

                metrics.session += 1;
                metrics.revenue += revenue;
            }

            console.log("Today Ans data : ", revenueArr);
            setMetrics(metrics);

            console.log("Today Ans Two data : : ", metrics)

            setChartData(revenueArr);
            setCheckPoint(true);
            
        } else {
            const ans= calculateMetrics(data);
            // console.log("Ans data : ", ans);
            setMetrics(ans);
            
            const ans2 = calculateChartData(data);
            console.log("Ans Two data : : ", ans2)
            
            setChartData(ans2);
            setCheckPoint(false);
            console.log("Seleect option else part worked")
        }
    }, [filter, selectedOption])
    
    // function toggleDate(){
    //   if(display.display === 'none')
    //   setDisplay({display: 'block'})
    //   else
    //   setDisplay({display: 'none'})
    // }

    // function handleSelect(date){
    //     setRange({ 
    //         startDate: date.selection.startDate,
    //         endDate: date.selection.endDate,
    //         key: date.selection.key
    //     })
    // }

    function calculateMetrics(para){
        const met = {
            energy: 0,
            revenue: 0,
            session: 0
        };
        console.log("Para : ", para);
        console.log("Para : length", para?.length);
        // if(para?.length > 0){
        //     para?.forEach((item) => {
        //         met.energy += Number(item?.totalKwh);
        //         met.revenue += Number(item?.revenue);
        //         met.session += 1;
        //     })

        // }

        // let countt = 0;
        for(let i = 0; i < para?.length; i++){
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }
            // countt++;
            // console.log("count : ", countt);

            // console.log("date: ", i, startDate);
            if(selectedOption){
                if(selectedOption?.value !== para[i]?.locationEN && selectedOption?.value !== 'All'){
                    continue;
                }
            }
            met.energy += Number(para[i]?.totalKwh);
            met.revenue += Number(para[i]?.revenue);
            met.session += 1;
        }

        return met;
    }

    function calculateChartData(para){
        const revenueArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        console.log("Para para para : : : ", para);
        for(let i = 0; i < para?.length; i++){
            const rev = parseFloat(para[i]?.revenue);
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }

            if(selectedOption){
                if(selectedOption?.value !== para[i]?.locationEN && selectedOption?.value !== 'All'){
                    continue;
                } else {
                    // console.log("selected op : ", selectedOption?.value)
                    // console.log("data name : ", para[i].locationEN)
                    console.log("data name true...");
                    
                }
            }



            const month = startDate.getMonth();
            revenueArr[month] += rev; 
        }

        return revenueArr;
    }

//     const date = new Date();
// const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
// const monthNumber = date.getMonth(); // Returns a zero-based integer (0 for January, 1 for February, etc.)
// const monthName = monthNames[monthNumber];

// console.log("Month number:", monthNumber);
// console.log("Month name:", monthName);

    function calculateTableData(para){
        const dumArr = [ 
            {
                month: 'January',
                session: 0,
                revenue: 0,
            },
            {
                month: 'February',
                session: 0,
                revenue: 0,
            },
            {
                month: 'March',
                session: 0,
                revenue: 0,
            },
            {
                month: 'April',
                session: 0,
                revenue: 0,
            },
            {
                month: 'May',
                session: 0,
                revenue: 0,
            },
            {
                month: 'June',
                session: 0,
                revenue: 0,
            },
            {
                month: 'July',
                session: 0,
                revenue: 0,
            },
            {
                month: 'August',
                session: 0,
                revenue: 0,
            },
            {
                month: 'September',
                session: 0,
                revenue: 0,
            },
            {
                month: 'October',
                session: 0,
                revenue: 0,
            },
            {
                month: 'November',
                session: 0,
                revenue: 0,
            },
            {
                month: 'December',
                session: 0,
                revenue: 0,
            },
        ];

        for(let i = 0; i < para?.length; i++){
            const rev = parseFloat(para[i]?.revenue);
            const startDate = new Date(para[i].startDate);
            const getMon = startDate.getMonth();

            
            if(dateRange2?.option){
                const startRange = new Date(dateRange2?.startDate);
                const endRange = new Date(dateRange2?.endDate);

                if(startDate >= startRange && startDate <= endRange){

                } else {
                    continue;
                }

            } else {
                if (startDate.getFullYear() !== new Date().getFullYear()) {
                  continue;
                }
            }




            

            dumArr[getMon].session +=1;
            dumArr[getMon].revenue += rev;

            // const month = startDate.getMonth();
            // dumArr[month] += rev; 
        }

        const newArr = [];

        dumArr && dumArr.map(item => {
            if(item?.session > 0){
                newArr.push(item);
            }
        })
        return newArr;
    }


    // function convertToWorksheet(tableData) {
    //     const worksheet = XLSX.utils.json_to_sheet(tableData);
    //     return worksheet;
    // }

    function convertToWorksheet(tableData) {
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Set width for all columns to 15 characters
        // worksheet['!cols'] = Array(worksheet['!ref'].split(':')[1].charCodeAt(0) - 64).fill({ width: 25 });
        const columnWidths = [18, 20, 20, 22]

        worksheet['!cols'] = columnWidths.map(width => ({ width }));

        return worksheet;
    }


    function downloadExcelFile(tableData, fileName) {
        const worksheet = convertToWorksheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }

    
    const now = new Date();
    
    const isoDateTime = now.toISOString();
    
    function handleDownloadExcel(fileData) {
        const now = new Date();
        const isoDateTime = now.toISOString();    
        const structuredData2 = [];
        const StructureData = fileData?.filter(item => {
            const obj = {"Month": item?.month ? item?.month : item?.date, "Total Sessions(Times)": Number(item?.session), "Total Revenue(THB)": parseFloat((Math.ceil(item?.revenue * 100)) / 100), "Avg Price Per Session": parseFloat(Math.ceil((item?.revenue / item?.session) * 100) / 100) };
            structuredData2?.push(obj);
            return obj;
            }
        )

        // console.log("Structured data : ", StructureData)

        // console.log("Structured data 2 : ", structuredData2)
        
        downloadExcelFile(structuredData2, `Revenue Report ${isoDateTime}`);
        // if(dateRange2?.option){
        //     const dumArr = [
        //         {
        //             month: null,
        //             session: 0,
        //             revenue: 0,
        //         }
        //     ];


        //     const start = new Date(dateRange2?.startDate);
        //     const end = new Date(dateRange2?.endDate);

        //     data && data.map(obj => {
        //         // console.log("obj : ", obj)
        //         const currentDate = new Date(obj?.startDate);
        //         const rev = parseFloat(obj?.revenue);

        //         if(currentDate >= start && currentDate <= end){
        //             dumArr[0].session +=1;
        //             dumArr[0].revenue += rev;    
        //         }

        //     });

        //     dumArr[0].month = `${dateRange2?.startDate}-${dateRange2?.endDate}`
            
        //     console.log("dumArr data : ", dumArr);

        //     downloadExcelFile(dumArr, `Revenue Report ${isoDateTime}`);
        // } else {
        // }
        // downloadExcelFile(fileData, `Revenue Report ${isoDateTime}`);
    }




    if(loader){
        return (
            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '17vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
            </div>
        )
    } else {

    return (
        <div className='station-revenue'>
            <div className='w-100 d-flex'>
                <div className='w-50'>
                    <h3 className='font-style27 mb-0'>Revenue</h3>
                    <h6 className='font-style28'>ภาพรวมข้อมูลรายได้</h6>
                </div>
                <div className='w-50 d-flex justify-content-end'>
                    {/* <select className="form-select d-inline-block" style={{width: '250px', height: '46px', marginRight: '14px'}} aria-label="Default select example">
                        <option selected>Select option</option>
                        <option value="1">All Station</option>
                        <option value="2">Station Two</option>
                        <option value="3">Station Three</option>
                    </select> */}

                    <Select
                        className="d-inline-block w-50  mt-1" style={{width: '250px', height: '46px'}}
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                    />


                    {/* <button className='btn-style1'>Download</button> */}
                </div>
            </div>
            <StationGraphCard 
                val1={<h3 className='graph-card-number'>{(Math.ceil(metrics?.session * 100) / 100).toLocaleString()} <b className='font-style29'>Times</b></h3>} 
                val2={<h3 className='graph-card-number'>{(Math.ceil(metrics?.revenue * 100) / 100).toLocaleString()}<b className='font-style29'>THB</b></h3>} 
                val3={<h3 className='graph-card-number'>{metrics?.session > 0 ? (Math.ceil((metrics?.revenue / metrics?.session) * 100) / 100).toLocaleString() : 0} <b className='font-style29'>THB</b></h3>} 
                l1="Total Sessions" l2="Total Revenue" l3="Average Time per Session"   
                cols={3}
                statRev={true}
                label="Revenue"
                data2={chartData}
                setFilter={setFilter}
                filter={filter}
                symbol={"BAHT ฿"}
                />
            <div>
            <div className='w-100 d-flex justify-content-end'>
                <div className='w-252 mb-4 pb-2'>
                    <DatePicker dateRange={dateRange2} setDateRange={setDateRange2} />
                </div>
                <div className='ps-3'>
                    <button className='btn-style1' onClick={() => handleDownloadExcel(filteredTableData)}>Download</button>
                </div>
            </div>
                {filteredTableData && (

                    <DataTableBase 
                        columns={columns}
                        data={filteredTableData}
                        title="Revenue"
                        pagination
                    />
                )}
            </div>
        </div>
    )}
}

export default ReportRevenue;