import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { BallTriangle } from 'react-loader-spinner'
import { useParams } from 'react-router-dom'
import '../style/StationOverview.css'
import GoogleMap from './reuseable/GoogleMap'

export default function StationOverview() {
    const [imgPreview, setImgPreview] = useState([])
    const [station, setStation] = useState(null);
    const [location, setLocation] = useState({
        // lat:33.684422,
        // lng: 73.047882
    })

    const {id} = useParams();

    function handleLocation(event){
        const name = event.target.name
        const value = event.target.value
        if(name === 'lat'){
            setLocation({lng: location.lng, lat: parseInt(value)})
        }else if(name === 'lng'){
            setLocation({lat: location.lat, lng: parseInt(value)})
        }
    }

    function handleFile(event){
        if(event.target.files){
            const files = event.target.files
            let array = []
            for(var i=0;i<files.length;i++){
                array.push(URL.createObjectURL(files[i]))
            }
            setImgPreview([...array])
        }
    }

    function removeImg(index){
        const array = []
        for(var i=0;i<imgPreview.length;i++){
            if(i !== index){
                array.push(imgPreview[i])
            }
        }
        setImgPreview([...array])
    }

    useEffect(() => {
        const token = localStorage.getItem('token');

        axios.get(`https://api.pea-cpo.com/api/getChargerListCpoById/${id}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
        console.log("Get Charger detail : ", res);
        
        setStation(res?.data?.data[0]);

        setLocation({
            lat: res?.data?.data[0]?.lat,
            lng: res?.data?.data[0]?.lng
        })
        setImgPreview([res?.data?.data[0]?.chargerPhoto])


        console.log("lat : ", res?.data?.data[0]?.lat)
        console.log("lng : ", res?.data?.data[0]?.lng)

        }).catch((err) =>{
            console.log("Error get user : ", err);
        })
    }, [])


  return (
    <div className='station-overview'>
        <div className='overview-section-heading'>
            <h3>Charger Profile</h3>
            <p>Update your company details and address.</p>
        </div>
        <div className='overview-section'>  
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger ID</h5>
                    <p>This will be displayed to customer.</p>
                </div>
                <div style={{width:'35%'}}>
                    <input className='form-control' readOnly defaultValue={station?.chargerID} placeholder='T54-HU1-3220-008'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger name (TH)</h5>
                    <p>This will be displayed to customer.</p>
                </div>
                <div style={{width:'35%'}}>
                    <input className='form-control' readOnly defaultValue={station?.locationTH} placeholder='PEA VOLTA บางจากทับสะแก #1'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger name (EN)</h5>
                    <p>This will be displayed to customer.</p>
                </div>
                <div style={{width:'35%'}}>
                    <input className='form-control' readOnly defaultValue={station?.locationEN}  placeholder='PEA VOLTA Bangchank TABSAKAE #1'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Address (TH)</h5>
                    <p>Address that available for contact</p>
                </div>
                <div style={{width:'35%'}}>
                    <input className='form-control' readOnly defaultValue={station?.descriptionTH} placeholder='888 ซอยรังสิต - นครนายก 49, ประชาธิปัต '/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Address (EN)</h5>
                    <p>Address that available for contact</p>
                </div>
                <div style={{width:'35%'}}>
                    <input className='form-control' readOnly defaultValue={station?.descriptionEN} placeholder='888 Soi Rangsit - Nakornnayok 49, Prachathipat'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Location</h5>
                    <p>Location of this charger</p>
                </div>
                <div>
                    <div className='station-field-location'>
                        <div>
                            <h6>Latitude</h6>
                            <input className='form-control' defaultValue={location?.lat} readOnly placeholder='14.044750' name='lat' onChange={handleLocation}/>
                        </div>
                        <div>
                            <h6>Longitude</h6>
                            <input className='form-control' defaultValue={location?.lng} readOnly placeholder='100.591728' name='lng' onChange={handleLocation}/>
                        </div>
                    </div>
                    <div className='station-map'>
                        {/* <GoogleMap lat={location.lat} lng={location.lng} width={'40%'} height={'40%'}/> */}
                        {station ? (
                            <GoogleMap lat={station?.lat} lng={station?.lng} width={'40%'} height={'40%'}/>
                        ) : (
                            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '3vh'}}>
                                <BallTriangle
                                    height={70}
                                    width={70}
                                    radius={5}
                                    color="#814097"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperClass={{}}
                                    wrapperStyle=""
                                    visible={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='station-field location' style={{height: '10vh', marginTop: '13vh'}}>
                
                <div className='station-field-div1'>
                    <h5>Charger Picture</h5>
                    <p>This will be displayed on your profile.</p>
                </div>
                <div className='img-preview' style={{zIndex:'10'}} >
                    <div>
                    {imgPreview.length > 0 && imgPreview.map((url, index)=>
                        <div key={index}>
                            <span style={{cursor:'pointer'}} onClick={()=>removeImg(index)}>&times;</span>
                            <img src={url} alt={url}/>
                        </div>
                    )}
                    </div>
                    <input type='file' disabled className='form-control' accept="image/*"  multiple onChange={handleFile}/>
                </div>
            </div>
        </div>
        <div className='overview-section-heading' style={{marginTop: '160px'}}>
            <h4>Charger Information</h4>
            <p>The information of the charger and connector types.</p>
        </div>
        <div className='overview-section'>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger Detail</h5>
                </div>
                <div>
                    <p>Charger name</p>
                    <input className='form-control' defaultValue={station?.locationTH} placeholder='Charger 1'/>
                    <div className='station-field-location'>
                        <div>
                            <p>Charger ID</p>
                            <input className='form-control' readOnly defaultValue={station?.chargerID} placeholder='T54-HU1-3220-008'/>
                        </div>
                        <div>
                            <p>Charger Number</p>
                            <input className='form-control' defaultValue={station?.number} readOnly placeholder='P101'/>
                        </div>
                    </div>
                    <div className='station-field-location'>
                        <div>
                            <p>Brand</p>
                            <input className='form-control' readOnly placeholder='ABB'/>
                        </div>
                        <div>
                            <p>Model</p>
                            <input className='form-control' readOnly placeholder='TERRA CJG-54'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Connector Type</h5>
                </div>
                <div className='station-connector'>
                    {station?.connectors && station?.connectors.map((item, index) => (
                        <div className='station-connector-section my-3' key={index}>
                            <div>
                                <h6>{item?.connectorType}</h6>
                                <p>Type: {item?.PowerType} <br/> Rated Power: {item?.kW} kW</p>
                            </div>
                            {item?.connectorStatusCode === "Available" ? (
                                <div className='status'>
                                    Available
                                </div>
                            ) : item?.connectorStatusCode === "Faulted" ? (
                                <div className='status faulted'>
                                    Faulted
                                </div>
                            ) : item?.connectorStatusCode === "Charging" ? (
                                <div className='status charging'>
                                    Charging
                                </div>
                            ) : (
                                <div className='status text-dark' style={{background:'#C6C6C6'}}>
                                    {item?.connectorStatusCode}
                                </div>

                            )}
                        </div>                        
                    ))}


                    {/* <div className='station-connector-section'>
                        <div>
                            <h6>CCS 2</h6>
                            <p>Type: DC <br/> Rated Power: 50 kW</p>
                        </div>
                        <div className='status'>
                            Available
                        </div>
                    </div> */}
                </div>
            </div>


            <div className='overview-section mt-4 pt-3'>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5>Connector Price</h5>
                    </div>
                    <div style={{width:'35%'}}>
                        <p className='mb-2'>Minimum Price</p>
                        <input className='form-control'  defaultValue={station?.prices?.min_price} readOnly placeholder='Enter Minimum Price'/>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        {/* <h5>Connector Price</h5> */}
                    </div>
                    <div style={{width:'35%'}}>
                        <p className='mb-2'>Maximum Price</p>
                        <input className='form-control'  defaultValue={station?.prices?.max_price} readOnly placeholder='Enter Maximum Price'/>
                    </div>
                </div>
            </div>


            {/* <div className='overview-section mt-4 pt-3'>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5></h5>
                    </div>
                    <div style={{width:'35%'}}>
                        <p className='mb-2'>On Peak Price</p>
                        <input className='form-control'  defaultValue={station?.prices?.onPeak} readOnly placeholder='Enter On Peak Price'/>
                    </div>
                </div>
                <div className='station-field'>
                    <div className='station-field-div1'>
                        <h5></h5>
                    </div>
                    <div style={{width:'35%'}}>
                        <p className='mb-2'>Off Peak Price</p>
                        <input className='form-control'  defaultValue={station?.prices?.offPeak} readOnly placeholder='Enter Off Peak Price'/>
                    </div>
                </div>
            </div> */}

        </div>


        {/* <div className='overview-section' style={{borderTop: '2px solid #E4E7EC', paddingTop: '2rem'}}>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger Detail</h5>
                </div>
                <div>
                    <p>Charger name</p>
                    <input className='form-control' placeholder='Charger 1'/>
                    <div className='station-field-location'>
                        <div>
                            <p>Charger ID</p>
                            <input className='form-control' placeholder='T54-HU1-3220-008'/>
                        </div>
                        <div>
                            <p>Charger Number</p>
                            <input className='form-control' placeholder='P101'/>
                        </div>
                    </div>
                    <div className='station-field-location'>
                        <div>
                            <p>Brand</p>
                            <input className='form-control' placeholder='ABB'/>
                        </div>
                        <div>
                            <p>Model</p>
                            <input className='form-control' placeholder='TERRA CJG-54'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Connector Type</h5>
                </div>
                <div className='station-connector'>
                    <div className='station-connector-section'>
                        <div>
                            <h6>CHAdeMO</h6>
                            <p>Type: DC <br/> Rated Power: 50 kW</p>
                        </div>
                        <div className='status'>
                            Available
                        </div>
                    </div>
                    <div className='station-connector-section'>
                        <div>
                            <h6>CCS 2</h6>
                            <p>Type: DC <br/> Rated Power: 50 kW</p>
                        </div>
                        <div className='status'>
                            Available
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </div>
  )
}
