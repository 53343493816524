import axios from 'axios';
import React, { useEffect, useState } from 'react'

import {useForm} from 'react-hook-form';
import { BallTriangle } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import SweetAlert from 'react-bootstrap-sweetalert';



export default function AddACharger({user}) {
    const [imgPreview, setImgPreview] = useState([]);
    const [profile, setProfile] = useState(null);
    const [message, setMessage] = useState(null);
    const [loader, setLoader] = useState(false);
    const [err, setErr] = useState(null);
    const [err2, setErr2] = useState(null);

    const [showAlert, setShowAlert] = useState(false);

    const navigate = useNavigate();

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const handleAlert = () => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        //   showAlertFunction();
        }, 3000); // Replace 3000 with the desired time in milliseconds
      };

    function handleFile(event){
        if(event.target.files){
            setProfile(event.target.files[0]);
            const files = event.target.files
            let array = []
            for(var i=0;i<files.length;i++){
                array.push(URL.createObjectURL(files[i]))
            }
            setImgPreview([...array])
        }
    }

    function removeImg(index){
        const array = []
        for(var i=0;i<imgPreview.length;i++){
            if(i !== index){
                array.push(imgPreview[i])
            }
        }
        setImgPreview([...array])
    }

    useEffect(() => {
        if(err){
            setErr(false);
        }
    }, [profile])

    const onSubmit = (data) => {
        console.log("DATA : ", data);
        if(profile){
            setLoader(true);
            const formData = new FormData();
            formData.append("companyProfile_ProviderName", data.companyProfile_ProviderName);
            formData.append("companyProfile_ComparyLogo", profile);
            formData.append("companyProfile_TaxId", data.companyProfile_TaxId);
            formData.append("companyProfile_PartnerId", 112121);
            formData.append("personalProfile_Prename", "dummmy pre");
            formData.append("accountantContact_Prename", "dummmy pre");
            formData.append("maintenancePersonalContact_Prename", "dummmy pre");
            formData.append("onSiteServiceContact_Prename", "dummmy pre");
            formData.append("companyProfile_Address_AddressNo", data.companyProfile_Address_AddressNo);
            formData.append("companyProfile_Address_Subdistrict", data.companyProfile_Address_Subdistrict);
            formData.append("companyProfile_Address_District", data.companyProfile_Address_District);
            formData.append("companyProfile_Address_Province", data.companyProfile_Address_Province);
            formData.append("companyProfile_Address_ZipCode", data.companyProfile_Address_ZipCode);
            formData.append("companyProfile_TelephoneNumber", data.companyProfile_TelephoneNumber);
            formData.append("personalProfile_Name", data.personalProfile_Name);
            formData.append("personalProfile_Surname", data.personalProfile_Surname);
            formData.append("personalProfile_Address_AddressNo", data.personalProfile_Address_AddressNo);
            formData.append("personalProfile_Address_Subdistrict", data.personalProfile_Address_Subdistrict);
            formData.append("personalProfile_Address_District", data.personalProfile_Address_District);
            formData.append("personalProfile_Address_Province", data.personalProfile_Address_Province);
            formData.append("personalProfile_Address_ZipCode", data.personalProfile_Address_ZipCode);
            formData.append("personalProfile_Email", data.personalProfile_Email);
            formData.append("personalProfile_MobilePhoneNumber", data.personalProfile_MobilePhoneNumber);
            formData.append("accountantContact_Name", data.accountantContact_Name);
            formData.append("accountantEmail", data.accountantEmail);
            formData.append("accountantContact_Surname", data.accountantContact_Surname);
            formData.append("accountantContact_PhoneNumber", data.accountantContact_PhoneNumber);
            formData.append("maintenancePersonalContact_Name", data.maintenancePersonalContact_Name);
            formData.append("maintenancePersonalContact_Surname", data.maintenancePersonalContact_Surname);
            formData.append("maintenancePersonalContact_Email", data.maintenancePersonalContact_Email);
            formData.append("maintenancePersonalContact_PhoneNumber", data.maintenancePersonalContact_PhoneNumber);
            formData.append("onSiteServiceContact_Name", data.onSiteServiceContact_Name);
            formData.append("onSiteServiceContact_Surname", data.onSiteServiceContact_Surname);
            formData.append("onSiteServiceContact_Email", data.onSiteServiceContact_Email);
            formData.append("onSiteServiceContact_PhoneNumber", data.onSiteServiceContact_PhoneNumber);
            formData.append("subscription", data.subscription);

            
            const token = localStorage.getItem('master_token');


            axios.post("https://api.pea-cpo.com/api/addNewCPO", formData, {headers: {'Authorization' : `Bearer ${token}`, 'Accept' : 'application/json'}}).then((res) => {
                console.log("user request response : ", res);
                console.log("user request response.data : ", res.data);
                setLoader(false);
                if(res?.data?.validation_errors?.personalProfile_Email[0] === "The personal profile  email has already been taken."){
                    handleAlert();
                } else {
                    reset({companyProfile_ProviderName: '', onSiteServiceContact_PhoneNumber: "", onSiteServiceContact_Email: "",
                    onSiteServiceContact_Surname: '', onSiteServiceContact_Name: '', maintenancePersonalContact_PhoneNumber: '',
                    maintenancePersonalContact_Email: '', maintenancePersonalContact_Surname: '', maintenancePersonalContact_Name: '',
                    accountantContact_PhoneNumber: '', accountantContact_Surname: '', accountantContact_Name: '', personalProfile_MobilePhoneNumber: '',
                    personalProfile_Email: '', personalProfile_Address_ZipCode: '', personalProfile_Address_Province: '', personalProfile_Address_District: '',
                    personalProfile_Address_Subdistrict: '', personalProfile_Address_AddressNo: '', personalProfile_Surname: '', personalProfile_Name: '', 
                    companyProfile_TelephoneNumber: '', companyProfile_Address_ZipCode: '', companyProfile_Address_Province: '', companyProfile_Address_District: '',
                    companyProfile_Address_Subdistrict: '', companyProfile_Address_AddressNo: '', companyProfile_TaxId: ''
                    });
                    setProfile(null);
        
                    setMessage("Charger added successfully.");
                    
                    navigate('/admin/dashboard');
                }

                
                // setUser(res?.data);
                
            }).catch((errr) =>{
                console.log("Error get user : ", errr);
                console.log("Error get user : ", errr?.data?.message);
                setErr2(errr?.data?.message);
                setLoader(false);
            })
        } else {
            setErr(true);
        }
    }

  return (
<>
    {showAlert && (
        <>
            {/* <SweetAlert
                warning
                showCancel
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                title="Email already exist"
                onConfirm={() => setShowAlert(false)}
                onCancel={() => setShowAlert(false)}
                focusCancelBtn
                >
                The personal profile  email has already been taken!
            </SweetAlert> */}
            <SweetAlert
            title="Error!"
            onConfirm={() => setShowAlert(false)}
            >
                The personal profile  email has already been taken!
            </SweetAlert>
        </>
    )}
    {loader ? (
        <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '37vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
        </div>
    ) : ( <div className='station-overview'>
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className='overview-section-heading'>
        <h3>Add CPO</h3>
        {/* <p>Update your company details and address.</p> */}
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Provider name</h5>
                <p>This will be displayed to customer.</p>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("companyProfile_ProviderName", {required: true})} defaultValue={user?.CompanyProfile?.ProviderName} placeholder='Charge Wai Co., Ltd.'/>
                {errors.companyProfile_ProviderName && <span className='pt-2 text-danger'>Provider Name is required</span>}
            </div>
        </div>
        <div className='station-field location mb-4'>
            <div className='station-field-div1'>
                <h5>Company Logo</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='img-preview mb-4'>
                <div>
                {imgPreview.length > 0 && imgPreview.map((url, index)=>
                    <div key={index}>
                        <span style={{cursor:'pointer'}} onClick={()=>removeImg(index)}>&times;</span>
                        <img src={url} alt={url}/>
                    </div>
                )}
                </div>
                <input type='file'  className='form-control' accept="image/*"  onChange={handleFile}/>
                {err && <span className='mt-2 mb-5 text-danger' style={{fontSize: '16px', fontWeight: '400'}}>Company Logo is required</span>}
                
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Tax ID</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("companyProfile_TaxId", {required: true})} defaultValue={user?.CompanyProfile?.TaxId} placeholder='1-3859-00043-33-1'/>
                {errors.companyProfile_TaxId && <span className='pt-2 text-danger'>Tax ID is required</span>}
                
            </div>
        </div>
        <div className='station-field' style={{marginTop:'2rem'}}>
            <div className='station-field-div1'>
                <h5>Company Address</h5>
                <p>Address that available for contact</p>
            </div>
            <div>
                <div style={{width:'100%'}}>
                    <p style={{marginBottom:'0.3rem'}}>Street address</p>
                    <input className='form-control' {...register("companyProfile_Address_AddressNo", {required: true})}  defaultValue={user?.CompanyProfile?.AddressNo} placeholder='888 Soi Rangsit - Nakornnayok 49, Prachathipat'/>
                    {errors.companyProfile_Address_AddressNo && <span className='pt-2 text-danger'>Company Address is required</span>}                    
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Apartment, Suite, etc (optional)</p>
                        <input className='form-control' {...register("companyProfile_Address_Subdistrict")} placeholder='-'/>
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>City</p>
                        <input className='form-control' {...register("companyProfile_Address_District", {required: true})}  defaultValue={user?.CompanyProfile?.District} placeholder='Thunyaburi'/>
                        {errors.companyProfile_Address_District && <span className='pt-2 text-danger'>City is required</span>}                    
                    </div>
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Province</p>
                        <input className='form-control' {...register("companyProfile_Address_Province", {required: true})} defaultValue={user?.CompanyProfile?.Province} placeholder='Pathumthani '/>
                        {errors.companyProfile_Address_Province && <span className='pt-2 text-danger'>Province is required</span>}                    
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Zip code</p>
                        <input className='form-control' {...register("companyProfile_Address_ZipCode", {required: true})} defaultValue={user?.CompanyProfile?.ZipCode} placeholder='12130'/>
                        {errors.companyProfile_Address_ZipCode && <span className='pt-2 text-danger'>Zip Code is required</span>}                    
                    </div>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Telephone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("companyProfile_TelephoneNumber", {required: true})} defaultValue={user?.CompanyProfile?.TelephoneNumber} placeholder='02-236-0192' />
                {errors.companyProfile_TelephoneNumber && <span className='pt-2 text-danger'>Telephone Number is required</span>}                                
            </div>
        </div>

        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Subscription</h5>
            </div>
            <div style={{width:'35%'}} className="mt-3 d-flex">

            <div class="form-check">
                <input class="form-check-input" {...register("subscription",{required: true})} type="radio" value="Basic" name="subscription" id="flexRadioDefault1" />
                <label class="form-check-label" for="flexRadioDefault1">
                    Basic
                </label>
            </div>
            <div class="form-check ms-3">
                <input class="form-check-input" {...register("subscription")} type="radio" value="Advance" name="subscription" id="flexRadioDefault2" />
                <label class="form-check-label" for="flexRadioDefault2">
                    Advance
                </label>
            {errors.subscription && <p className='pt-2 text-danger' style={{marginLeft: '-98px'}} >Subscription is required</p>}                                
            </div>

                
                
                {/* <input className='form-control' {...register("companyProfile_TelephoneNumber", {required: true})} defaultValue={user?.CompanyProfile?.TelephoneNumber} placeholder='02-236-0192' /> */}
            </div>
        </div>

    </div>
    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>Contact Profile</h3>
        <p>Update your contact details and address.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input className='form-control' {...register("personalProfile_Name", {required: true})} defaultValue={user?.PersonalProfile?.Name} placeholder='Charge'/>
                    {errors.personalProfile_Name && <span className='pt-2 text-danger'>Name is required</span>}                                                    
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input className='form-control' {...register("personalProfile_Surname", {required: true})} defaultValue={user?.PersonalProfile?.Prename} placeholder='Wai'/>
                    {errors.personalProfile_Surname && <span className='pt-2 text-danger'>Surname is required</span>}                                                    
                    
                </div>
            </div>
        </div>
        <div className='station-field' style={{marginTop:'2rem'}}>
            <div className='station-field-div1'>
                <h5>Address</h5>
                <p>Address that available for contact</p>
            </div>
            <div>
                <div style={{width:'100%'}}>
                    <p style={{marginBottom:'0.3rem'}}>Street address</p>
                    <input className='form-control' {...register("personalProfile_Address_AddressNo", {required: true})} defaultValue={user?.PersonalProfile?.AddressNo} placeholder='888 Soi Rangsit - Nakornnayok 49, Prachathipat'/>
                    {errors.personalProfile_Address_AddressNo && <span className='pt-2 text-danger'>Street address is required</span>}                                                    
                    
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Apartment, Suite, etc (optional)</p>
                        <input className='form-control' {...register("personalProfile_Address_Subdistrict")} placeholder='-'/>
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>City</p>
                        <input className='form-control' {...register("personalProfile_Address_District", {required: true})} defaultValue={user?.PersonalProfile?.District} placeholder='Thunyaburi'/>
                        {errors.personalProfile_Address_District && <span className='pt-2 text-danger'>City is required</span>}                                                    

                    </div>
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Province</p>
                        <input className='form-control' {...register("personalProfile_Address_Province", {required: true})} defaultValue={user?.PersonalProfile?.Province} placeholder='Pathumthani '/>
                        {errors.personalProfile_Address_Province && <span className='pt-2 text-danger'>Province is required</span>}                                                    

                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Zip code</p>
                        <input className='form-control' {...register("personalProfile_Address_ZipCode", {required: true})} defaultValue={user?.PersonalProfile?.ZipCode} placeholder='12130'/>
                        {errors.personalProfile_Address_ZipCode && <span className='pt-2 text-danger'>Zip Code is required</span>}                                                    
                        
                    </div>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" {...register("personalProfile_Email", {required: true})} className='form-control' defaultValue={user?.PersonalProfile?.Email} placeholder='charge.wai@peavolta.com'/>
                {errors.personalProfile_Email && <span className='pt-2 text-danger'>Email is required</span>}                                                    
                
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("personalProfile_MobilePhoneNumber", {required: true})} defaultValue={user?.PersonalProfile?.MobilePhoneNumber} placeholder='099-234-9877'/>
                {errors.personalProfile_MobilePhoneNumber && <span className='pt-2 text-danger'>Mobile Number is required</span>}                                                    
                
            </div>
        </div>
    </div>

    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>Accountant Contact</h3>
        <p>Update your accountant contact.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input className='form-control' {...register("accountantContact_Name", {required: true})} defaultValue={user?.AccountantContact?.Name} placeholder='Charge'/>
                    {errors.accountantContact_Name && <span className='pt-2 text-danger'>Name is required</span>}                                                    
                    
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input className='form-control' {...register("accountantContact_Surname", {required: true})} defaultValue={user?.AccountantContact?.Surname} placeholder='Wai'/>
                    {errors.accountantContact_Surname && <span className='pt-2 text-danger'>Surname is required</span>}                                                    
                    
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" {...register("accountantEmail", {required: true})} className='form-control' placeholder='charge.wai@peavolta.com'/>
                {errors.accountantEmail && <span className='pt-2 text-danger'>Email is required</span>}                                                    
                
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("accountantContact_PhoneNumber", {required: true})} placeholder='099-234-9877'/>
                {errors.accountantContact_PhoneNumber && <span className='pt-2 text-danger'>Mobile number is required</span>}                                                    

            </div>
        </div>
    </div>

    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>Maintenance Personnel Contact</h3>
        <p>Update your maintenance personnel contact.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input className='form-control' {...register("maintenancePersonalContact_Name", {required: true})} defaultValue={user?.MaintenancePersonalContact?.Name} placeholder='Charge'/>
                    {errors.maintenancePersonalContact_Name && <span className='pt-2 text-danger'>Name is required</span>}                                                    
                    
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input className='form-control' {...register("maintenancePersonalContact_Surname", {required: true})} defaultValue={user?.MaintenancePersonalContact?.Surname} placeholder='Wai'/>
                    {errors.maintenancePersonalContact_Surname && <span className='pt-2 text-danger'>Surname is required</span>}                                                    
                    
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" {...register("maintenancePersonalContact_Email", {required: true})} className='form-control' defaultValue={user?.MaintenancePersonalContact?.Email} placeholder='charge.wai@peavolta.com'/>
                {errors.maintenancePersonalContact_Email && <span className='pt-2 text-danger'>Email address is required</span>}                                                    
                
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("maintenancePersonalContact_PhoneNumber", {required: true})} defaultValue={user?.MaintenancePersonalContact?.PhoneNumber} placeholder='099-234-9877'/>
                {errors.maintenancePersonalContact_PhoneNumber && <span className='pt-2 text-danger'>Mobile number is required</span>}                                                    
                
            </div>
        </div>
    </div>

    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>On-site Service Contact</h3>
        <p>Update your On-site service contact.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input {...register("onSiteServiceContact_Name", {required: true})} className='form-control' defaultValue={user?.OnSiteServiceContact?.Name} placeholder='Charge'/>
                    {errors.onSiteServiceContact_Name && <span className='pt-2 text-danger'>Name is required</span>}                                                    
                    
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input {...register("onSiteServiceContact_Surname", {required: true})} className='form-control' defaultValue={user?.OnSiteServiceContact?.Surname} placeholder='Wai'/>
                    {errors.onSiteServiceContact_Surname && <span className='pt-2 text-danger'>Surname is required</span>}                                                    
                    
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" {...register("onSiteServiceContact_Email", {required: true})} className='form-control' defaultValue={user?.OnSiteServiceContact?.Email} placeholder='charge.wai@peavolta.com'/>
                {errors.onSiteServiceContact_Email && <span className='pt-2 text-danger'>Email address is required</span>}                                                    
                
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("onSiteServiceContact_PhoneNumber", {required: true})} defaultValue={user?.OnSiteServiceContact?.PhoneNumber} placeholder='099-234-9877'/>
                {errors.onSiteServiceContact_PhoneNumber && <span className='pt-2 text-danger'>Mobile number is required</span>}                                                    
                
            </div>
        </div>
    </div>
    <div className='station-setting-btns' style={{marginTop:'3rem'}}>
        <button className='cancel-btn' onClick={() => navigate('/admin/dashboard')}>Cancel</button>
        <button className='save-btn' type='submit'>Submit</button>
    </div>
    <p className='text-success py-2'>{message}</p>
    <p className='text-danger w-100 text-end py-2' style={{marginTop: '-50px', marginLeft: '-20%'}}>{err && "Company email is required."}</p>
    <p className='text-danger w-100 text-end py-2' style={{marginTop: '-50px', marginLeft: '-20%'}}>{err2 && err2}</p>
</form>
</div>)}
</>
  )
}
