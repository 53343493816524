import React, { useEffect } from "react";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";

const VerifyToken = () => {
    const {t} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(t){
            localStorage.setItem('token', t);
            navigate('/');
        } else {
            navigate('/admin/cpolist');        
        }

    }, [])
    
    return (
        <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '37vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
        </div>
    )
}

export default VerifyToken;