import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";

const VerifyAdminToken = () => {
    const {token, encryptedString, encryptedCode} = useParams();
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    console.log("token : ", token)
    console.log("encryptedString : ", encryptedString)
    console.log("encryptedCode : ", encryptedCode)

    useEffect(() => {
        axios.post(`https://api.pea-cpo.com/api/two_login_Admin`, {
            token: token,
            encryptedString: encryptedString,
            encryptedCode: encryptedCode
        }).then((res) => {
            console.log("Res : ", res);
            localStorage.setItem('master_token', res?.data?.token);
            navigate('/admin/dashboard');

        }).catch((err) => {
            console.log("Err : ", err);
            setError(true);
            setTimeout(() => {
                localStorage.removeItem("master_token");
                localStorage.removeItem("token");
                setError(null);
                navigate('/login');
            }, 3000)
        })
    }, [])

    return (
        <div>
            <h1 className="text-center" style={{paddingTop: '30vh'}}>Verifing Token</h1>
            {error === null ? (
                <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '7vh'}}>
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#814097"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                </div>
            ) : (
                <h2 className="text-danger text-center">Token experied please try again.</h2>
            )}
        </div>
    )
}


export default VerifyAdminToken;