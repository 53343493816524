import React from 'react'
import DataTable from 'react-data-table-component';
import sortIcon1 from '../../assets/sortArrowIcon.svg';

const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

const sortIcon = <img src={sortIcon1} width="10px" height="10px" className="ms-1" />;

const checkboxx = () => {
    return (
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
    )
}

const DataTableBase = (props) => {
    return (
        <DataTable
            selectableRowsComponent={checkboxx}
            selectableRowsComponentProps={selectProps}
            sortIcon={sortIcon}
            // dense
            // pagination

            {...props}
        />
    )
}

export default DataTableBase;