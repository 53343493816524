import React, { useEffect } from 'react';
import DataTableBase from './reuseable/DataTableBase';
import DatePicker from './reuseable/DatePicker';
import StationGraphCard from './reuseable/StationGraphCard';
import { useState } from 'react';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

const StationTransaction = () => {
    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    // const [tableData, setTableData] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [checkPoint, setCheckPoint] = useState(false);

  const [dateRange2, setDateRange2] = useState(null);


    const [filter, setFilter] = useState(null);

    const {id} = useParams();

    useEffect(() => {
        console.log("date range 2 : ", dateRange2)
        if(dateRange2?.option){
            // Filter the data based on the date range
            const start = new Date(dateRange2?.startDate);
            const end = new Date(dateRange2?.endDate);

            const filteredData2 = data && data.filter(obj => {
                // console.log("obj : ", obj)
                const currentDate = new Date(obj.startDate);
                // console.log("current date : ", currentDate, obj.startDate);
                // console.log("filter row : ", currentDate >= start && currentDate <= end)
                // console.log("Start date : ", start)
                // console.log("End date : ", end)
                return currentDate >= start && currentDate <= end;
            });
             
            console.log("Filtered data : ", filteredData2);
            setFilteredData(filteredData2);
        } else {
            setFilteredData(data);
        }
    }, [dateRange2?.startDate])

    useEffect(() => {
        const access_token = localStorage.getItem('token');
        // axios.get('https://api.pea-cpo.com/api/overviewPageStatus', {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
        axios.get(`https://api.pea-cpo.com/api/overviewPageChargingListByID/${id}`, {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
            // console.log("Cpo request res : ", res);
            console.log("Overview page status Response : ", res?.data);
            console.log("Overview page status Response : ", res?.data?.data);
            console.log("Overview page status Response : ", res?.data?.data?.lateastTransactions);
            setData(res?.data?.data);

            const yearFilterData = filterCurrentYearData(res?.data?.data);

            setFilteredData(yearFilterData);

            const ans= calculateMetrics(res?.data?.data);
            console.log("Metrix data : ", ans);
            setMetrics(ans);

            const ans2 = calculateChartData(res?.data?.data);
            console.log("Ans Two data : : ", ans2)

            setChartData(ans2);

            // const tabData = calculateTableData(res?.data?.data);

            // console.log("Table Data : ", tabData);

            // const revTabData = tabData.reverse();

            // setTableData(revTabData);
            
            setLoader(false);
            
        }).catch((err) => {
            console.log("Error : ", err);
            setLoader(false);
        })
    }, [])



    useEffect(() => {
        console.log("Filter val 1: ", filter)
        if(filter === 'Today'){
            console.log("Filter val : ", filter);
            const revenueArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            const metrics = {
                session: 0,
                duration: 0,
            };

            for (let i = 0; i < data?.length; i++) {
                const duration = parseFloat(data[i]?.duration);
                const startDate = new Date(data[i]?.startDate);
                const startTime = data[i]?.startTime;

                const today = new Date();
                if(filter === 'Today'){
                    if (
                      startDate.getFullYear() !== today.getFullYear() ||
                      startDate.getMonth() !== today.getMonth() ||
                      startDate.getDate() !== today.getDate()
                    ) {
                      console.log("Wrong today");
                      continue;
                    } else {
                        console.log("Right today");                        
                    }
                }

                const hour = Number(startTime.slice(0,2));

                revenueArr[hour] += duration; 

                metrics.session += 1;
                metrics.duration += duration;
            }

            console.log("Today Ans data : ", revenueArr);
            setMetrics(metrics);

            console.log("Today Ans Two data : : ", metrics)

            setChartData(revenueArr);
            setCheckPoint(true);
            
        } else {
            const ans= calculateMetrics(data);
            // console.log("Ans data : ", ans);
            setMetrics(ans);
            
            const ans2 = calculateChartData(data);
            console.log("Ans Two data : : ", ans2)
            
            setChartData(ans2);
            setCheckPoint(false);
        }
    }, [filter])


    function calculateMetrics(para){
        const met = {
            duration: 0,
            session: 0
        };
        

        // para && para?.forEach((item) => {
        //     met.duration += Number(item?.duration);
        //     met.session += 1;
        // })


        for(let i = 0; i < para?.length; i++){
            const duration = parseFloat(para[i]?.duration);
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }

            met.duration += duration;
            met.session += 1; 
        }
        

        return met;
    }

    function filterCurrentYearData(para){
        const filteredArray = [];

        for(let i = 0; i < para?.length; i++){
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }

            filteredArray?.push(para[i]);
        }

        return filteredArray;
    }

    function calculateChartData(para){
        const revenueArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for(let i = 0; i < para?.length; i++){
            const duration = parseFloat(para[i]?.duration);
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }

            const month = startDate.getMonth();
            // revenueArr[month] += duration; 
            revenueArr[month] += 1; 
        }

        return revenueArr;
    }

    

    const columns = [
        {
            name: "Start Charge",
            selector: row => `${row?.startDate} ${row?.startTime}`,
            sortable: true,
        },
        {
            name: "End Charge",
            selector: row => `${row?.endDate} ${row?.endTime}`,
            sortable: true,
        },
        {
            name: "Connector",
            selector: row => row?.connector,
            sortable: true,
        },
        {
            name: "Charger Name",
            selector: row => row?.location,
        },
        {
            name: "Duration (mins)",
            selector: row => row?.duration,
            sortable: true,
        },
        {
            name: "Energy (kWh)",
            selector: row => row?.totalKwh,
            sortable: true,
        },
        {
            name: "Revenue (THB)",
            selector: row => row?.revenue,
            sortable: true,
        },
    ];

    

    if(loader){
        return (
          <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '17vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
          </div>
        )
    } else {
        return (
            <div className='station-revenue'>
                <div className='w-100 d-flex'>
                    <div className='w-50'>
                        <h3 className='font-style27 mb-0'>Transaction</h3>
                        <h6 className='font-style28'>ข้อมูล Transaction</h6>
                    </div>
                    <div className='w-50'>
                        {/* <button className='btn-style1'>Download</button> */}
                    </div>
                </div>
                <StationGraphCard 
                    val1={<h3 className='graph-card-number'>{metrics?.session} <b className='font-style29'>Times</b></h3>} 
                    val2={<h3 className='graph-card-number'>{Math.floor(Number(metrics?.duration) / 60)} <b className='font-style29'>hr</b> {Number(metrics?.session) % 60} <b className='font-style29'>mins</b></h3>} 
                    val3={<h3 className='graph-card-number'>{Number(metrics?.duration) > 0 ? Math.floor((Number(metrics?.duration) / metrics?.session) / 60) : 0} <b className='font-style29'>hr</b> <b className='font-style29'>{Number(metrics?.duration) > 0 ? Math.floor((Number(metrics?.duration) / metrics?.session) % 60) : 0} mins</b></h3>} 
                    l1="Total Sessions" l2="Total Durations" l3="Average Time per Session"  
                    cols={3}
                    statRev={true}
                    
                    label="Transaction"
                    data2={chartData}
                    setFilter={setFilter}
                    filter={filter}
                    symbol={"No. of Sessions"}
                    />
                <div className='w-100 d-flex my-4 justify-content-end'>
                <div className='mx-3'>
                        {/* <button onClick={()=>setToggleModel(true)} className='btn-style1'>Download</button> */}
                        {/* <button className='btn-style1'>Download</button> */}
                </div>
                    <div className='section-3rdds'>
                        <DatePicker dateRange={dateRange2} setDateRange={setDateRange2}/> 
                    </div>
                </div>
                <div>
                    {filteredData && (
                    <DataTableBase 
                        columns={columns}
                        data={filteredData} 
                        title="Transaction"
                        pagination
                    />

                    )}
                </div>
            </div>
        )
    }
}

export default StationTransaction;


const data1 = [
    {
        id: 1,
        name: '#08',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 2,
        name: '#06',
        connector: 'CHAdeMO',
        startCharge: '2022-06-02 09:52:33',
        endCharge: '2022-06-10 09:52:33',
        duration: 22.48,
        energy: 18.16,
        revenue: 124.15
    },
    {
        id: 3,
        name: '#04',
        connector: 'CHAdeMO',
        startCharge: '2022-06-06 09:52:33',
        endCharge: '2022-06-21 09:52:33',
        duration: 142.34,
        energy: 78.41,
        revenue: 464.16
    },
    {
        id: 4,
        name: '#02',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 5,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 6,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 7,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 8,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 9,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 10,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 11,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 12,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 13,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 14,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 15,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
    {
        id: 16,
        name: '#01',
        connector: 'CHAdeMO',
        startCharge: '2022-06-01 09:52:33',
        endCharge: '2022-06-01 09:52:33',
        duration: 42.34,
        energy: 38.41,
        revenue: 244.56
    },
];