import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'

export default function AccountSetting() {
    const [focusElements, setFocusElements] = useState(['active-tog'])
    function handleClick(value) { 
        const data = []
        for(var i = 0; i < 9; i++) {
            if(i !== value){
                data[i] = '';
            }else{
                data[value] =  'active-tog'
            }
        }
        setFocusElements(data)
    };
  return (
    <>
    <div className="Station-detail">
    <div className="station-detail-card">
    <h2 className="station-detail-title">Account Settings</h2>
        <div className="Station-toggle">
            <ul>
                <li className={focusElements[0]} onClick={() => handleClick(0)}>
                    <Link to="profile">My Profile</Link>
                </li>
                <li className={focusElements[1]} onClick={() => handleClick(1)}>
                    <Link to="subscription">My Subscription</Link>
                </li>
                {/* <li className={focusElements[2]} onClick={() => handleClick(2)}>
                    <Link to="notification">Notification</Link>
                </li> */}
                <li className={focusElements[3]} onClick={() => handleClick(3)}>
                    <Link to="change-password">Change Password</Link>
                </li>
            </ul>
        </div>
    </div>
    <Outlet/>
    </div>
</>
  )
}
