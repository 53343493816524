import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import MASidebar from '../components/MASidebar';
import MANavbar from '../components/MANavbar';
import { BallTriangle } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';


function ViewCPOChargers() {
  const navigate = useNavigate();
  const [access_token, setAccessToken] = useState(localStorage.getItem('master_token'));
  const [user, setUser] = useState(null);
  const [tog, setTog] = useState(1);
  
  // const [loading, setLoading] = useState(false);
  // const [numArr, setNumArr] = useState([]);
  
  const [loader, setLoader] = useState(true);
  const [data1, setData1] = useState(false);
  const [number, setNumber] = useState(null);
  const [location, setLocation] = useState(null);
  const [delId, setDelId] = useState(null);
  const [data, setData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [flag, setFlag] = useState(1);

  const {id} = useParams();

  const showAlertFunction = () => {
    setShowAlert(true);
  };

  const handleAlert = () => {
    setShowAlert1(true);
    setTimeout(() => {
      setShowAlert1(false);
    }, 2000); // Replace 3000 with the desired time in milliseconds
  };
  

useEffect(() => {
  const access_token = localStorage.getItem('master_token');
  axios.get(`https://api.pea-cpo.com/api/getCpoDetail/${id}`, {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
      console.log("Cpo request res : ", res);
      console.log("Cpo Response data : ", res.data);
      setData1(res?.data);
      setLoader(false);
      if(res?.data?.status === 200){
      }
    }).catch((err) => {
      console.log("Error : ", err);
      setLoader(false);
  })
}, [])

useEffect(() => {
  const access_token = localStorage.getItem('master_token');
  axios.post('https://api.pea-cpo.com/api/getChargerCpo', {cpo_id: id}, {headers: {'Authorization': `Bearer ${access_token}`}}).then((response) => {
    console.log("Response chargers data : ", response?.data);
    setData(response?.data);
    setFilterData(response?.data);
    setLoader(false);
  }).catch((error) => {
    console.log("Error : ", error);
    setLoader(false);
  })
}, [flag])
  
  useEffect(() => {
    const token = localStorage.getItem('master_token');
    if (token === null) {
      navigate('/admin/login');
    }
    // else{     
    //   setLoading(true)
    // }
  }, [])

  const token = localStorage.getItem('master_token');
  useEffect(() => {
    if(!access_token){
      setAccessToken(localStorage.getItem('master_token'));
    }
    axios.get("https://api.pea-cpo.com/api/getAdminProfile", {headers: {'Authorization' : `Bearer ${access_token}`}}).then((res) => {
      setUser(res?.data?.username);
    }).catch((err) =>{
      console.log("Error get user : ", err);
    })
  }, [access_token])



  useEffect(() => {
    if(location !== null && number === null ){
      const result = data && data.filter((item) => {
        return item.locationEN.toLocaleLowerCase().match(location.toLocaleLowerCase()) || item.locationTH.toLocaleLowerCase().match(location.toLocaleLowerCase());
      });
      setFilterData(result);
    }
    if(location !== null && number !== null){
      const result = data && data.filter((item) => {
        return (item.locationEN.toLocaleLowerCase().match(location.toLocaleLowerCase()) || item.locationTH.toLocaleLowerCase().match(location.toLocaleLowerCase()) ) && (item.descriptionEN.toLocaleLowerCase().match(number.toLocaleLowerCase()) || item.descriptionTH.toLocaleLowerCase().match(number.toLocaleLowerCase()));
      });
      setFilterData(result);
    }
  }, [location || number])

 
 
  useEffect(() => {
    if(number !== null && location === null){
      const result2 = data && data.filter((item) => {
        return item.descriptionEN.toLocaleLowerCase().match(number.toLocaleLowerCase()) || item.descriptionTH.toLocaleLowerCase().match(number.toLocaleLowerCase());
      });
      setFilterData(result2);
    }

    if(number !== null && location !== null){
      const result2 = data && data.filter((item) => {
        return (item.descriptionEN.toLocaleLowerCase().match(number.toLocaleLowerCase()) || item.descriptionTH.toLocaleLowerCase().match(number.toLocaleLowerCase())) && (item.locationEN.toLocaleLowerCase().match(location.toLocaleLowerCase()) || item.locationTH.toLocaleLowerCase().match(location.toLocaleLowerCase()));
      });
      setFilterData(result2);
    }
  }, [number || location])

  function deleteCharger(){
    setShowAlert(false);
    setLoader(true);
    const access_token = localStorage.getItem('master_token');
    axios.post('https://api.pea-cpo.com/api/deleteChargerCpo', {cpo_id: id, charger_id: delId}, {headers: {'Authorization': `Bearer ${access_token}`}}).then((response) => {
      setLoader(false);
      handleAlert();
      setFlag(flag + 1);
      setDelId(null);

    }).catch((error) => {
      console.log("Error : ", error);
      setLoader(false);
      setDelId(null);
    })    
  }
  

  // if(loading){
  return (
    <div className='d-flex'>
      <MASidebar />
      <div className='content-div'>
        <MANavbar user={user} data={data1} /> 

            {loader ? (
                <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '37vh'}}>
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#814097"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                </div>
            ) : ( 
        <div className='main-div' style={{padding: '16px 32px'}}>
              
                {showAlert && (
                  <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="secondary"
                    title="Are you sure?"
                    onConfirm={() => deleteCharger()}
                    onCancel={() => { setDelId(null); setShowAlert(false); }}
                    focusCancelBtn
                  >
                    Are you sure you want to delete this!
                  </SweetAlert>
                )}

                  {showAlert1 && (
                    <SweetAlert success title="congratulations" onConfirm={() => setShowAlert1(false)} onCancel={() => setShowAlert1(false)}>
                      Charger Deleted successfully.
                    </SweetAlert>
                  )}

                
                {/* {showAlert && (
                  <SweetAlert
                    title="Hello, world!"
                    onConfirm={() => setShowAlert(false)}
                  >
                    This is a SweetAlert example.
                  </SweetAlert>
                )} */}

                
            <div className='Station-toggle m-0' style={{border: 'none'}}>
                <ul className='mt-0'>
                    <li className="mt-0" onClick={() => navigate(`/admin/viewcpo/profile/${id}`)}>CPO Profile</li>
                    <li className='active-tog mt-0' onClick={() => navigate(`/admin/viewcpo/chargers/${id}`)}>Chargers</li>
                </ul>
            </div>
                <div>
                    <div className='w-100  d-flex' style={{padding: '16px 0px'}}>
                        <div className='w-25'><h4>Chargers List</h4></div>
                        
                        <div className='w-75  justify-content-end d-flex'>
                            <div className='search-field d-inline-block pe-2' style={{width: '40%'}}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#6F6F6F" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                <input type="text" onChange={(e) => setLocation(e.target.value)} placeholder="Search by Name" />
                            </div>
                            <div className='search-field d-inline-block ps-2' style={{width: '40%'}}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#6F6F6F" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                <input type="text" onChange={(e) => setNumber(e.target.value)} placeholder="Search by Address" />
                            </div>
                            <button className='add-charger' onClick={() => navigate(`/admin/chargers/${id}`)}>Add Charger</button>
                        </div>
                    </div>
                    {filterData && filterData.map((item, idd) => (
                        <div className="charger-box-div d-flex" style={{margin: '16px 0px'}} key={idd}>
                        <div className="cbd-a">
                            <img src={item.chargerPhoto} height="300px" width="410px" alt="imgg" />
                        </div>
                        <div>
                            <h4 className='mb-3 mt-4 pt-2'>{item.chargerID}</h4>
                            <h1 className='mb-3'>{item.locationTH}</h1>
                            {/* <h1 className='mb-3'>{item.locationEN}</h1> */}
                            <h5 className='mb-3'>{item.number}</h5>
                            <p>{item.descriptionTH}</p>
                            <p className='mt-2'><button className='btn btn-info px-3' onClick={() => navigate(`/admin/viewcpo/chargers/${id}/update/${item?.id}`)}>Manage</button> <button onClick={() => {setDelId(item?.id); showAlertFunction();}} className='btn btn-danger ms-3'>Delete</button></p>
                        </div>
                        </div>
                    )) }
                </div>
            </div>)}
        </div>
    </div>
  );
  // }else{
  //   return(<div></div>);
  // }
}

export default ViewCPOChargers;
