// Function to get the current year
export const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear();
};
  
  // Function to get the current month in "MMM" format (e.g., Jan, Feb)
export const getCurrentMonth = () => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const currentDate = new Date();
    return months[currentDate.getMonth()];
};
  
  // Function to get the start and end date of the current week in the specified format
export const getCurrentWeekDates = () => {
    const currentDate = new Date();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
  
    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
  
    const formattedStartDate = `${startOfWeek.toLocaleString('default', { month: 'short' })} ${startOfWeek.getDate()}, ${startOfWeek.getFullYear()}`;
    const formattedEndDate = `${endOfWeek.toLocaleString('default', { month: 'short' })} ${endOfWeek.getDate()}, ${endOfWeek.getFullYear()}`;
  
    return `${formattedStartDate} - ${formattedEndDate}`;
};
  