import React, { useState } from 'react'
import upwardArrowIcon from '../assets/greenArrow.svg';
import downwardArrowIcon from '../assets/redarrow.svg';
import ColumnGraph from './ColumnGraph';

export const DashGraph = ({customLabels, statistics, chartData, type, datee}) => {
  const [tog, setTog] = useState(1);

  const yearLabels =  ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const oneDayLabels =  ["0-1", "1-2", "2-3", "3-4", "4-5", "5-6", "6-7", "7-8", "8-9", "9-10", "10-11", "11-12", "12-13", "13-14" , "14-15", "15-16", "16-17", "17-18", "18-19", "19-20", "20-21", "21-22", "22-23", "23-24"];
  const weekLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const month28 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'];
  const month29 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'];
  const month30 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
  const month31 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];

  const date2 = new Date(datee?.startDate);

  const yearc = date2.getFullYear();
  const monthc = date2.getMonth();

  console.log("year and month ", yearc, monthc)

  // Get the last day of the month
  const lastDayc = new Date(yearc, monthc + 1, 0);
  // const lastDayc = new Date(datee);

  // Get the number of days in the month
  const numDaysc = lastDayc.getDate();
  

  const date = new Date();

  const currentYear = date.getFullYear();

  return (
    <div className='section-graph'>
        <div className='graph-div'>
            <h3 className='graph-sec-title'>Charging Stations </h3>
            <h6 className='graph-sec-subtitle'>{datee?.option === 'Today' || datee?.option === 'Yesterday' ? datee?.option+' : '+datee?.startDate : datee !== null ? datee?.option+' : '+datee?.startDate+' - '+datee?.endDate : `This Year: Jan 1, ${currentYear} - Dec 31, ${currentYear}`} </h6>
            <div className='w-100 d-flex graph-card-wrapper'>
                <div className="graph-card border-right">
                    <h6 className='mb-0 pb-20 graph-card-title'>Total Revenue</h6>
                    <h3 className='graph-card-number'>{(Math.ceil(statistics?.revenue * 100) / 100)?.toLocaleString()} </h3>
                    {/* <h6 className='font-style204 mb-0'><img src={upwardArrowIcon} className="mr-8o16" width="11.67px" height="11.67px" alt="" /> 48% <span className='font-style205'>vs last year</span></h6> */}
                </div>
                <div className="graph-card border-right pl-24">
                    <h6 className='mb-0 pb-20 graph-card-title'>Total Charging Sessions</h6>
                    <h3 className='graph-card-number'>{(Math.ceil(statistics?.chargingSessions * 100) / 100)?.toLocaleString()} </h3>
                    {/* <h6 className='font-style206 mb-0'><img src={downwardArrowIcon} className="mr-8o16" width="11.67px" height="11.67px" alt="" /> 24% <span className='font-style205'>vs last year</span></h6> */}
                </div>
                <div className="graph-card border-right pl-24">
                    <h6 className='mb-0 pb-20 graph-card-title'>Total Customers</h6>
                    <h3 className='graph-card-number'>{(statistics?.uniqueCustomers)?.toLocaleString()}</h3>
                    {/* <h6 className='font-style204 mb-0'><img src={upwardArrowIcon} className="mr-8o16" width="11.67px" height="11.67px" alt="" /> 16% <span className='font-style205'>vs last year</span></h6> */}
                </div>
                <div className="graph-card pl-24">
                    <h6 className='mb-0 pb-20 graph-card-title'>Total Energy Used</h6>
                    <h3 className='graph-card-number'>{(Math.ceil(statistics?.energyUsed * 100) / 100)?.toLocaleString()}</h3>
                    {/* <h6 className='font-style204 mb-0'><img src={upwardArrowIcon} className="mr-8o16" width="11.67px" height="11.67px" alt="" /> 56% <span className='font-style205'>vs last year</span></h6> */}
                </div>
            </div>
            <div className='w-100 d-flex'>
              <div className='pt-20 w-25'>
                <h4 className='font-style-7'>Statistics</h4>
              </div>
              <div className='w-75'>
                <div className='toggles-graph'>
                  <ul className='graphs-ul'>
                    <li className={tog === 1 ? 'graph-active-li' : 'unactive'}
                    onClick={() => setTog(1)}
                    >Revenue</li>
                    <li className={tog === 2 ? 'graph-active-li' : 'unactive'}
                    onClick={() => setTog(2)}
                    >Charging Sessions</li>
                    <li className={tog === 3 ? 'graph-active-li' : 'unactive'}
                    onClick={() => setTog(3)}
                    >Customers</li>
                    <li className={tog === 4 ? 'graph-active-li' : 'unactive'}
                    onClick={() => setTog(4)}
                    >Energy Used</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='w-100'>
              <p className='mb-0 pb-0 ms-3'>{tog === 2 ? "No. of Sessions" : tog === 3 ? "No. of Customers" : tog === 4 ? "kWh" : "BAHT ฿"}</p>
              <ColumnGraph tog={tog} labs={type === 'Today' || type === 'Yesterday' ? oneDayLabels: type === 'This Week' || type === 'Last Week' ? weekLabels : type === 'Custom Range' ? customLabels : type === 'This Month' || type === 'Last Month' ? (numDaysc === 28 ? month28 : numDaysc === 29 ? month29 : numDaysc === 31 ? month31 : month30) : yearLabels} label={tog === 1? 'Revenue' : tog === 2? 'Charging Sessions' : tog === 3? 'Customers' : 'Energy Used'} data2={tog === 1? chartData?.revenue : tog === 2? chartData?.chargingSessions : tog === 3? chartData?.uniqueCustomers : chartData?.energyUsed } />
              {/* <ColumnGraph tog={tog} labs={type === 'Today' || type === 'Yesterday' ? oneDayLabels: type === 'This Week' || type === 'Last Week' ? weekLabels : type === 'This Month' || type === 'Last Month' ?  month31 : yearLabels} label={tog === 1? 'Revenue' : tog === 2? 'Charging Sessions' : tog === 3? 'Customers' : 'Energy Used'} data2={tog === 1? chartData?.revenue : tog === 2? chartData?.chargingSessions : tog === 3? chartData?.uniqueCustomers : chartData?.energyUsed } /> */}
            </div>
        </div>
    </div>
  )
}
