import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = ({ chartData, colors, chartDataX, chartDataY }) => {
    console.log("Chart data : : ", chartData)
    // const chartOptions = {
    //     labels: Object.keys(data),
    //     colors: colors,
    //     legend: {
    //       show: true,
    //     },
    // };
    
    // const chartData = data?.map(item => ({
    //     x: item.provider_name,
    //     y: item.cpo_count,
    //   }));
    
    const chartOptions = {
        // labels: chartData?.map(item => item?.x), // Labels from transformed 
        // labels: ['cpo', 'filter', 'names', 'stations', 'khan'],
        labels: chartDataX,
        colors: colors,        
        legend: {
          show: false,
        },
    };


    const chartSeries = chartData?.map(item => item?.y); // Data points from transformed data

    
    
    return (
        <Chart
            options={chartOptions}
            series={chartDataY}
            type="pie"
            width="100%"
            height={"320px"}
        />
    )
}

export default PieChart;