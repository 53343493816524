import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import DataTableBase from './reuseable/DataTableBase';
import DatePicker from './reuseable/DatePicker';
import StationGraphCard from './reuseable/StationGraphCard';
import StationGraphCard2 from './reuseable/StationGraphCard2';

const StationCustomer = () => {
    const [data, setData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [loader, setLoader] = useState(true);

    const [checkPoint, setCheckPoint] = useState(true);

    const [dateRange2, setDateRange2] = useState(null);

    const [filter, setFilter] = useState(null);

    
    const { id } = useParams();


    useEffect(() => {
        function filterDataByDateRange(startDateStr, endDateStr, data) {
            let customersCheckArray = [];

            function uniqueCustomer(cus){
                let flg = 1;
                for (let index = 0; index < customersCheckArray?.length; index++) {
                    if(customersCheckArray[index] === cus){
                        flg = 0;
                        break;
                    }
                }
                customersCheckArray.push(cus);
                return flg;
            }


            const startDate = new Date(startDateStr);
            const endDate = new Date(endDateStr);
        
            const filteredData = data.filter((item) => {
            const itemDate = new Date(item.startDate);
            return itemDate >= startDate && itemDate <= endDate;
            });

            console.log("Filterd data set : ", filteredData);


            for(let a= 0; a < filteredData?.length; a++){
                console.log("date : ", filteredData[a]?.startDate)
            }
        
            const dateRevenueSessionMap = {};
        
            filteredData.forEach((item) => {
            const itemDate = new Date(item.startDate).toLocaleDateString();

        
            if (!dateRevenueSessionMap[itemDate]) {
                console.log("(item.energy) : ", item.totalKwh);
                dateRevenueSessionMap[itemDate] = {
                date: itemDate,
                customers: 1,
                unique: 1,
                repeated: 0,
                };
            } else {
                dateRevenueSessionMap[itemDate].customers += 1;
                dateRevenueSessionMap[itemDate].unique += uniqueCustomer(item?.email);
                dateRevenueSessionMap[itemDate].repeated += dateRevenueSessionMap[itemDate].customers - dateRevenueSessionMap[itemDate].unique;
            }
            });
        
            // Convert the map into an array of objects
            const result = Object.values(dateRevenueSessionMap);
        
            return result;
        }

        if(dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today'){
            // Assuming your data is stored in the 'data' array
            
            // Example usage:
            const startDateStr = "2023-01-01";
            const endDateStr = "2023-03-01";
            const filteredData = filterDataByDateRange(dateRange2?.startDate, dateRange2?.endDate, data);
            console.log(filteredData);
            setTableData(filteredData);

            
        } else {
            const tabData = calculateTableData(data);

            console.log("Table Data : ", tabData);

            const revTabData = tabData.reverse();

    

            setTableData(revTabData);
        }
    }, [dateRange2?.startDate])

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`https://api.pea-cpo.com/api/overviewPageChargingListByID/${id}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
        console.log("Get Charger detail : ", res);
        
        setData(res?.data?.data);

        const ans= calculateMetrics(res?.data?.data);
        
        setMetrics(ans);
        
        const ans2 = calculateChartData(res?.data?.data);
        console.log("Ans Two data : : ", ans2)
        
        setChartData(ans2);

        const tabData = calculateTableData(res?.data?.data);

        console.log("Table data : ", tabData)

        const revTabData = tabData.reverse();

        setTableData(revTabData);

        setLoader(false);
        
        }).catch((err) =>{
            setLoader(false);
            console.log("Error get user : ", err);
        })
    }, [])


    useEffect(() => {
        console.log("Filter val 1: ", filter)
        if(filter === 'Today'){
            console.log("Filter val : ", filter);
            const customerArr = {
                customers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                unique: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                repeated: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            }
            const met = {
                customers: 0,
                unique: 0,
            };
    
            let customersCheckArray = [];
    
            function uniqueCustomer(cus){
                let flg = 1;
                for (let index = 0; index < customersCheckArray?.length; index++) {
                    if(customersCheckArray[index] === cus){
                        flg = 0;
                        break;
                    }
                }
                customersCheckArray.push(cus);
                return flg;
            }

            let customersCheckArray2 = [];
    
            function uniqueCustomer2(cus){
                let flg = 1;
                for (let index = 0; index < customersCheckArray2?.length; index++) {
                    if(customersCheckArray2[index] === cus){
                        // console.log("mail : ", customersCheckArray2[index]);
                        // console.log(cus);
                    flg = 0;
                    break;
                    }
                }
                customersCheckArray2.push(cus);
                return flg;
            }

            for (let i = 0; i < data.length; i++) {
                const email = data[i]?.email;
                const startDate = new Date(data[i]?.startDate);
                const startTime = data[i]?.startTime;

                const today = new Date();
                if(filter === 'Today'){
                    if (
                      startDate.getFullYear() !== today.getFullYear() ||
                      startDate.getMonth() !== today.getMonth() ||
                      startDate.getDate() !== today.getDate()
                    ) {
                      // console.log("Wrong today");
                      continue;
                    }
                }

                const hour = Number(startTime.slice(0,2));

                 

                customerArr.customers[hour] += 1; 
                customerArr.unique[hour] += uniqueCustomer(data[i].email); 

                met.unique += uniqueCustomer2(email);
                met.customers += 1;
            }

            console.log("Today Ans data : ", customerArr);
            setMetrics(met);

            console.log("Today Ans Two data : : ", met)

            setChartData(customerArr);
            // setCheckPoint(true);
            setCheckPoint(false);
            
        } else {
            if(filter !== null){
                const ans= calculateMetrics(data);
                // console.log("Ans data : ", ans);
                setMetrics(ans);
                
                const ans2 = calculateChartData(data);
                console.log("Ans Two data : : ", ans2)
                
                setChartData(ans2);
                // setCheckPoint(false);

                setCheckPoint(true);

            }
        }
    }, [filter])

    function calculateMetrics(para){
        const met = {
            customers: 0,
            // new: 0,
            unique: 0,
        };

        let customersCheckArray = [];

        function uniqueCustomer(cus){
            let flg = 1;
            for (let index = 0; index < customersCheckArray?.length; index++) {
                if(customersCheckArray[index] === cus){
                    flg = 0;
                    break;
                }
            }
            customersCheckArray.push(cus);
            return flg;
        }

        for(let i = 0; i < para?.length; i++){
            const env = parseFloat(para[i]?.totalKwh);
            const startDate = new Date(para[i].startDate);

            
            if (startDate.getFullYear() !== new Date().getFullYear()) {
                continue;
            }
            

            met.unique += uniqueCustomer(para[i]?.email);
            met.customers += 1;
        }

        // para && para.forEach((item) => {
        //     met.unique += uniqueCustomer(item?.email);
        //     met.customers += 1;
        // })

        return met;
    }

    function calculateChartData(para){
        const customerArr = {
            customers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            unique: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            repeated: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        }
        
        let customersCheckArray = [];

        function uniqueCustomer(cus){
            let flg = 1;
            for (let index = 0; index < customersCheckArray?.length; index++) {
                if(customersCheckArray[index] === cus){
                    // console.log("mail : ", customersCheckArray[index]);
                    // console.log(cus);
                flg = 0;
                break;
                }
            }
            customersCheckArray.push(cus);
            return flg;
        }

        for(let i = 0; i < para?.length; i++){
            const env = parseFloat(para[i]?.totalKwh);
            const startDate = new Date(para[i].startDate);

            // console.log("For loop working")
            
            if (startDate.getFullYear() !== new Date().getFullYear()) {
                continue;
            }
            
            // console.log("For loop True true ")

            const month = startDate.getMonth();
            customerArr.customers[month] += 1; 
            customerArr.unique[month] += uniqueCustomer(para[i].email); 
        }

        for(let a = 0; a < 7; a++){
            customerArr.repeated[a] = customerArr.customers[a] - customerArr.unique[a];
        }

        return customerArr;
    }


    function calculateTableData(para){
        const dumArr = [
            {
                month: 'January',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'February',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'March',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'April',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'May',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'June',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'July',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'August',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'September',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'October',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'November',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'December',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
        ];

        let customersCheckArray = [];

        function uniqueCustomer(cus){
            let flg = 1;
            for (let index = 0; index < customersCheckArray?.length; index++) {
                if(customersCheckArray[index] === cus){
                    flg = 0;
                    break;
                }
            }
            customersCheckArray.push(cus);
            return flg;
        }

        for(let i = 0; i < para?.length; i++){
            const email = para[i]?.email;
            const startDate = new Date(para[i].startDate);
            const getMon = startDate.getMonth();


            if(dateRange2?.option){
                const startRange = new Date(dateRange2?.startDate);
                const endRange = new Date(dateRange2?.endDate);

                if(startDate >= startRange && startDate <= endRange){

                } else {
                    continue;
                }

            } else {
                if (startDate.getFullYear() !== new Date().getFullYear()) {
                  continue;
                }
            }

            
            dumArr[getMon].customers +=1;
            
            dumArr[getMon].unique += uniqueCustomer(email);
            
            dumArr[getMon].repeated = dumArr[getMon].customers - dumArr[getMon].unique;

            
        }

        const newArr = [];

        dumArr && dumArr.map(item => {
            if(item?.customers > 0){
                newArr.push(item);
            }
        })

        return newArr;
    }


    const columns = [
        {
            name: dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? "Date" : "Month",
            selector: row => dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? row?.date : row?.month,
            sortable: true,
        },
        {
            name: "Total Customer",
            selector: row => (row?.customers).toLocaleString(),
            sortable: true,
        },
        {
            name: "Total New Customer",
            selector: row => (row?.unique).toLocaleString(),
            sortable: true,
        },
        {
            name: "Total Repeat Customer",
            selector: row => (row?.repeated).toLocaleString(),
            sortable: true,
        }        
    ];

if(loader){
    return (
        <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '17vh'}}>
        <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#814097"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
        />
        </div>
    )
} else {

    return (
        <div className='station-revenue'>
            <div className='w-100 d-flex'>
                <div className='w-50'>
                    <h3 className='font-style27 mb-0'>Customer</h3>
                    <h6 className='font-style28'>ข้อมูลด้านลูกค้า</h6>
                </div>
                <div className='w-50'>
                    {/* <button className='btn-style1'>Download</button> */}
                </div>
            </div>
            
            <StationGraphCard2
                val1={<h3 className='graph-card-number'>{(metrics?.customers).toLocaleString()} <b className='font-style29'></b></h3>} 
                val2={<h3 className='graph-card-number'>{(metrics?.unique).toLocaleString()} <b className='font-style29'></b></h3>} 
                val3={<h3 className='graph-card-number'>{(metrics?.customers - metrics?.unique).toLocaleString()} <b className='font-style29'></b></h3>} 
                val4={<h3 className='graph-card-number'>{metrics?.customers > 0 ? Math.ceil((((metrics?.customers - metrics?.unique) / metrics?.customers ) * 100) * 100) / 100 : 0}%<b className='font-style29'></b></h3>} 
                l1="Total Customer" l2="Total New Customer" l3="Total Repeat Customer" l4="Retention Rate"  
                cols={4}
                statRev={true}
                stacked={true}
                label="Customers"
                data2={chartData && chartData}
                setFilter={setFilter}
                filter={filter}
                checkPoint={checkPoint}
                />
                
            <div className='w-100 d-flex my-4 justify-content-end'>
               <div className='mx-3'>
                    {/* <button onClick={()=>setToggleModel(true)} className='btn-style1'>Download</button> */}
                    {/* <button className='btn-style1'>Download</button> */}
               </div>
                <div className='section-3rdds'>
                    <DatePicker dateRange={dateRange2} setDateRange={setDateRange2}/> 
                </div>
            </div>
            <div>
                {tableData && (
                    <DataTableBase 
                        columns={columns}
                        data={tableData && tableData}
                        title="Summary"
                        pagination
                    />
                )}
            </div>
        </div>
    )
    }
}

export default StationCustomer;