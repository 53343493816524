import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import img from '../assets/login-formphoto.png';
import Logo from '../assets/logo.svg';
import emailIcon from '../assets/email.svg';
import arrowIcon from '../assets/backtologin.svg';
import { useNavigate } from 'react-router-dom';


const Forget = () => {
    const navigate = useNavigate();
    const [tog, setTog] = useState(0);

    return (
        <div className='login'>
            <Container fluid>
            <Row>
                <Col md={6}>
                    {tog === 0 ? (
                        <div className='login-right-div'>
                            <div className='text-center'>
                                <img src={Logo} className="loginform-logo2" width="170px" />
                                <h2 className='font-style31 mb-0'>Forgot password?</h2>
                                <h6 className='font-style32'>No worries, we’ll send you reset instructions.</h6>
                            </div>
                            <div className='login-input-field-div mt-32'>
                                <label className='input-field-label'>Email</label>
                                <div>
                                    <img src={emailIcon} width="16.67px" className='input-field-icon' height="13.33px" alt="icon" />
                                    <input type="email" placeholder='Enter your Email' className="login-input-fields w-100" name="email" />

                                </div>
                            </div>
                            <div className='login-input-field-div'>
                                <button className='login-btn' onClick={() => setTog(1)}>Reset password</button>
                            </div>
                            <div className='login-input-field-div text-center'>
                                <h5 className='font-style35 cusor-pointer' onClick={() => navigate('/login')}><img src={arrowIcon} width="11.67px" height="11.67px" style={{marginTop: '-5px', marginRight: '7px'}} /> Back to log in</h5>
                            </div>
                        </div>
                    ) : (
                        <div className='login-right-div'>
                            <div className='text-center'>
                                <img src={Logo} className="loginform-logo2" width="170px" />
                                <h2 className='font-style31 mb-0'>Check your email</h2>
                                <h6 className='font-style32'>We sent a password reset link to <br /> sunny.lig@pea.co.th</h6>
                            </div>
                            {/* <div className='login-input-field-div mt-32'>
                                <label className='input-field-label'>Email</label>
                                <div>
                                    <img src={emailIcon} width="16.67px" className='input-field-icon' height="13.33px" alt="icon" />
                                    <input type="email" placeholder='Enter your Email' className="login-input-fields w-100" name="email" />

                                </div>
                            </div> */}
                            <div className='login-input-field-div'>
                                <button className='login-btn'>Okay</button>
                            </div>
                            <div className='login-input-field-div'>
                                <h5 className='font-style35'>Didn’t receive the email? <span className='font-style36' style={{fontWeight: '500'}}>Click to resend</span></h5>
                            </div>
                            <div className='login-input-field-div text-center'>
                                <h5 className='font-style35 cusor-pointer' onClick={() => navigate('/login')}><img src={arrowIcon} width="11.67px" height="11.67px" style={{marginTop: '-5px', marginRight: '7px'}} /> Back to log in</h5>
                            </div>
                        </div>
                    )}
                </Col>
                <Col md={6} className="p-0">
                    <img src={img} alt={img} className="form-img" />
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default Forget;