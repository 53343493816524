import React from 'react'
import { useEffect } from 'react';
import calenderIcon from '../../assets/calendar.svg';

export default function DatePicker({setDateRange}) {
    useEffect(() => {
      let drp = new window.DateRangePicker('datetimerange-input1',
      {
          //startDate: '2000-01-01',
          //endDate: '2000-01-03',
          //minDate: '2021-07-15 15:00',
          //maxDate: '2021-08-16 15:00',
          //maxSpan: { "days": 9 },
          //showDropdowns: true,
          //minYear: 2020,
          //maxYear: 2022,
          //showWeekNumbers: true,
          //showISOWeekNumbers: true,
          timePicker: false,
          //timePickerIncrement: 10,
          // timePicker24Hour: false,
          // timePickerSeconds: false,
          //showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          //opens: 'center',
          //drops: 'up',
          //singleDatePicker: true,
          //autoApply: true,
        //   linkedCalendars: false,
          //isInvalidDate: function(m){
          //    return m.weekday() == 3;
          //},
          //isCustomDate: function(m){
          //    return "weekday-" + m.weekday();
          //},
          //autoUpdateInput: false,
          ranges: {
              'Today': [window.moment().startOf('day'), window.moment().endOf('day')],
              'Yesterday': [window.moment().subtract(1, 'days').startOf('day'), window.moment().subtract(1, 'days').endOf('day')],
              // 'This Week':[window.moment().subtract(6, 'days').startOf('day'), window.moment().endOf('day')],
              'This Week':[window.moment().startOf('week'), window.moment().endOf('week')],
              // 'Last Week': [window.moment().subtract(6, 'days').startOf('day'), window.moment().endOf('day')],
              'Last Week': [window.moment().subtract(1, 'week').startOf('week'), window.moment().subtract(1, 'week').endOf('week')],
              'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
              'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')],
              'This Year': [window.moment().startOf('year').startOf('month').startOf('day'), window.moment().endOf('year').endOf('month').endOf('day')],
              'Last Year': [window.moment().subtract(1, 'year').startOf('year'), window.moment().subtract(1, 'year').endOf('year')],
          },
          locale: {
              format: "YYYY-MM-DD",
          }
      },
      function (start, end) {
        //   alert(start.format() + " - " + end.format());
        console.log("Apply button hit")
        // console.log("Date Selected : --> ");
        // console.log("Start Date : ", start);
        // console.log("Start Date : ", start.format('YYYY-MM-DD'));
        // console.log("End Date : ", end);
      })
        //drp.setStartDate('2014/03/01');
        //drp.setEndDate('2014/03/03');
        window.addEventListener('apply.daterangepicker', function (ev) {
          console.log("Callback function with peremeter ev hit : ev : ", ev);
            console.log("Selected filter option  : ", ev?.detail?.chosenLabel);
            console.log(ev.detail.startDate.format('YYYY-MM-DD'));
            console.log(ev.detail.endDate.format('YYYY-MM-DD'));

            setDateRange({startDate: ev.detail.startDate.format('YYYY-MM-DD'), endDate: ev.detail.endDate.format('YYYY-MM-DD'), option: ev?.detail?.chosenLabel});
            
            // // Today Date Check 

            // const today = new Date();
            // const year = today.getFullYear();
            // const month = String(today.getMonth() + 1).padStart(2, '0');
            // const day = String(today.getDate()).padStart(2, '0');
            // const todayString = `${(year)}-${month}-${day}`;
            
            // // Yesterday Date Check 

            // let yesterday = new Date(today);
            // yesterday.setDate(today.getDate() - 1);
            // // Format yesterday's date as a string in the YYYY-MM-DD format
            // let yesterday_str = yesterday.toISOString().slice(0,10);
            

            // // This Week Check 


            // const today1 = new Date();
            // // Find the first day of the current week (Sunday)
            // const firstDay = new Date(today1.getFullYear(), today1.getMonth(), today1.getDate() - today1.getDay());
            // // Find the last day of the current week (Saturday)
            // const lastDay = new Date(today1.getFullYear(), today1.getMonth(), firstDay.getDate() + 6);
            // const year2 = firstDay.getFullYear();
            // const month2 = String(firstDay.getMonth() + 1).padStart(2, '0');
            // const day2 = String(firstDay.getDate()).padStart(2, '0');

            // const year3 = lastDay.getFullYear();
            // const month3 = String(lastDay.getMonth() + 1).padStart(2, '0');
            // const day3 = String(lastDay.getDate()).padStart(2, '0');            

            // const firstDayString = `${(year2)}-${month2}-${day2}`;
            // const lastDayString = `${(year3)}-${month3}-${day3}`;
            // // const firstDayString = firstDay.toISOString();
            // // const lastDayString = lastDay.toISOString();

 
            // // Get today's date
            // // const today = new Date();

            // // Get the date for last week
            // const lastWeek = new Date(today1.getFullYear(), today1.getMonth(), today1.getDate() - 7);

            // // Get the start and end dates of last week
            // const lastWeekStart = new Date(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate() - lastWeek.getDay() + 1);
            // const lastWeekEnd = new Date(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate() - lastWeek.getDay() + 7);

            // // Format the dates as strings
            // const lastWeekStartDateString = lastWeekStart.toISOString().slice(0, 10);
            // const lastWeekEndDateString = lastWeekEnd.toISOString().slice(0, 10);

            // // console.log(`Last week was from ${lastWeekStartDateString} to ${lastWeekEndDateString}`);

            // const date = new Date();

            // const myear = date.getFullYear();
            // const mmonth = date.getMonth();

            // // Get the first day of the month
            // const mfirstDay = new Date(myear, mmonth, 1);

            // // Get the last day of the month
            // const mlastDay = new Date(myear, mmonth + 1, 0);


            // const year4 = mfirstDay.getFullYear();
            // const month4 = String(mfirstDay.getMonth() + 1).padStart(2, '0');
            // const day4 = String(mfirstDay.getDate()).padStart(2, '0');

            // const year5 = mlastDay.getFullYear();
            // const month5 = String(mlastDay.getMonth() + 1).padStart(2, '0');
            // const day5 = String(mlastDay.getDate()).padStart(2, '0');            

            // const firstDayMonth = `${(year4)}-${month4}-${day4}`;
            // const lastDayMonth = `${(year5)}-${month5}-${day5}`;


            // // console.log(" Month first day" ,firstDayMonth); // Outputs the first day of the month
            // // console.log(" Month Last day", lastDayMonth); // Outputs the last day of the month


            // const today2 = new Date();

            // // create a new date object for today
            
            // // set the date to the first day of this month
            // const firstDayThisMonth = new Date(today2.getFullYear(), today2.getMonth(), 1);

            // // subtract one day from the first day of this month to get the last day of last month
            // const lastDayLastMonth = new Date(firstDayThisMonth.getTime() - 86400000);

            // // set the date to the first day of last month
            // const firstDayLastMonth = new Date(lastDayLastMonth.getFullYear(), lastDayLastMonth.getMonth(), 1);

            // // format the starting and ending dates of last month in yyyy-mm-dd format in local time zone
            // const startingDateLastMonth = firstDayLastMonth.getFullYear() + "-" + ("0" + (firstDayLastMonth.getMonth() + 1)).slice(-2) + "-" + ("0" + firstDayLastMonth.getDate()).slice(-2);
            // const endingDateLastMonth = lastDayLastMonth.getFullYear() + "-" + ("0" + (lastDayLastMonth.getMonth() + 1)).slice(-2) + "-" + ("0" + lastDayLastMonth.getDate()).slice(-2);

            // // console.log("Starting date of last month: " + startingDateLastMonth);
            // // console.log("Ending date of last month: " + endingDateLastMonth);


            // // const today = new Date();

            // // set the date to the first day of January of the previous year
            // const firstDayLastYear = new Date(today2.getFullYear() - 1, 0, 1);

            // // set the date to the last day of December of the previous year
            // const lastDayLastYear = new Date(today2.getFullYear() - 1, 11, 31);

            // // format the starting and ending dates of last year in yyyy-mm-dd format in local time zone
            // const startingDateLastYear = firstDayLastYear.getFullYear() + "-" + ("0" + (firstDayLastYear.getMonth() + 1)).slice(-2) + "-" + ("0" + firstDayLastYear.getDate()).slice(-2);
            // const endingDateLastYear = lastDayLastYear.getFullYear() + "-" + ("0" + (lastDayLastYear.getMonth() + 1)).slice(-2) + "-" + ("0" + lastDayLastYear.getDate()).slice(-2);

            // // console.log("Starting date of last year: " + startingDateLastYear);
            // // console.log("Ending date of last year: " + endingDateLastYear);


            // if(ev.detail.startDate.format('YYYY-MM-DD') === todayString && ev.detail.endDate.format('YYYY-MM-DD') === todayString){
            //   console.log("Today Selected : : : ", todayString);
            //   setDateRange({startDate: ev.detail.startDate.format('YYYY-MM-DD'), endDate: ev.detail.endDate.format('YYYY-MM-DD'), option: 'Today'});
            // } else if(ev.detail.startDate.format('YYYY-MM-DD') === yesterday_str && ev.detail.endDate.format('YYYY-MM-DD') === yesterday_str){
            //   console.log("Yesterday Selected : : : ", yesterday_str);
            //   setDateRange({startDate: ev.detail.startDate.format('YYYY-MM-DD'), endDate: ev.detail.endDate.format('YYYY-MM-DD'), option: 'Yesterday'});
              
            // } else if(ev.detail.startDate.format('YYYY-MM-DD') === firstDayString && ev.detail.endDate.format('YYYY-MM-DD') === lastDayString){
            //   setDateRange({startDate: ev.detail.startDate.format('YYYY-MM-DD'), endDate: ev.detail.endDate.format('YYYY-MM-DD'), option: 'This Week'});
            // } else if(ev.detail.startDate.format('YYYY-MM-DD') === lastWeekStartDateString && ev.detail.endDate.format('YYYY-MM-DD') === lastWeekEndDateString){
              
            //   setDateRange({startDate: ev.detail.startDate.format('YYYY-MM-DD'), endDate: ev.detail.endDate.format('YYYY-MM-DD'), option: 'Last Week'});
              
            // } else if(ev.detail.startDate.format('YYYY-MM-DD') === firstDayMonth && ev.detail.endDate.format('YYYY-MM-DD') === lastDayMonth){
            //   setDateRange({startDate: ev.detail.startDate.format('YYYY-MM-DD'), endDate: ev.detail.endDate.format('YYYY-MM-DD'), option: 'This Month'});
            // } else if(ev.detail.startDate.format('YYYY-MM-DD') === startingDateLastMonth && ev.detail.endDate.format('YYYY-MM-DD') === endingDateLastMonth){
              
            //   setDateRange({startDate: ev.detail.startDate.format('YYYY-MM-DD'), endDate: ev.detail.endDate.format('YYYY-MM-DD'), option: 'Last Month'});
            // } 
            // else if(ev.detail.startDate.format('YYYY-MM-DD') === startingDateLastYear && ev.detail.endDate.format('YYYY-MM-DD') === endingDateLastYear){
              
            //   setDateRange({startDate: ev.detail.startDate.format('YYYY-MM-DD'), endDate: ev.detail.endDate.format('YYYY-MM-DD'), option: 'Last Year'});
            // }
            // else if(ev?.detail?.chosenLabel === "This Year"){
            //   setDateRange({startDate: ev.detail.startDate.format('YYYY-MM-DD'), endDate: ev.detail.endDate.format('YYYY-MM-DD'), option: 'This Year'});
            // } 
            // else {
            //   setDateRange({startDate: ev.detail.startDate.format('YYYY-MM-DD'), endDate: ev.detail.endDate.format('YYYY-MM-DD'), option: 'Custom Range'});
            // }

            
        });
     }, [])
    
  return (
    <div className='year-filter' style={{cursor:'pointer', display:'flex', alignItems:'center', width:'auto'}}>
        <img src={calenderIcon} className="me-2 ms-3" width="20px" height="20px" alt="" /> 
        <input type="text" id="datetimerange-input1" size="40" style={{textAlign:"center", cursor:'pointer', border:'none', fontSize:'medium', padding:'0px', width:'200px'}}/>
    </div>
  )
}
