import React from 'react';

const Notify = () => {
    return (
        <div className='station-overview' style={{padding: '16px 32px' }}>
            <div className='overview-section-heading'>
                <h3>Notification settings</h3>
                <p>We may still send you important notifications about your account outside of your notification settings.</p>
            </div>
            <div className='overview-section'>  
                <div className='station-field notification-field'>
                    <div className='station-field-div1'>
                        <h5>Feedback from customers</h5>
                        <p>These are notifications to remind you of feedback from your customers.</p>
                    </div>
                    <div className='station-field-div2' style={{width:'35%'}}>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            Push
                        </div>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            Email
                        </div>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            SMS
                        </div>
                    </div>
                </div>
                <div className='station-field notification-field'>
                    <div className='station-field-div1'>
                        <h5>Charger Status</h5>
                        <p>These are notifications for your charger(s), when charger status is changed.</p>
                    </div>
                    <div className='station-field-div2' style={{width:'35%'}}>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            Push
                        </div>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            Email
                        </div>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            SMS
                        </div>
                    </div>
                </div>
                <div className='station-field notification-field'>
                    <div className='station-field-div1'>
                        <h5>Charger Setting</h5>
                        <p>These are notifications for your charger(s), when charger setting is updated.</p>
                    </div>
                    <div className='station-field-div2' style={{width:'35%'}}>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            Push
                        </div>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            Email
                        </div>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            SMS
                        </div>
                    </div>
                </div>
                <div className='station-field notification-field'>
                    <div className='station-field-div1'>
                        <h5>Update and News Letter</h5>
                        <p>Receive the latest news and updates from us.</p>
                    </div>
                    <div className='station-field-div2' style={{width:'35%'}}>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            Push
                        </div>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            Email
                        </div>
                        <div className='notification-toggle'>
                            <input type="checkbox" class="toggle-station"/>
                            SMS
                        </div>
                    </div>
                </div>
            </div>
            <div className='station-setting-btns' style={{marginTop:'3rem'}}>
                <button className='cancel-btn text-white'>Cancel</button>
                <button className='save-btn'>Save Changes</button>
            </div>
        </div>
    )
}

export default Notify;