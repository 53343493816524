import React from 'react'
import '../style/Profile.css'
export default function Subscription() {
  return (
    <div className='station-overview'>
      <div className='overview-section-heading'>
          <h3>Account plans</h3>
          <p>Pick an account plan that fits your workflow.</p>
      </div>
      <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Current plan</h5>
                <p>We’ll credit your account if you need to downgrade during the billing cycle.</p>
            </div>
            <div style={{width:'35%'}}>
              <div className='subscription-section'>
                <div className='subscription-section-one'>
                  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.33594 7.66667L8.0026 11L14.6693 7.66667M8.0026 1L1.33594 4.33333L8.0026 7.66667L14.6693 4.33333L8.0026 1Z" stroke="#7718BC" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  Standard plan
                </div>
              <div className='subscription-section-two'>
                <div className='subscription-section-two-header'>
                  <h1>THB 590 <span>per month</span></h1>
                  <p>Service fee 5% per transaction</p>
                </div>
                <div className='subscription-section-two-paid'>
                  Monthly Paid
                </div>
              </div>
            </div>
            <div className='subscription-section-three'>
              Upgrade plan
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.9 14.15L12.25 8.8V11.4H13.75V6.25H8.6V7.75H11.175L5.85 13.075L6.9 14.15ZM10 19.5C8.68333 19.5 7.446 19.25 6.288 18.75C5.12933 18.25 4.125 17.575 3.275 16.725C2.425 15.875 1.75 14.8707 1.25 13.712C0.75 12.554 0.5 11.3167 0.5 10C0.5 8.68333 0.75 7.44567 1.25 6.287C1.75 5.129 2.425 4.125 3.275 3.275C4.125 2.425 5.12933 1.75 6.288 1.25C7.446 0.75 8.68333 0.5 10 0.5C11.3167 0.5 12.5543 0.75 13.713 1.25C14.871 1.75 15.875 2.425 16.725 3.275C17.575 4.125 18.25 5.129 18.75 6.287C19.25 7.44567 19.5 8.68333 19.5 10C19.5 11.3167 19.25 12.554 18.75 13.712C18.25 14.8707 17.575 15.875 16.725 16.725C15.875 17.575 14.871 18.25 13.713 18.75C12.5543 19.25 11.3167 19.5 10 19.5ZM10 18C12.2167 18 14.1043 17.221 15.663 15.663C17.221 14.1043 18 12.2167 18 10C18 7.78333 17.221 5.89567 15.663 4.337C14.1043 2.779 12.2167 2 10 2C7.78333 2 5.896 2.779 4.338 4.337C2.77933 5.89567 2 7.78333 2 10C2 12.2167 2.77933 14.1043 4.338 15.663C5.896 17.221 7.78333 18 10 18Z" fill="#814097"/></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
