// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import MASidebar from '../components/MASidebar';
import MANavbar from '../components/MANavbar';
// import ChargerList from '../components/ChargerList';
// import AddACharger from '../components/AddACharger';
import UpdateCPO from '../components/UpdateCPO';

function UpdateCPOPage() {
  const navigate = useNavigate();
  const [access_token, setAccessToken] = useState(localStorage.getItem('master_token'));
  const [user, setUser] = useState(null);
  // cosnt [data1, setData1] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const token = localStorage.getItem('master_token');
    if (token === null) {
      navigate('/admin/login');
    }else{     
      setLoading(true)
    }
  }, [])

  const token = localStorage.getItem('master_token');
  useEffect(() => {
    if(!access_token){
      setAccessToken(localStorage.getItem('master_token'));
    }
    console.log("token found value is : ", token);
    axios.get("https://api.pea-cpo.com/api/getAdminProfile", {headers: {'Authorization' : `Bearer ${access_token}`}}).then((res) => {
      console.log("user request response : ", res);
      setUser(res?.data?.username);
    }).catch((err) =>{
      console.log("Error get user : ", err);
    })
  }, [access_token])

  // useEffect(() => {
  //   const access_token = localStorage.getItem('master_token');
  //   axios.get(`https://api.pea-cpo.com/api/getCpoDetail/${id}`, {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
  //       console.log("Cpo request res : ", res);
  //       console.log("Cpo Response data : ", res.data);
  //       setData1(res?.data);
        
  //     }).catch((err) => {
  //       console.log("Error : ", err);
  //   })
  // }, [])

  

  if(loading){
  return (
    <div className='d-flex'>
      <MASidebar />
      <div className='content-div'>
        <MANavbar user={user} />  
        <div className='main-div' style={{padding: '0px 32px'}}>
        <UpdateCPO />
        </div>
      </div>
    </div>
  );
  }else{
    return(<div></div>);
  }
}

export default UpdateCPOPage;
