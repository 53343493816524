import React,{useEffect, useState} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import img from '../assets/login-formphoto.png';
import Logo from '../assets/logo.svg';
import emailIcon from '../assets/email.svg';
import lockIcon from '../assets/lockIcon.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Audio, BallTriangle } from  'react-loader-spinner';


const Login = () => {
    const [authentication, setAuthentication] = useState({email:'', password:''})
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false)
    const [error, setError] = useState(false);
    const [req, setReq] = useState(0);
    const [loader, setLoader] = useState(false);

    const inputStyle = {
        padding: '10px 14px',
        paddingLeft: '42px',
        // background: '#FFFFFF',
        // border: '1px solid #F4F4F4',
        // boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        borderRadius: '8px',
        outline: 'none'
    }

    function handleChange(e){
        setAuthentication({
            ...authentication,
            [e.target.name]: e.target.value
        })
    }

    function LoginUser(){
        if(authentication.email !== '' && authentication.password !== ''){
            setLoader(true);
            // axios.post(`https://cpo-backend.trigondigital.net/api/login`, { ...authentication })
            axios.post(`https://api.pea-cpo.com/api/login`, { ...authentication })
            .then(res => {
                console.log("response : : ", res);
                if(res.data.message === 'Successfully Login'){
                    if(rememberMe){
                        localStorage.setItem('rememberMe', 'true');
                    }else{
                        localStorage.setItem('rememberMe', 'false');
                    }
                    localStorage.setItem('pea_tab', '1');
                    localStorage.setItem('token', res.data.token);
                    navigate('/');
                }else{
                    // alert('Invalid email or password');
                    setError(true);
                    setLoader(false);
                }
            }).catch((err) => {
                console.log("error : ", err);
                setError(true);
                setLoader(false);
            })
        }else{
            // alert('Fill all required fields')
            if(authentication.email == ''){
                setReq(1);
            }
            if(authentication.password == ''){
                setReq(2);
            }
            if(authentication.password == '' && authentication.email == ''){
                setReq(3);
            }

        }
    }

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token){
            navigate('/');
        }
    }, [])

    return (
        <>
            {loader ? (
                <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '37vh'}}>
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#814097"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                </div>
            ) : (
            <div className='login'>
                <Container fluid>
                <Row>
                    <Col md={6}>
                        <div className='login-right-div'>
                            <div className='text-center'>
                                <img src={Logo} className="loginform-logo" width="170px" />
                                <h2 className='font-style31 mb-0'>Welcome back!</h2>
                                <h6 className='font-style32'>EV Charging Management Platform</h6>
                            </div>
                            {/* <div className="col-md-6">
                                <label for="validationServer03" className="form-label">City</label>
                                <input type="text" className="form-control is-invalid" id="validationServer03" aria-describedby="validationServer03Feedback" required />
                                <div id="validationServer03Feedback" className="invalid-feedback">
                                Invalid email or password
                                </div>
                            </div> */}
                            <div className='login-input-field-div mt-32'>
                                <label className='input-field-label'>Email</label>
                                <div>
                                    <img src={emailIcon} width="16.67px" className='input-field-icon' height="13.33px" alt="icon" />
                                    <input type="email" id="validationServer04" style={error ? inputStyle : {}} className={error ? "form-control w-100 is-invalid" : "login-input-fields w-100"} placeholder='Enter your Email' aria-describedby="validationServer04Feedback" required name="email"  value={authentication.email} onChange={handleChange}/>
                                </div>
                                {req === 1 || req === 3 && (
                                    <p className='text-info pt-2'>Email is required</p>
                                )}
                            </div>
                            <div className='login-input-field-div mt-20'>
                                <label className='input-field-label'>Password</label>
                                <div>
                                    <img src={lockIcon} width="16.67px" className='input-field-icon' height="13.33px" alt="icon" />
                                    <input type="password" id="validationServer03" placeholder='Enter your Password' style={error ? inputStyle : {}} className={error ? "form-control w-100 is-invalid" : "login-input-fields w-100"} name="password" aria-describedby="validationServer03Feedback" required  value={authentication.password} onChange={handleChange}/>
                                </div>
                                {error && (
                                    <p className='text-danger pt-2'>Invalid email or password</p>
                                )}
                                {req === 2 || req === 3 && (
                                    <p className='text-info pt-2'>Password is required</p>
                                )}
                                {/* <div id="validationServer03Feedback" className="invalid-feedback">
                                    
                                </div> */}
                            </div>
                            <div className="form-check login-input-field-div mt-28">
                                <input className="form-check-input" type="checkbox" value={rememberMe} onChange={(e)=>setRememberMe(e.target.value)} id="flexCheckDefault" />
                                <label className="form-check-label font-style33 w-100" >
                                <span for="flexCheckDefault">Remember</span>   <span className='font-style34' onClick={() => navigate('/forget-password')}>Forget Password?</span>
                                </label>
                            </div>
                            <div className='login-input-field-div'>
                                <button type='button' className='login-btn' onClick={LoginUser}>Log In</button>
                            </div>
                            <div className='login-input-field-div'>
                                <h5 className='font-style35'>Become a PEA VOLTA partner <span className='font-style36' onClick={() => navigate('/registeration')}>Register Here</span></h5>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="p-0">
                        <img src={img} alt={img} className="form-img" />
                    </Col>
                </Row>
                </Container>
            </div>
            )}
        </>
    )
}

export default Login;