import React, { useState } from 'react';
import ellipseIcon from '../assets/symbol.svg';
import groupIcon from '../assets/Group 31.svg';
import penMarkerIcon from '../assets/pin-marker.svg';
import grayPenMarkerIcon from '../assets/grap-penmarker.svg';
import symbolIcon from '../assets/symbol1.svg';
import { useEffect } from 'react';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import PieChart from './reuseable/PieChart';
import { BallTriangle } from 'react-loader-spinner';
import DataTableBase from './reuseable/DataTableBase';

const AdminDashboard = () => {
    const [data1, setData1] = useState(null);
    const [stationTableData, setStationTableData] = useState(null);
    const [data, setData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [chartDataX, setChartDataX] = useState(null);
    const [chartDataY, setChartDataY] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);

    const customColors = ['#12B76A', '#0BA5EC', '#9C54B4', '#C6C6C6', '#EE7200', '#FF5733', '#FFC300'];
    

    useEffect(() => {
        const access_token = localStorage.getItem('master_token');
        axios.get('https://api.pea-cpo.com/api/dashbaord/dataTableStat', {headers: {'Authorization' : `Bearer ${access_token}`}}).then((res) => {
            console.log("Check API Response : ", res);
            setData(res?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("Check API error : ", err); 
        })
    }, [])

    useEffect(() => {
        const access_token = localStorage.getItem('master_token');
        axios.get('https://api.pea-cpo.com/api/dashbaord/dataStat', {headers: {'Authorization' : `Bearer ${access_token}`}}).then((res) => {
            console.log("Dashboard data Response : ", res);
            const resData= res?.data?.data;
            const temData = res?.data?.data?.pieChartData?.map(item => ({
                x: item.provider_name,
                y: item.cpo_chargers_count,
            }));

            const labs = res?.data?.data?.pieChartData?.map(item => item?.provider_name);
            
            const vals = res?.data?.data?.pieChartData?.map(item => item?.cpo_chargers_count);

            const stationData = [
                {
                    label: 'Total Station',
                    value: resData?.totalStations,
                },
                {
                    label: 'Total Used Station',
                    value: resData?.totalStationsUsed,
                },
                {
                    label: 'Total Unused station',
                    value: resData?.totalStationsUnUsed,
                }
            ];

            setStationTableData(stationData);

            setChartDataX(labs);
            setChartDataY(vals);

            console.log("Tem data : ", temData)
            setData1(res?.data?.data);
            setChartData(temData);
            // setLoading(false);

        }).catch((err) => {
            // setLoading(false);
            console.log("Error : ", err);
        })
    }, [])


    const data2 = [
        { id: 1, name: 'John Doe', age: 25, country: 'USA' },
        { id: 2, name: 'Jane Smith', age: 30, country: 'Canada' },
        // ... other data objects
      ];
    
    const columns = [
        {
          name: 'Provider Name',
          selector: row => row?.provider_name,
          sortable: true,
        },
        {
          name: 'Number os Stations',
          selector: row => row?.cpo_chargers_count,
          sortable: true,
        },
        {
          name: 'Revnue',
          selector: row => { 
            let sum = 0;
            row?.cpo_chargers?.map(item => {
                item?.chargers?.customers?.map(customer => {
                    sum = sum + parseFloat(customer?.revenue)
                })
            });
            return  (Math.ceil(sum * 100) / 100).toLocaleString();
          },
          sortable: true,
        },
        {
          name: 'Energy Useg',
          selector: row => {
            let sum = 0;
            row?.cpo_chargers?.map(item => {
                item?.chargers?.customers?.map(customer => {
                    sum = sum + parseFloat(customer?.totalKwh);
                });
            })
            return (Math.ceil(sum * 100) / 100).toLocaleString();
          },
          sortable: true,
        },
        {
          name: 'Created',
          selector: row => row?.created_at,
          sortable: true,
        },
        {
          name: 'Modified',
          selector: row => row?.updated_at,
          sortable: true,
        },
    ];

    const colums2 = [
        {
            name: 'Label',
            selector: row => row?.label,
        },
        {
            name: 'Value',
            selector: row => row?.value
        }
    ];
    
    // const handleRowExpand = (row) => {
    //     const isRowExpanded = expandedRows.includes(row?.id);
    //     if (isRowExpanded) {
    //       setExpandedRows(expandedRows.filter(id => id !== row?.id));
    //     } else {
    //       setExpandedRows([...expandedRows, row.id]);
    //     }
    // };



    const ExpandableContent = ({ data }) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // Returns a value from 0 to 11, where 0 represents January and 11 represents December
        const currentYear = currentDate.getFullYear();
        console.log("Current Month:", currentMonth, currentYear);

        const [year, setYear] = useState(currentYear);
        const [month, setMonth] = useState(currentMonth + 1);
        const [filteredData, setFilteredData] = useState(data)
        
        console.log("DATA data nested table ", data);
        
        return (
            <div className='py-4 px-4'>
                <div className='w-100 d-flex justify-content-end mb-2'>
                    <div className='w-25'>
                        <select className='form-select' onChange={(e) => setYear(e.target.value) }>
                            <option>Select Year</option>
                            <option value={2023}>2023</option>
                            <option value={2022}>2022</option>
                            <option value={2021}>2021</option>
                        </select>
                    </div>
                    <div className='w-25'>
                        <select className='form-select' onChange={(e) => setMonth(e.target.value)}>
                            <option>Select Month</option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                    </div>
                </div>
                <DataTableBase
                    columns={[
                        {
                        name: 'Station Name',
                        selector: row => row?.chargers?.locationTH,
                        sortable: true,
                        },
                        {
                        name: 'Number of Transacions',
                        selector: row => row?.chargers?.customers?.length,
                        sortable: true,
                        },
                        {
                        name: 'Energy Useg  Kwh',
                        selector: row => {
                            let sum = 0;
                            row?.chargers?.customers?.map(item => {
                                const startDate = new Date(item?.startDate); //"2023-03-17"
                                const itemYear = startDate.getFullYear();
                                const itemMonth = startDate.getMonth() + 1;
                                
                                if((year === null || Number(year) === itemYear) && (month === null || Number(month) === itemMonth)){
                                    sum = sum + parseFloat(item?.totalKwh);                                    
                                }
                            })
                            
                            return (Math.ceil(sum * 100) / 100).toLocaleString();
                        },
                        sortable: true,
                        },
                        {
                        name: 'Revenue',
                        selector: row => {
                            let sum = 0;
                            row?.chargers?.customers?.map(item => {
                                const startDate = new Date(item?.startDate); //"2023-03-17"
                                const itemYear = startDate.getFullYear();
                                const itemMonth = startDate.getMonth() + 1;
                                
                                if((year === null || Number(year) === itemYear) && (month === null || Number(month) === itemMonth)){
                                    sum = sum + parseFloat(item?.revenue);
                                }
                            })
                            return (Math.ceil(sum * 100) / 100).toLocaleString();
                        },
                        sortable: true,
                        },
                        {
                        name: 'Status',
                        selector: row => row?.chargers?.chargerStatusEN,
                        sortable: true,
                        },
                    ]}
                    data={data?.cpo_chargers}
                    noHeader
                    dense
                    // pagination
                />
            </div>
        );
    }

    
    
    // const expandableRows = data?.map(row => ({
    //     ...row,
    //     onClick: () => handleRowExpand(row),
    //     isExpanded: expandedRows.includes(row?.id),
    //     subRows: [
    //       {
    //         name: `${row?.provider_name} details`,
    //         age: row?.age,
    //         country: row?.country,
    //       },
    //     ],
    // }));


    // const expandableRows = data?.map(parent => ({
    //     ...parent,
    //     onClick: () => handleRowExpand(parent),
    //     isExpanded: expandedRows.includes(parent.id),
    //     subRows: [
    //       {
    //         childTable: (
    //           <DataTableBase
    //             columns={[
    //               {
    //                 name: 'Station Name',
    //                 selector: row => row?.chargers?.locationTH,
    //                 sortable: true,
    //               },
    //               {
    //                 name: 'Number of Transacions',
    //                 selector: row => row?.chargers?.customers?.length,
    //                 sortable: true,
    //               },
    //               {
    //                 name: 'Energy Useg  Kwh',
    //                 selector: 10,
    //                 sortable: true,
    //               },
    //               {
    //                 name: 'Revenue',
    //                 selector: 2893,
    //                 sortable: true,
    //               },
    //               {
    //                 name: 'Status',
    //                 selector: row => row?.chargers?.chargerStatusEN,
    //                 sortable: true,
    //               },
    //             ]}
    //             data={data?.filter(child => {
    //                 if(child?.id === parent?.id){
    //                     return child?.cpo_chargers;
    //                 }
    //             })}
    //             noHeader
    //             dense
    //             pagination
    //           />
    //         ),
    //       },
    //     ],
    // }));

    // const expandableRows = data?.map(parent => ({
    //     ...parent,
    //     onClick: () => handleRowExpand(parent),
    //     isExpanded: expandedRows.includes(parent.id),
    //     subRows: [
    //       {
    //         childTable: <ExpandableContent rowData={parent} />,
    //       },
    //     ],
    // }));


    const TestExpandable = ({data}) => {
        console.log("Row of table : ", data );
        return (
            <div>
                expandable row
            </div>
        )
    }

    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;


    if(loading){
        return (
            <div className='bg-white w-100' style={{ height: '100vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '33vh'}}>
                <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#814097"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                />
            </div>
        )
    } else {

        return (
            <>
                <div className='w-100 cards-wrapper d-flex mt-4'>
                    <div className='cards d-flex'>
                        <div>
                        <img src={ellipseIcon} width="32px" height="32px" alt="" />
                        </div>
                        <div className=''>
                        <h6 className='font-pkg mb-0'>Total CPO</h6>
                        <h6 className='font-pkg-title mb-0'>{data1?.totalCpos}</h6>
                        </div>
                    </div>
                    <div className='cards d-flex'>
                        <div>
                        <img src={groupIcon} width="32px" height="32px" alt="" />
                        </div>
                        <div className=''>
                        <h6 className='font-pkg mb-0'>Total Station</h6>
                        <h6 className='font-pkg-title mb-0'>{data1?.totalStations}</h6>
                        </div>
                    </div>
                    <div className='cards d-flex'>
                        <div>
                        <img src={penMarkerIcon} width="32px" height="32px" alt="" />
                        </div>
                        <div className=''>
                        <h6 className='font-pkg mb-0'>Online Station</h6>
                        <h6 className='font-pkg-title mb-0'>{data1?.totalStationsOnline}</h6>
                        </div>
                    </div>
                    <div className='cards d-flex'>
                        <div>
                        <img src={grayPenMarkerIcon} width="32px" height="32px" alt="" />
                        </div>
                        <div className=''>
                        <h6 className='font-pkg mb-0'>Offline Station</h6>
                        <h6 className='font-pkg-title mb-0'>{data1?.totalStationsOffline}</h6>
                        </div>
                    </div>
                    <div className='cards d-flex'>
                        <div>
                        <img src={symbolIcon} width="32px" height="32px" alt="" />
                        </div>
                        <div className=''>
                        <h6 className='font-pkg mb-0'>Faulted Station</h6>
                        <h6 className='font-pkg-title mb-0'>{data1?.totalStationsFaulted}</h6>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col className='pt-4'>
                        {chartData && (
                            <PieChart 
                                // data={{
                                //     "Total CPO": data?.totalCpos, 
                                //     "Total Stations": data?.totalStations,
                                //     "Total Available Station": data?.totalStationsOnline,
                                //     "Total Offline Station": data?.totalStationsOffline,
                                //     "Total Station Unused": data?.totalStationsUnUsed,
                                //     "Total Station Used": data?.totalStationsUsed, 
                                //     "Total Faulated Stations": data?.totalStationsFaulted, 
                                // }} 
                                chartData = {chartData}
                                colors={customColors}  
                                chartDataX={chartDataX}       
                                chartDataY={chartDataY}       
                            />
                        )} 
                    </Col>
                    <Col className='pl-32 pt-5 pb-5'>
                        <DataTableBase 
                            title="Stations Detail"
                            columns={colums2}
                            data={stationTableData}
                        />
                    </Col>
                </Row>
                <Row className='px-4 mb-5'>
                    <Col>
                        {data && (
                            <DataTableBase
                                title="Expandable Rows Table"
                                columns={columns}
                                data={data}
                                expandableRows
                                // expandableRowsComponent={({ data }) => (
                                //     <div>
                                //     <p>Name: {data?.provider_name}</p>
                                //     <p>Age: {data.age}</p>
                                //     <p>Country: {data.country}</p>
                                //     </div>
                                // )}

                                // expandableRowsComponent={<div />}
                                // expandableRowsComponent={<TestExpandable />}
                                // expandableRowsComponent={ExpandedComponent}
                                // expandableRowsComponent={TestExpandable}
                                expandableRowsComponent={ExpandableContent}
                            />
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

export default AdminDashboard;