import React, {useState, useEffect} from 'react';
import DataTableBase from './reuseable/DataTableBase';
import DatePicker from './reuseable/DatePicker';
import StationGraphCard from './reuseable/StationGraphCard';

import Select from 'react-select';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';

import XLSX from 'xlsx';

const ReportTransaction = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);


    const [loader, setLoader] = useState(true);
    const [data, setData] = useState(true);
    const [filteredData, setFilteredData] = useState(true);

    const [tableData, setTableData] = useState(null);
    const [tableFilterdData, setTableFilteredData] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [checkPoint, setCheckPoint] = useState(false);

    const [filter, setFilter] = useState(null);

    const [dateRange2, setDateRange2] = useState(null);


    console.log("Selected Option : : : ", selectedOption);


    useEffect(() => {
        const token = localStorage.getItem('token');
        // console.log("token found value is : ", token);
        axios.get("https://api.pea-cpo.com/api/getChargerListCpo", {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("Get Charger list for options  : ", res);
            const opArr = [];

            res?.data?.data && res?.data?.data?.map((item) => {
                opArr.push({value: item?.locationEN, label: item?.locationTH});
            })

            console.log("Options options : ", opArr)
            setOptions(opArr);
            // setStations(res?.data?.data);
            
        }).catch((err) =>{
        console.log("Error get user : ", err);
        })
    }, [])






    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://api.pea-cpo.com/api/overviewPageChargingList', {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("Get Charger detail test test : ", res);
            
            setData(res?.data?.data);
            setFilteredData(res?.data?.data);

            const tabDataFilter = res?.data?.data && res?.data?.data?.map((item) => {
                const obj = {
                    "Start Charge": `${item?.startDate} ${item?.startTime}`,
                    "End Charge": `${item?.endDate} ${item?.endTime}`,
                    "startDate": item?.startDate,
                    "endDate": item?.endDate,
                    "Connector": item?.connector,
                    "Charger Name": item?.locationTH,
                    "Duration (mins)": parseFloat(item?.duration),
                    "Energy (kWh)": parseFloat(item?.totalKwh),
                    "Revenue (THB)": parseFloat(item?.revenue),
                }
                return obj;
            })


            console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
            console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
            console.log("Structured data : ", tabDataFilter)
            console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
            console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
            setTableData(tabDataFilter);
            setTableFilteredData(tabDataFilter);
            const ans= calculateMetrics(res?.data?.data);
            // console.log("Ans data : ", ans);
            setMetrics(ans);

            const ans2 = calculateChartData(res?.data?.data);
            console.log("Ans Two data : : ", ans2)

            setChartData(ans2);

            // const tabData = calculateTableData(res?.data?.data);

            // console.log("Table Data : ", tabData);

            // const revTabData = tabData.reverse();


            // setTableData(revTabData);
            
            setLoader(false);
        
        }).catch((err) =>{
            console.log("Error get user : ", err);
            setLoader(false);
        })
    }, [])

    useEffect(() => {
        console.log("Filter val 1: ", filter)
        if(filter === 'Today'){
            console.log("Filter val : ", filter);
            const revenueArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            const metrics = {
                session: 0,
                duration: 0,
            };

            for (let i = 0; i < data.length; i++) {
                const duration = parseFloat(data[i]?.duration);
                const startDate = new Date(data[i]?.startDate);
                const startTime = data[i]?.startTime;

                const today = new Date();
                if(filter === 'Today'){
                    if (
                      startDate.getFullYear() !== today.getFullYear() ||
                      startDate.getMonth() !== today.getMonth() ||
                      startDate.getDate() !== today.getDate()
                    ) {
                      // console.log("Wrong today");
                      continue;
                    }
                }

                if(selectedOption){
                    if(selectedOption?.value !== data[i]?.locationEN){
                        continue;
                    }
                }

                const hour = Number(startTime.slice(0,2));

                revenueArr[hour] += duration; 

                metrics.session += 1;
                metrics.duration += duration;
            }

            console.log("Today Ans data : ", revenueArr);
            setMetrics(metrics);

            console.log("Today Ans Two data : : ", metrics)

            setChartData(revenueArr);
            setCheckPoint(true);
            
        } else {
            const ans= calculateMetrics(data);
            // console.log("Ans data : ", ans);
            setMetrics(ans);
            
            const ans2 = calculateChartData(data);
            console.log("Ans Two data : : ", ans2)
            
            setChartData(ans2);
            setCheckPoint(false);
            console.log("Seleect option else part worked")
        }
    }, [filter, selectedOption])
    
    // function toggleDate(){
    //   if(display.display === 'none')
    //   setDisplay({display: 'block'})
    //   else
    //   setDisplay({display: 'none'})
    // }

    // function handleSelect(date){
    //     setRange({ 
    //         startDate: date.selection.startDate,
    //         endDate: date.selection.endDate,
    //         key: date.selection.key
    //     })
    // }

    function calculateMetrics(para){
        const met = {
            duration: 0,
            session: 0
        };
        console.log("Para : ", para);
        console.log("Para : length", para?.length);
        


        for(let i = 0; i < para?.length; i++){
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }
            if(selectedOption){
                if(selectedOption?.value !== para[i]?.locationEN){
                    continue;
                }
            }

            met.duration += Number(para[i]?.duration);
            met.session += 1;
        }

        return met;
    }

    function calculateChartData(para){
        const revenueArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        console.log("Para para para : : : ", para);
        for(let i = 0; i < para?.length; i++){
            const duration = parseFloat(para[i]?.duration);
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }

            if(selectedOption){
                if(selectedOption?.value !== para[i]?.locationEN){
                    continue;
                } else {
                    // console.log("selected op : ", selectedOption?.value)
                    // console.log("data name : ", para[i].locationEN)
                    console.log("data name true...");
                    
                }
            }



            const month = startDate.getMonth();
            // revenueArr[month] += duration; 
            revenueArr[month] += 1; 
        }

        return revenueArr;
    }


    function convertToWorksheet(tableData) {
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Set width for all columns to 15 characters
        // worksheet['!cols'] = Array(worksheet['!ref'].split(':')[1].charCodeAt(0) - 64).fill({ width: 25 });
        const columnWidths = [24, 24, 12, 12, 12, 36, 17,15, 15]

        worksheet['!cols'] = columnWidths.map(width => ({ width }));

        return worksheet;
    }

    function downloadExcelFile(tableData, fileName) {
        const worksheet = convertToWorksheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }

    const now = new Date();

    const isoDateTime = now.toISOString();
    
    function handleDownloadExcel(fileData) {

        if(dateRange2?.option){
            // Filter the data based on the date range
            const start = new Date(dateRange2?.startDate);
            const end = new Date(dateRange2?.endDate);

            const filteredData2 = fileData && fileData.filter(obj => {
                // console.log("obj : ", obj)
                const currentDate = new Date(obj.startDate);
                // console.log("current date : ", currentDate, obj.startDate);
                // console.log("filter row : ", currentDate >= start && currentDate <= end)
                // console.log("Start date : ", start)
                // console.log("End date : ", end)
                return currentDate >= start && currentDate <= end;
            });

            // const structuredData2 = [];
            // const StructureData = filteredData2?.filter(item => {
            //     const obj = {"Start Charge": `${item?.startDate} ${item?.startTime}`, "End Charge": `${item?.endDate} ${item?.endTime}`, "Connector": item?.connector, "Charge Name": item?.locationTH, "Duration(mins)": item?.duration, "Energy(kWh)": item?.totalKwh, "Revenue(THB)": item?.revenue };
            //     structuredData2?.push(obj);
            //     return obj;
            //     }
            // )
             
            // console.log("Filtered data : ", filteredData2);
            // downloadExcelFile(filteredData2, `Transaction Report ${isoDateTime}`);
            downloadExcelFile(filteredData2, `Transaction Report ${isoDateTime}`);
            // setFilteredData(filteredData2);
        } else {
            // console.log("File data file data : ", fileData);
            // const structuredData2 = [];
            // const StructureData = fileData?.filter(item => {
            //     const obj = {"Start Charge": `${item?.startDate} ${item?.startTime}`, "End Charge": `${item?.endDate} ${item?.endTime}`, "Connector": item?.connector, "Charge Name": item?.locationTH, "Duration(mins)": item?.duration, "Energy(kWh)": item?.totalKwh, "Revenue(THB)": item?.revenue };
            //     structuredData2?.push(obj);
            //     return obj;
            //     }
            // )
             
            // console.log("Filtered data : ", filteredData2);

            downloadExcelFile(fileData, `Transaction Report ${isoDateTime}`);
        }
        
    }



    const caseInsensitiveSort = (rowA, rowB) => {
        const a = Math.ceil(rowA?.duration * 100) / 100;
        const b = Math.ceil(rowB?.duration * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };


    const caseInsensitiveSort2 = (rowA, rowB) => {
        const a = Math.ceil(rowA?.totalKwh * 100) / 100;
        const b = Math.ceil(rowB?.totalKwh * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };


    const caseInsensitiveSort3 = (rowA, rowB) => {
        const a = Math.ceil(rowA?.revenue * 100) / 100;
        const b = Math.ceil(rowB?.revenue * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };


    const columns = [
        {
            name: "Start Charge",
            selector: row => `${row?.startDate} ${row?.startTime}`,
            sortable: true,
        },
        {
            name: "End Charge",
            selector: row => `${row?.endDate} ${row?.endTime}`,
            sortable: true,
        },
        {
            name: "Connector",
            selector: row => row?.connector,
            sortable: true,
        },
        {
            name: "Charger Name",
            selector: row => row?.locationTH,
        },
        {
            name: "Duration (mins)",
            selector: row => (Math.ceil(row?.duration * 100) / 100).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort,
        },
        {
            name: "Energy (kWh)",
            selector: row => (Math.ceil(row?.totalKwh * 100) / 100).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort2,
        },
        {
            name: "Revenue (THB)",
            selector: row => (Math.ceil(row?.revenue * 100) / 100).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort3,
        },
    ];


    useEffect(() => {
        console.log("date range 2 : ", dateRange2)
        if(dateRange2?.option){
            // Filter the data based on the date range
            const start = new Date(dateRange2?.startDate);
            const end = new Date(dateRange2?.endDate);

            const filteredData2 = data && data.filter(obj => {
                // console.log("obj : ", obj)
                const currentDate = new Date(obj.startDate);
                // console.log("current date : ", currentDate, obj.startDate);
                // console.log("filter row : ", currentDate >= start && currentDate <= end)
                // console.log("Start date : ", start)
                // console.log("End date : ", end)
                return currentDate >= start && currentDate <= end;
            });
             
            console.log("Filtered data : ", filteredData2);
            setFilteredData(filteredData2);
        } else {
            setFilteredData(data);
        }
    }, [dateRange2?.startDate])







    if(loader){
        return (
          <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '17vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
          </div>
        )
    } else {

    return (
        <div className='station-revenue'> 
            <div className='w-100 d-flex'>
                <div className='w-50'>
                    <h3 className='font-style27 mb-0'>Transaction</h3>
                    <h6 className='font-style28'>ภาพรวมข้อมูลรายได้</h6>
                </div>
                <div className='w-50 d-flex justify-content-end'>
                {/* <select className="form-select d-inline-block" style={{width: '250px', height: '46px', marginRight: '14px'}} aria-label="Default select example">
                    <option selected>Select option</option>
                    <option value="1">All Station</option>
                    <option value="2">Station Two</option>
                    <option value="3">Station Three</option>
                </select> */}

                    <Select
                        className="d-inline-block w-50 mt-1" style={{width: '250px', height: '46px'}}
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                    />

                    {/* <button className='btn-style1'>Download</button> */}
                </div>
            </div>
            <StationGraphCard 
                val1={<h3 className='graph-card-number'>{(metrics?.session)?.toLocaleString()} <b className='font-style29'>Times</b></h3>} 
                val2={<h3 className='graph-card-number'>{Math.floor(Number(metrics?.duration) / 60)} <b className='font-style29'>hr</b> {Number(metrics?.session) % 60}<b className='font-style29'>mins</b></h3>} 
                val3={<h3 className='graph-card-number'>{Number(metrics?.duration) > 0 ? Math.floor((Number(metrics?.duration) / metrics?.session) / 60) : 0} <b className='font-style29'>hr</b> {Number(metrics?.duration) > 0 ? Math.floor((Number(metrics?.duration) / metrics?.session) % 60) : 0} <b className='font-style29'> mins</b></h3>} 
                l1="Total Sessions" l2="Total Durations" l3="Average Time per Session"  
                cols={3}
                statRev={true}

                label="Transaction"
                data2={chartData}
                setFilter={setFilter}
                filter={filter}
                symbol={"No. of Sessions"}
                />
            <div>
            <div className='w-100 d-flex justify-content-end'>
                <div className='w-252 mb-4 pb-2'>
                    <DatePicker dateRange={dateRange2} setDateRange={setDateRange2} />
                </div>
                <div className='ps-3'>
                    <button className='btn-style1' onClick={() => handleDownloadExcel(tableData)}>Download</button>
                </div>
            </div>
                {filteredData && (
                    <DataTableBase 
                        columns={columns}
                        data={filteredData}
                        title="Transaction"
                        pagination
                    />
                )}
            </div>
        </div>
    )}
}

export default ReportTransaction;