import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from 'axios';
import MASidebar from '../components/MASidebar';
import MANavbar from '../components/MANavbar';
import { BallTriangle } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';


function Chargers() {
  const navigate = useNavigate();
  const [access_token, setAccessToken] = useState(localStorage.getItem('master_token'));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState(null);
  const [location, setLocation] = useState(null);
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [flag, setFlag] = useState(1);
  const [loader, setLoader] = useState(true);

  const { id } = useParams();  
  
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);

  const showAlertFunction = () => {
    setShowAlert(true);
  };

  
  useEffect(() => {
    const token = localStorage.getItem('master_token');
    if (token === null) {
      navigate('/admin/login');
    }else{     
      setLoading(true)
    }
  }, [])


  useEffect(() => {
    const access_token = localStorage.getItem('master_token');
    axios.get('https://api.pea-cpo.com/api/getChargerList', {headers: {'Authorization': `Bearer ${access_token}`}}).then((response) => {
      console.log("Response chargers data : ", response?.data?.data);
      setData(response?.data?.data);
      setFilterData(response?.data?.data);
      setLoader(false);
    }).catch((error) => {
      console.log("Error : ", error);
      setLoader(false);
    })
  }, [flag])


  const token = localStorage.getItem('master_token');
  useEffect(() => {
    if(!access_token){
      setAccessToken(localStorage.getItem('master_token'));
    }
    axios.get("https://api.pea-cpo.com/api/getAdminProfile", {headers: {'Authorization' : `Bearer ${access_token}`}}).then((res) => {
      console.log("user request response : ", res);
      setUser(res?.data?.username);
    }).catch((err) =>{
      console.log("Error get user : ", err);
    })
  }, [access_token])


  useEffect(() => {
    const access_token = localStorage.getItem('master_token');
    axios.get(`https://api.pea-cpo.com/api/getCpoDetail/${id}`, {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
        console.log("Cpo request res : ", res);
        console.log("Cpo Response data : ", res.data);
        setData1(res?.data);
        
      }).catch((err) => {
        console.log("Error : ", err);
    })
  }, [])


  useEffect(() => {
    if(location !== null && number === null ){
      const result = data && data.filter((item) => {
        return item.locationEN.toLocaleLowerCase().match(location.toLocaleLowerCase()) || item.locationTH.toLocaleLowerCase().match(location.toLocaleLowerCase());
      });
      setFilterData(result);
    }
    if(location !== null && number !== null){
      const result = data && data.filter((item) => {
        return (item.locationEN.toLocaleLowerCase().match(location.toLocaleLowerCase()) || item.locationTH.toLocaleLowerCase().match(location.toLocaleLowerCase())) && (item.descriptionEN.toLocaleLowerCase().match(number.toLocaleLowerCase()) || item.descriptionTH.toLocaleLowerCase().match(number.toLocaleLowerCase()));
      });
      setFilterData(result);
    }
  }, [location || number])


  useEffect(() => {
    if(number !== null && location === null){
      const result2 = data && data.filter((item) => {
        return item.descriptionEN.toLocaleLowerCase().match(number.toLocaleLowerCase()) || item.descriptionTH.toLocaleLowerCase().match(number.toLocaleLowerCase());
      });
      setFilterData(result2);
    }
    if(number !== null && location !== null){
      const result2 = data && data.filter((item) => {
        return (item.descriptionEN.toLocaleLowerCase().match(number.toLocaleLowerCase()) || item.descriptionTH.toLocaleLowerCase().match(number.toLocaleLowerCase())) && (item.locationEN.toLocaleLowerCase().match(location.toLocaleLowerCase()) || item.locationTH.toLocaleLowerCase().match(location.toLocaleLowerCase()));
      });
      setFilterData(result2);
    }
  }, [number || location])


  const handleAlert = () => {
    setShowAlert1(true);
    setTimeout(() => {
      setShowAlert1(false);
      showAlertFunction();
    }, 2000); // Replace 3000 with the desired time in milliseconds
  };

  const handleAlert2 = () => {
    setShowAlert2(true);
    setTimeout(() => {
      setShowAlert2(false);
    }, 2000); // Replace 3000 with the desired time in milliseconds
  };
  
  
  function addCharger(charger_id){
    setLoader(true);
    const access_token = localStorage.getItem('master_token');
    axios.post('https://api.pea-cpo.com/api/addChargerCpo', {cpo_id: id, charger_id: charger_id}, {headers: {'Authorization': `Bearer ${access_token}`}}).then((response) => {
      // console.log("Response chargers: ", response);
      console.log("Response add charger data : ", response?.data);
      // alert("Charger added successfully.");
      setLoader(false);
      handleAlert();
      // setFlag(flag+1);
      setFlag(flag + 1);
    }).catch((error) => {
      console.log("Error : ", error);
      setLoader(false);
      if(error?.response?.data?.message === "Charger Already Into Cpo"){
        // setShowAlert2(true);
        handleAlert2();
      }
      // alert("Charger already exist.");
    })
  }
  

  if(loading){
  return (
    <div className='d-flex'>
      <MASidebar />
      <div className='content-div'>
        <MANavbar data={data1} user={user} />  
        <div className='main-div'>
          <div className='w-100  d-flex' style={{padding: '16px 32px'}}>
            <div className='w-50'><h4>Chargers List</h4></div>
            <div className='w-25 pe-2' style={{marginLeft: '-90px'}}>
              <div className='search-field w-100'>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#6F6F6F" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  <input type="text" onChange={(e) => setLocation(e.target.value)} placeholder="Search by Name" />
              </div>
            </div>
            <div className='w-25 ps-2'>
              <div className='search-field w-100'>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#6F6F6F" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  <input type="text" onChange={(e) => setNumber(e.target.value)} placeholder="Search by Address" />
              </div>
            </div>
              <button className='back-btn' onClick={() => navigate(`/admin/viewcpo/chargers/${id}`)}>Back</button>
          </div>
          
          {/* <SweetAlert title="This charger alread exist in cpo." onConfirm={this.onConfirm} onCancel={this.onCancel} /> */}
          
          {loader ? (
                <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '37vh'}}>
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#814097"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                </div>
            ) : (
                filterData  && filterData.map((item, id) => (
                  <div className="charger-box-div d-flex" style={{margin: '16px 32px'}} key={id}>
                    <div className="cbd-a">
                      <img src={item.chargerPhoto} height="300px" width="410px" alt="imgg" />
                    </div>
                    <div className='ps-4'>
                      <h4 className='mb-3 mt-5 pt-2'>{item.chargerID}</h4>
                      <h1 className='mb-3'>{item.locationTH}</h1>
                      <h5 className='mb-3'>{item.number}</h5>
                      <p>{item.descriptionTH}</p>
                      <button className='add-charger ms-0' onClick={() => addCharger(item.id)}>Add Charger</button>
                    </div>
                  </div>
                ))  
            )}
          
          {showAlert1 && (
            <SweetAlert success title="Congratulations!" onConfirm={() => setShowAlert1(false)} onCancel={() => setShowAlert1(false)}>
              Charger Added Successfully.
            </SweetAlert>
          )}

          {showAlert2 && (
            <SweetAlert title="Charger already exist into CPO!" onConfirm={() => setShowAlert2(false)} onCancel={() => setShowAlert2(false)} />
          )}

            {showAlert && (
              <SweetAlert
                custom
                showCancel
                showCloseButton
                confirmBtnText="Yes"
                cancelBtnText="No"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="light"
                // customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
                title="Do you want to add more chargers?"
                onConfirm={() => setShowAlert(false)}
                onCancel={() => navigate(`/admin/viewcpo/chargers/${id}`)}
              >
                click No to navigate to dashboard!
              </SweetAlert>
            )}

        </div>
      </div>
    </div>
  );
  }else{
    return(<div></div>);
  }
}

export default Chargers;
