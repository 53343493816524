import React, { useState } from 'react';
import Chart from 'react-apexcharts'

const ColumnGraph = ({tog, data2, label, labs}) => {
  const data =  [{
    name: label,
    // data: [5.3, 3.1, 7.0, 10.1, 4.0, 8.6, 6.2, 7.3, 1.4, 8, 5, 8.2]
    data: data2
  }]
  const option = {
    chart: {
      type: 'bar',
      height: 500,
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      },
    },
    plotOptions: {
          bar: {
            borderRadius: 5,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return (Math.ceil(val * 100) / 100).toLocaleString();
      },
      offsetY: -20,
      style: {
        colors: ["transparent"]
      }
    },
    responsive: [{
      breakpoint: 480
    }],
    xaxis: {
          // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          categories: labs? labs : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
    yaxis: {
          axisBorder: {
            show: false
            // show: true
          },
          axisTicks: {
            show: false,
            // show: true,
          },
          labels: {
            // show: false,
            show: true,
            formatter: function (val) {
              return (Math.ceil(val * 100) / 100).toLocaleString();
            }
          }    
        },
    fill: {
      opacity: 1,
      colors: ['#B76AD1']
    }
  }

  return (
    <div id="chart">
      <Chart options={option} series={ data } type="bar" height={350} />
    </div>
  )
}

export default ColumnGraph