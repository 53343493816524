import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import DataTableBase from './reuseable/DataTableBase';
import DatePicker from './reuseable/DatePicker';
import StationGraphCard from './reuseable/StationGraphCard';

const StationEnergyUse = () => {
    const [data, setData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [loader, setLoader] = useState(true);

    const [filter, setFilter] = useState(null);

    
    const { id } = useParams();

    const [dateRange2, setDateRange2] = useState(null);


    useEffect(() => {
        function filterDataByDateRange(startDateStr, endDateStr, data) {
            const startDate = new Date(startDateStr);
            const endDate = new Date(endDateStr);
        
            const filteredData = data.filter((item) => {
            const itemDate = new Date(item.startDate);
            return itemDate >= startDate && itemDate <= endDate;
            });

            console.log("Filterd data set : ", filteredData);


            for(let a= 0; a < filteredData?.length; a++){
                console.log("date : ", filteredData[a]?.startDate)
            }
        
            const dateRevenueSessionMap = {};
        
            filteredData.forEach((item) => {
            const itemDate = new Date(item.startDate).toLocaleDateString();


        
            if (!dateRevenueSessionMap[itemDate]) {
                console.log("(item.energy) : ", item.totalKwh);
                dateRevenueSessionMap[itemDate] = {
                date: itemDate,
                energy: Number(item.totalKwh),
                offPeak: Number(item.offpeakKwh),
                onPeak: Number(item.onpeakKwh),
                session: 1,
                };
            } else {
                dateRevenueSessionMap[itemDate].energy += Number(item.totalKwh);
                dateRevenueSessionMap[itemDate].offPeak += Number(item.offpeakKwh);
                dateRevenueSessionMap[itemDate].onPeak += Number(item.onpeakKwh);
                dateRevenueSessionMap[itemDate].session += 1;
            }
            });
        
            // Convert the map into an array of objects
            const result = Object.values(dateRevenueSessionMap);
        
            return result;
        }

        if(dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today'){
            // Assuming your data is stored in the 'data' array
            
            // Example usage:
            const startDateStr = "2023-01-01";
            const endDateStr = "2023-03-01";
            const filteredData = filterDataByDateRange(dateRange2?.startDate, dateRange2?.endDate, data);
            console.log(filteredData);
            setTableData(filteredData);

            
        } else {
            const tabData = calculateTableData(data);

            console.log("Table Data : ", tabData);

            const revTabData = tabData.reverse();

    

            setTableData(revTabData);
        }

        
    }, [dateRange2?.startDate])

    // https://api.pea-cpo.com/api/overviewPageChargingListByID/1

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`https://api.pea-cpo.com/api/overviewPageChargingListByID/${id}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
        console.log("Get Charger detail : ", res);
        
        setData(res?.data?.data);

        const ans= calculateMetrics(res?.data?.data);
        // console.log("Ans data : ", ans);
        setMetrics(ans);
        
        const ans2 = calculateChartData(res?.data?.data);
        console.log("Ans Two data : : ", ans2)
        
        setChartData(ans2);

        const tabData = calculateTableData(res?.data?.data);

        const revTabData = tabData.reverse();

        setTableData(revTabData);

        setLoader(false);
        
        }).catch((err) =>{
            setLoader(false);
            console.log("Error get user : ", err);
        })
    }, [])


    function calculateMetrics(para){
        const met = {
            energy: 0,
            offPeak: 0,
            onPeak: 0,
            session: 0,
        };

        for(let i = 0; i < para?.length; i++){
            // const env = parseFloat(para[i]?.totalKwh);
            const startDate = new Date(para[i].startDate);


            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }


            met.energy += parseFloat(para[i]?.totalKwh);
            met.offPeak += parseFloat(para[i]?.offpeakKwh);
            met.onPeak += parseFloat(para[i]?.onpeakKwh);
            met.session += 1;
        }

        // para && para.forEach((item) => {
        //     met.energy += Number(item?.totalKwh);
        //     met.offPeak += Number(item?.offpeakKwh);
        //     met.onPeak += Number(item?.onpeakKwh);
        //     met.session += 1;
        // })

        return met;
    }

    function calculateChartData(para){
        const energyArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        
        for(let i = 0; i < para?.length; i++){
            const env = parseFloat(para[i]?.totalKwh);
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }

            const month = startDate.getMonth();
            energyArr[month] += env; 
        }

        return energyArr;
    }
    

    const columns = [
        {
            name: dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? "Date" : "Month",
            selector: row => dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? row?.date : row?.month,
            sortable: true,
        },
        {
            name: "Total Energy Usage (kWh)",
            selector: row => (Math.ceil(row?.energy * 100) / 100).toLocaleString(),
            sortable: true,
        },
        {
            name: "Off Peak (kWh)",
            selector: row => (Math.ceil(row?.offPeak * 100) / 100).toLocaleString(),
            sortable: true,
        },
        {
            name: "On Peak (kWh)",
            selector: row => (Math.ceil(row?.onPeak * 100) / 100).toLocaleString(),
        },
        {
            name: "Avg. Energy (kWh)",
            selector: row => row?.session ? (Math.ceil(row?.energy / row?.session * 100) / 100).toLocaleString() : 0,
            sortable: true,
        },        
    ];


    useEffect(() => {
        console.log("Filter val 1: ", filter)
        if(filter === 'Today'){
            console.log("Filter val : ", filter);
            const revenueArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            const metrics = {
                energy: 0,
                offPeak: 0,
                onPeak: 0,
                session: 0,
            };

            for (let i = 0; i < data.length; i++) {
                const energy = parseFloat(data[i]?.totalKwh);
                const offpeakKwh = parseFloat(data[i]?.offpeakKwh);
                const onpeakKwh = parseFloat(data[i]?.onpeakKwh);
                const startDate = new Date(data[i]?.startDate);
                const startTime = data[i]?.startTime;

                const today = new Date();
                if(filter === 'Today'){
                    if (
                      startDate.getFullYear() !== today.getFullYear() ||
                      startDate.getMonth() !== today.getMonth() ||
                      startDate.getDate() !== today.getDate()
                    ) {
                      // console.log("Wrong today");
                      continue;
                    }
                }

                const hour = Number(startTime.slice(0,2));

                revenueArr[hour] += energy; 

                // metrics.session += 1;
                // metrics.revenue += revenue;

                metrics.energy += energy;
                metrics.offPeak += offpeakKwh;
                metrics.onPeak += onpeakKwh;
                metrics.session += 1;
            }

            console.log("Today Ans data : ", revenueArr);
            setMetrics(metrics);

            console.log("Today Ans Two data : : ", metrics)

            setChartData(revenueArr);
            // setCheckPoint(true);
            
        } else {
            const ans= calculateMetrics(data);
            // console.log("Ans data : ", ans);
            setMetrics(ans);
            
            const ans2 = calculateChartData(data);
            console.log("Ans Two data : : ", ans2)
            
            setChartData(ans2);
            // setCheckPoint(false);
        }
    }, [filter])


    function calculateTableData(para){
        const dumArr = [
            {
                month: 'January',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'February',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'March',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'April',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'May',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'June',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'July',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'August',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'September',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'October',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'November',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'December',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
        ];

        for(let i = 0; i < para?.length; i++){
            const energy = parseFloat(para[i]?.totalKwh);
            const offpeak = parseFloat(para[i]?.offpeakKwh);
            const onpeak = parseFloat(para[i]?.onpeakKwh);
            const startDate = new Date(para[i].startDate);
            const getMon = startDate.getMonth();


            if(dateRange2?.option){
                const startRange = new Date(dateRange2?.startDate);
                const endRange = new Date(dateRange2?.endDate);

                if(startDate >= startRange && startDate <= endRange){

                } else {
                    continue;
                }

            } else {
                if (startDate.getFullYear() !== new Date().getFullYear()) {
                  continue;
                }
            }

            dumArr[getMon].session +=1;
            dumArr[getMon].energy += energy;
            dumArr[getMon].offPeak += offpeak;
            dumArr[getMon].onPeak += onpeak;

            // const month = startDate.getMonth();
            // dumArr[month] += rev; 
        }

        const newArr = [];

        dumArr && dumArr.map(item => {
            if(item?.session > 0){
                newArr.push(item);
            }
        })

        return newArr;
    }


    const data1 = [
        {
            id: 1,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 2,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 3,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 4,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 5,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 6,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 7,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
    ];

    if(loader){
        return (
          <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '17vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
          </div>
        )
      } else {

    return (
        <div className='station-revenue'>
            <div className='w-100 d-flex'>
                <div className='w-50'>
                    <h3 className='font-style27 mb-0'>Energy Use</h3>
                    <h6 className='font-style28'>ภาพรวมข้อมูลการใช้พลังงานไฟฟ้า</h6>
                </div>
                <div className='w-50'>
                    {/* <button className='btn-style1'>Download</button> */}
                </div>
            </div>
            <StationGraphCard 
                val1={<h3 className='graph-card-number'>{(Math.ceil(metrics?.energy * 100) / 100).toLocaleString()} <b className='font-style29'>kWh</b></h3>} 
                val2={<h3 className='graph-card-number'>{(Math.ceil(metrics?.offPeak * 100) / 100).toLocaleString()} <b className='font-style29'>kWh</b></h3>} 
                val3={<h3 className='graph-card-number'>{(Math.ceil(metrics?.onPeak * 100) / 100).toLocaleString()} <b className='font-style29'>kWh</b></h3>} 
                val4={<h3 className='graph-card-number'>{metrics?.session > 0 ? (Math.ceil(metrics?.energy / metrics?.session * 100) / 100).toLocaleString() : 0}<b className='font-style29'>kWh</b></h3>} 
                l1="Total Energy Usage" l2="Off Peak" l3="On Peak" l4="Average Energy per session"  
                cols={4}
                statRev={true}
                label="Energy"
                data2={chartData}
                setFilter={setFilter}
                filter={filter}
                symbol={"kWh"}
                />
            <div className='w-100 d-flex my-4 justify-content-end'>
               <div className='mx-3'>
                    {/* <button onClick={()=>setToggleModel(true)} className='btn-style1'>Download</button> */}
                    {/* <button className='btn-style1'>Download</button> */}
               </div>
                <div className='section-3rdds'>
                    <DatePicker dateRange={dateRange2} setDateRange={setDateRange2} /> 
                </div>
            </div>
            <div>
                <DataTableBase 
                    columns={columns}
                    data={tableData && tableData}
                    title="Summary"
                    pagination
                />
            </div>
        </div>
    )}
}

export default StationEnergyUse;