import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from 'axios';
import MASidebar from '../components/MASidebar';
import MANavbar from '../components/MANavbar';
import { BallTriangle } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';
import CPOUpdate from '../components/CPOUpdate';


function CPOUpdatePage() {
  const navigate = useNavigate();
  const [access_token, setAccessToken] = useState(localStorage.getItem('master_token'));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
//   const [number, setNumber] = useState(null);
//   const [location, setLocation] = useState(null);
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [filterData, setFilterData] = useState(null);
//   const [flag, setFlag] = useState(1);
  const [loader, setLoader] = useState(true);

  const { id, cid } = useParams();  

  console.log("id id id id id id : ", id)
  console.log("cid cid cid cid cid cid : ", id)

  
  useEffect(() => {
    const token = localStorage.getItem('master_token');
    if (token === null) {
      navigate('/admin/login');
    }else{     
      setLoading(true)
    }
  }, [])


  useEffect(() => {
    const access_token = localStorage.getItem('master_token');
    axios.get('https://api.pea-cpo.com/api/getChargerList', {headers: {'Authorization': `Bearer ${access_token}`}}).then((response) => {
      console.log("Get Chargers List Response : ", response?.data?.data);
      setData(response?.data?.data);
      setFilterData(response?.data?.data);
      setLoader(false);
    }).catch((error) => {
      console.log("Error Get Chargers List: ", error);
      setLoader(false);
    })
  }, [])


  const token = localStorage.getItem('master_token');
  useEffect(() => {
    if(!access_token){
      setAccessToken(localStorage.getItem('master_token'));
    }
    axios.get("https://api.pea-cpo.com/api/getAdminProfile", {headers: {'Authorization' : `Bearer ${access_token}`}}).then((res) => {
      console.log("Get Admin Profile response : ", res);
      setUser(res?.data?.username);
    }).catch((err) =>{
      console.log("Error get admin profile : ", err);
    })
  }, [access_token])


  useEffect(() => {
    const access_token = localStorage.getItem('master_token');
    axios.get(`https://api.pea-cpo.com/api/getCpoDetail/${id}`, {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
        console.log("GET Cpo Detail request Response : ", res);
        // console.log("GET Cpo Response data : ", res.data);
        setData1(res?.data);
        
      }).catch((err) => {
        console.log("Error : ", err);
    })
  }, [])

  // useEffect(() => {
  //   const access_token = localStorage.getItem('master_token');
  //   axios.get(`https://api.pea-cpo.com/api/getCpoDetail/${id}`, {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
  //       console.log("Cpo request res : ", res);
  //       // console.log("Cpo Response data : ", res.data);
  //       setData1(res?.data);
  //       setLoader(false);
  //       if(res?.data?.status === 200){
  //       }
  //     }).catch((err) => {
  //       console.log("Error : ", err);
  //       setLoader(false);
  //   })
  // }, [])
  

  if(loading){
  return (
    <div className='d-flex'>
      <MASidebar />
      <div className='content-div'>
        <MANavbar data={data1} user={user} />  
        <div className='main-div' style={{padding: '0px 32px'}}>
            <CPOUpdate />
        </div>
      </div>
    </div>
  );
  }else{
    return(<div></div>);
  }
}

export default CPOUpdatePage;
