import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../style/StationOverview.css'
import GoogleMap from './reuseable/GoogleMap'
import SweetAlert from 'react-bootstrap-sweetalert';
import { useForm } from 'react-hook-form'

import { BallTriangle } from "react-loader-spinner";


export default function CPOUpdate() {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [profile, setProfile] = useState(null);
    const [imgPreview, setImgPreview] = useState([])
    const [station, setStation] = useState(null);
    const [loader, setLoader] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [selectedConnector, setSelectedConnector] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [location, setLocation] = useState({
        // lat:33.684422,
        // lng: 73.047882
    })

    const navigate = useNavigate();
 
    const {id, cid} = useParams();

    function handleLocation(event){
        const name = event.target.name
        const value = event.target.value
        if(name === 'lat'){
            setLocation({lng: location.lng, lat: parseInt(value)})
        }else if(name === 'lng'){
            setLocation({lat: location.lat, lng: parseInt(value)})
        }
    }

    function handleFile(event){
        if(event.target.files){
            setProfile(event.target.files[0]);

            const files = event.target.files
            let array = []
            for(var i=0;i<files.length;i++){
                array.push(URL.createObjectURL(files[i]))
            }
            setImgPreview([...array])
        }
    }

    function removeImg(index){
        const array = []
        for(var i=0;i<imgPreview.length;i++){
            if(i !== index){
                array.push(imgPreview[i])
            }
        }
        setImgPreview([...array])
    }

    useEffect(() => {
        const token = localStorage.getItem('master_token');

        // axios.get(`https://api.pea-cpo.com/api/getChargerListCpoById/${id}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
        axios.get(`https://api.pea-cpo.com/api/getChargerDetail/${cid}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
        console.log("Get Charger detail Response: ", res);
        
        setImgPreview([res?.data?.data[0]?.chargerPhoto])
        setStation(res?.data?.data[0]);

        setLocation({
            lat: res?.data?.data[0]?.lat,
            lng: res?.data?.data[0]?.lng
        })

        // reset({
        //     max_price: res?.data?.data[0]?.prices?.max_price, 
        //     min_price: res?.data?.data[0]?.prices?.min_price, 
        // })

        setLoader(false);
        
        
        // console.log("lat : ", res?.data?.username[0]?.lat)
        // console.log("lng : ", res?.data?.username[0]?.lng)

        }).catch((err) =>{
            setLoader(false);
            console.log("Error Get Charger Detail : ", err);
        })
    }, [refresh])

    // console.log("image preview : ", imgPreview);
    console.log("Selected connector : ", selectedConnector);


    const handleAlert2 = () => {
        setShowAlert2(true);
        setTimeout(() => {
            setShowAlert2(false);
            // navigate(`/admin/viewcpo/chargers/${id}`);
        }, 2000)
    }

    const handleUpdateSubmit = (data) => {
        // alert("form submitted");
        setLoader(true);
        console.log(data)
        // chargerID,
        // number,
        // location_textEN,
        // location_textTH,
        // description_textEN,
        // description_textTH,
        // description_textProvinceNameEN,
        // description_textProvinceNameTH,
        // lat,
        // lng,
        // chargerPhoto        
        // max_price,
        // min_price,
        // on_peakPrice,
        // off_peakPrice,

        const formData = new FormData();
        formData.append("charger_id", cid);
        formData.append("chargerID", data?.chargerID !== '' ? data?.chargerID : station?.chargerID);
        formData.append("number", data?.number !== '' ? data?.number : station?.number);
        formData.append("location_textEN", data?.locationEN !== '' ? data?.locationEN : station?.locationEN);
        formData.append("location_textTH", data?.locationTH !== '' ? data?.locationTH : station?.locationTH);
        formData.append("description_textEN", data?.descriptionEN !== '' ? data?.descriptionEN : station?.descriptionEN);
        formData.append("description_textTH", data?.descriptionTH !== '' ? data?.descriptionTH : station?.descriptionTH);
        formData.append("lat", location?.lat);
        formData.append("lng", location?.lng);
        formData.append("chargerPhoto", profile);
        formData.append("max_price", data?.max_price !== '' ? data?.max_price : station?.prices?.max_price);
        formData.append("min_price", data?.min_price !== '' ? data?.min_price : station?.prices?.min_price);
        formData.append("on_peakPrice", data?.on_peakPrice !== '' ? data?.on_peakPrice : station?.prices?.on_peakPrice);
        formData.append("off_peakPrice", data?.off_peakPrice !== '' ? data?.off_peakPrice : station?.prices?.off_peakPrice);
        
        const token = localStorage.getItem('master_token');


        axios.post("https://api.pea-cpo.com/api/updateChargerDetail", formData, {headers: {'Authorization' : `Bearer ${token}`, 'Accept' : 'application/json'}}).then((res) => {
                console.log("user request response updated successfully : ", res);
                console.log("user request response.data : ", res.data);
                

                setLoader(false);
                setRefresh(refresh + 1);
                handleAlert2();

                
                // setUser(res?.data);
                
            }).catch((errr) =>{
                setLoader(false);
                console.log("Error get user : ", errr);
                console.log("Error get user : ", errr?.data?.message);
                // setErr2(errr?.data?.message);
                // setLoader(false);
            })


    }

    const [formData, setFormData] = useState({
        connectorName: '',
        connectorType: '',
        power: '',
        connectorStatusCode: ''
      });


      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        console.log("form data : ", formData)
      }


      const updateConnector = () => {
        setLoader(true);
        setShowAlert(false);
        const token = localStorage.getItem('master_token');

        axios.post("https://api.pea-cpo.com/api/updateConnectorDetail", {...formData, connector_id: selectedConnector?.id}, {headers: {'Authorization' : `Bearer ${token}`, 'Accept' : 'application/json'}}).then((res) => {
                console.log("user request response updated successfully : ", res);
                console.log("user request response.data : ", res.data);
                setLoader(false);
                setRefresh(refresh + 1);
                
                handleAlert2();

                // alert("connector update sccessfully");

                
                // setUser(res?.data);
                
            }).catch((errr) =>{
                setLoader(false);
                console.log("Error get user : ", errr);
                console.log("Error get user : ", errr?.data?.message);
                // setErr2(errr?.data?.message);
                // setLoader(false);
            })
      }

     

if(loader){
    return (
        <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '34vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
        </div>
    )
} else {

  return (
    <div className='station-overview'>
        {showAlert2 && (
            <SweetAlert success title="Congratulations" onConfirm={() => setShowAlert2(false)} onCancel={() => setShowAlert2(false)}>
                Charger Updated Successfully.
            </SweetAlert>
        )}

        {/* {showAlert3 && (
            <SweetAlert
            warning
            showCancel
            confirmBtnText="Update"
            confirmBtnBsStyle="danger"
            title="Are you sure you want to update?"
            onConfirm={this.deleteFile}
            onCancel={() => setShowAlert3(false)}
            focusCancelBtn
            >
            </SweetAlert>
        )} */}

        {showAlert && (
            <SweetAlert
                title={"Update Connector"}
                showCancel
                onConfirm={() => updateConnector()}
                onCancel={() => setShowAlert(false)}
                confirmBtnText="Update"
                cancelBtnText="Cancel"
                confirmBtnBsStyle="updated-btn2"
                cancelBtnBsStyle="secondary"
            >
        
                <div class="mb-3 text-start">
                    <label for="exampleFormControlInput1" className="form-label edit-connector-label text-start">Connector Name</label>
                    <input type="text" onChange={handleInputChange} name='connectorName' className="form-control" defaultValue={selectedConnector?.connectorType} id="exampleFormControlInput1" placeholder="Enter name" />
                </div>
                <div className="mb-3 text-start">
                    <label for="exampleFormControlInput12" className="form-label edit-connector-label">Connector Type</label>
                    <input type="text" onChange={handleInputChange} name='connectorType' className="form-control" defaultValue={selectedConnector?.PowerType} id="exampleFormControlInput12" placeholder="Enter type" />
                </div>
                <div className="mb-3 text-start">
                    <label for="exampleFormControlInput12" className="form-label edit-connector-label">Power Type (kWh)</label>
                    <input type="number" onChange={handleInputChange} name='power' className="form-control" defaultValue={selectedConnector?.kW} id="exampleFormControlInput12" placeholder="Enter power" />
                </div>
                <div className='mb-3 text-start'>
                    <label for="exampleFormControlInput123" className="form-label edit-connector-label">Status</label>
                    <select onChange={handleInputChange} name='connectorStatusCode' defaultValue={selectedConnector?.connectorStatusCode} class="form-select" aria-label="Default select example">
                        <option >Select Status</option>
                        <option value="Available">Available</option>
                        <option value="Offline">Offline</option>
                        <option value="Charging">Charging</option> 
                        <option value="Faulted">Faulted</option> 
                    </select>
                </div>
            </SweetAlert>
        )}
        <form onSubmit={handleSubmit(handleUpdateSubmit)}>
        <div className='overview-section-heading '>
            <div className='w-100 d-flex justify-content-between mb-4'>
                <div className='w-50'>
                    <h3>Manage Charger</h3>
                </div>
                <div >
                    <button className='back-btn' onClick={() => navigate(`/admin/viewcpo/chargers/${id}`)}>Back</button>
                </div>
            </div>
            {/* <p>Update your company details and address.</p> */}
        </div>
        <div className='overview-section'>  
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger ID</h5>
                    <p>This will be displayed to customer.</p>
                </div>

                

                <div style={{width:'35%'}}>
                    <input className='form-control' {...register("chargerID")}   defaultValue={station?.chargerID} placeholder='T54-HU1-3220-008'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger name (TH)</h5>
                    <p>This will be displayed to customer.</p>
                </div>
                <div style={{width:'35%'}}>
                    <input className='form-control' {...register("locationTH")}  defaultValue={station?.locationTH} placeholder='PEA VOLTA บางจากทับสะแก #1'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger name (EN)</h5>
                    <p>This will be displayed to customer.</p>
                </div>
                <div style={{width:'35%'}}>
                    <input className='form-control' {...register("locationEN")}  defaultValue={station?.locationEN}  placeholder='PEA VOLTA Bangchank TABSAKAE #1'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Address (TH)</h5>
                    <p>Address that available for contact</p>
                </div>
                <div style={{width:'35%'}}>
                    <input className='form-control' {...register("descriptionTH")}  defaultValue={station?.descriptionTH} placeholder='888 ซอยรังสิต - นครนายก 49, ประชาธิปัต '/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Address (EN)</h5>
                    <p>Address that available for contact</p>
                </div>
                <div style={{width:'35%'}}>
                    <input className='form-control' {...register("descriptionEN")}  defaultValue={station?.descriptionEN} placeholder='888 Soi Rangsit - Nakornnayok 49, Prachathipat'/>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Location</h5>
                    <p>Location of this charger</p>
                </div>
                <div>
                    <div className='station-field-location'>
                        <div>
                            <h6>Latitude</h6>
                            <input className='form-control' defaultValue={location?.lat}  placeholder='14.044750' name='lat' onChange={handleLocation}/>
                        </div>
                        <div>
                            <h6>Longitude</h6>
                            <input className='form-control' defaultValue={location?.lng}  placeholder='100.591728' name='lng' onChange={handleLocation}/>
                        </div>
                    </div>
                    <div className='station-map'>
                        {/* <GoogleMap lat={location.lat} lng={location.lng} width={'40%'} height={'40%'}/> */}
                        {station ? (
                            <GoogleMap lat={station?.lat} lng={station?.lng} width={'40%'} height={'40%'}/>
                        ) : (
                            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '3vh'}}>
                                <BallTriangle
                                    height={70}
                                    width={70}
                                    radius={5}
                                    color="#814097"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperClass={{}}
                                    wrapperStyle=""
                                    visible={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='station-field location' style={{height: '10vh', marginTop: '13vh'}}>
                
                <div className='station-field-div1'>
                    <h5>Charger Picture</h5>
                    <p>This will be displayed on your profile.</p>
                </div>
                <div className='img-preview' style={{zIndex:'10'}} >
                    <div>
                    {imgPreview.length > 0 && imgPreview.map((url, index)=>
                        <div key={index}>
                            <span style={{cursor:'pointer'}} onClick={()=>removeImg(index)}>&times;</span>
                            <img src={url} alt={url}/>
                        </div>
                    )}
                    </div>
                    <input type='file' className='form-control' accept="image/*"  multiple onChange={handleFile}/>
                </div>
            </div>
        </div>
        <div className='overview-section-heading' style={{marginTop: '160px'}}>
            <h4>Charger Information</h4>
            <p>The information of the charger and connector types.</p>
        </div>
        <div className='overview-section'>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger Detail</h5>
                </div>
                <div>
                    <p>Charger name</p>
                    <input className='form-control' defaultValue={station?.locationTH} placeholder='Charger 1'/>
                    <div className='station-field-location'>
                        <div>
                            <p>Charger ID</p>
                            <input className='form-control' defaultValue={station?.chargerID}  placeholder='T54-HU1-3220-008'/>
                        </div>
                        <div>
                            <p>Charger Number</p>
                            <input className='form-control'  {...register("number")} defaultValue={station?.number}  placeholder='P101'/>
                        </div>
                    </div>
                    <div className='station-field-location'>
                        <div>
                            <p>Brand</p>
                            <input className='form-control'  placeholder='ABB'/>
                        </div>
                        <div>
                            <p>Model</p>
                            <input className='form-control'  placeholder='TERRA CJG-54'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Connector Type</h5>
                </div>
                <div className='station-connector'>
                    {station?.connectors && station?.connectors.map((item, index) => (
                        <div className='station-connector-section my-3' key={index}>
                            <div>
                                <h6>{item?.connectorType}</h6>
                                <p>Type: {item?.PowerType} <br/> Rated Power: {item?.kW} kW</p>
                            </div>
                            <div className='d-flex'>

                                {item?.connectorStatusCode === "Available" ? (
                                    <div className='status'>
                                        Available
                                    </div>
                                ) : item?.connectorStatusCode === "Offline" ? (
                                    <div className='status offline'>
                                        {item?.connectorStatusCode}
                                    </div>
                                ) : item?.connectorStatusCode === "Maintenance" ? (
                                    <div className='status maintenance'>
                                        {item?.connectorStatusCode}
                                    </div>
                                ) : item?.connectorStatusCode === "Faulted" ? (
                                    <div className='status faulted'>
                                        {item?.connectorStatusCode}
                                    </div>
                                ) : (
                                    <div className='status charging'>
                                        {item?.connectorStatusCode}
                                    </div>

                                )}
                                <div >
                                    <button className='status2' type='button' onClick={() =>{ setFormData({
                                                                                    connectorName: item?.connectorType,
                                                                                    connectorType: item?.PowerType,
                                                                                    power: item?.kW,
                                                                                    connectorStatusCode: item?.connectorStatusCode
                                                                                }); 
                                        setSelectedConnector(item); setShowAlert(true); }}>Edit</button>
                                </div>
                            </div>
                        </div>                        
                    ))}


                    {/* <div className='station-connector-section'>
                        <div>
                            <h6>CCS 2</h6>
                            <p>Type: DC <br/> Rated Power: 50 kW</p>
                        </div>
                        <div className='status'>
                            Available
                        </div>
                    </div> */}
                </div>
            </div>
            
        </div>
        
        <div className='overview-section mt-4'>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Connector Price</h5>
                </div>
{/*                 
                chargerID,
                number,
                location_textEN,
                location_textTH,
                description_textEN,
                description_textTH,
                description_textProvinceNameEN,
                description_textProvinceNameTH,
                lat,
                lng,
                chargerPhoto        
                max_price,
                min_price,
                on_peakPrice,
                off_peakPrice, */}
                <div style={{width:'35%'}}>
                    <p className='mb-2'>Minimum Price</p>
                    <input className='form-control' {...register("min_price", { pattern: /^-?\d+(\.\d{1,2})?$/ })}  defaultValue={station?.prices?.min_price} placeholder='Enter Minimum Price'/>
                    {errors?.min_price && <p className='text-danger mb-0 pb-0'>This field must have maximum of 2 decimal places.</p>}
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    {/* <h5>Connector Price</h5> */}
                </div>
                <div style={{width:'35%'}}>
                    <p className='mb-2'>Maximum Price</p>
                    <input className='form-control'  {...register("max_price", { pattern: /^-?\d+(\.\d{1,2})?$/ })} defaultValue={station?.prices?.max_price} placeholder='Enter Maximum Price'/>
                    {errors?.max_price && <p className='text-danger mb-0 pb-0'>This field must have maximum of 2 decimal places.</p>}
                </div>
            </div>

            <div className='station-field'>
                <div className='station-field-div1'>
                    {/* <h5>Connector Price</h5> */}
                </div>
                <div style={{width:'35%'}}>
                    <p className='mb-2'>On Peak Price</p>
                    <input className='form-control'   {...register("on_peakPrice", { pattern: /^-?\d+(\.\d{1,2})?$/ })} defaultValue={station?.prices?.on_peakPrice} placeholder='Enter On Peak Price'/>
                    {errors?.on_peakPrice && <p className='text-danger mb-0 pb-0'>This field must have maximum of 2 decimal places.</p>}
                </div>
            </div>
            
            <div className='station-field'>
                <div className='station-field-div1'>
                    {/* <h5>Connector Price</h5> */}
                </div>
                <div style={{width:'35%'}}>
                    <p className='mb-2'>Off Peak Price</p>
                    <input className='form-control'   {...register("off_peakPrice", { pattern: /^-?\d+(\.\d{1,2})?$/ })} defaultValue={station?.prices?.off_peakPrice} placeholder='Enter Off Peak Price'/>
                    {errors?.off_peakPrice && <p className='text-danger mb-0 pb-0'>This field must have maximum of 2 decimal places.</p>}
                </div>
            </div>



        </div>

        <div className='w-100 pb-5 pt-4'>
                <button className='btn-style1 ms-3 mb-5' style={{marginRight: '28%'}}>Update</button>
                <button className='btn-style2can'>Cancel</button>
        </div>

        </form>


        {/* <div className='overview-section' style={{borderTop: '2px solid #E4E7EC', paddingTop: '2rem'}}>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger Detail</h5>
                </div>
                <div>
                    <p>Charger name</p>
                    <input className='form-control' placeholder='Charger 1'/>
                    <div className='station-field-location'>
                        <div>
                            <p>Charger ID</p>
                            <input className='form-control' placeholder='T54-HU1-3220-008'/>
                        </div>
                        <div>
                            <p>Charger Number</p>
                            <input className='form-control' placeholder='P101'/>
                        </div>
                    </div>
                    <div className='station-field-location'>
                        <div>
                            <p>Brand</p>
                            <input className='form-control' placeholder='ABB'/>
                        </div>
                        <div>
                            <p>Model</p>
                            <input className='form-control' placeholder='TERRA CJG-54'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Connector Type</h5>
                </div>
                <div className='station-connector'>
                    <div className='station-connector-section'>
                        <div>
                            <h6>CHAdeMO</h6>
                            <p>Type: DC <br/> Rated Power: 50 kW</p>
                        </div>
                        <div className='status'>
                            Available
                        </div>
                    </div>
                    <div className='station-connector-section'>
                        <div>
                            <h6>CCS 2</h6>
                            <p>Type: DC <br/> Rated Power: 50 kW</p>
                        </div>
                        <div className='status'>
                            Available
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </div>
  ) }
}
