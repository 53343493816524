import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from 'react-select';
import DataTableBase from "./reuseable/DataTableBase";
import { BallTriangle } from "react-loader-spinner";
import XLSX from 'xlsx';


const months = [
    {
        value: 0,
        label: 'January'
    },
    {
        value: 1,
        label: 'February',
    },
    {
        value: 2,
        label: 'March',
    },
    {
        value: 3,
        label: 'April',
    },
    {
        value: 4,
        label: 'May',
    },
    {
        value: 5,
        label: 'June',
    },
    {
        value: 6,
        label: 'July',
    },
    {
        value: 7,
        label: 'August',
    },
    {
        value: 8,
        label: 'September',
    },
    {
        value: 9,
        label: 'October',
    },
    {
        value: 10,
        label: 'November',
    },
    {
        value: 11,
        label: 'December',
    },
];

const years = [
    {
        value: '2023',
        label: '2023'
    },
    {
        value: '2024',
        label: '2024'
    },
]



const ReportBilling = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    // console.log("current month : ", currentMonth);
    
    const [stations, setStations] = useState([]);
    const [selectedStation, setSelectedStation] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(months[currentMonth]);
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [data3, setData3] = useState(null);
    const [filteredData, setFilteredData] = useState(null);

    const [loader, setLoader] = useState(true);

    const [flag, setFlag] = useState(2);


    function filterDataByMonth(data, month) {
        // console.log("month : ", month)
        if (month === null || month === '' || month === undefined) return data;
      
        return data?.filter(item => {
          const startDate = new Date(item?.startDate);
          return startDate.getMonth() === month;
        });
    }
    
    
    function filterDataByYear(data, year) {
        // console.log("Year : ", year)
        if (!year) return data;
        
        // console.log("Year number : ", Number(year))
        // // const numericYear = parseInt(year, 10);
        // const numericYear = +year;
        // console.log("Year numberic : ", numericYear)
      
        return data?.filter(item => {
          const startDate = new Date(item?.startDate);
        //   console.log("Get full year : - ", startDate.getFullYear())
          return startDate.getFullYear() === Number(year);
        });
    }


    function filterDataByStation(data, stationName) {
        if (!stationName || stationName === 'All') return data;
      
        return data?.filter(item => item?.locationTH === stationName);
    }


    function calculateDataForEachStation(filteredData) {
        const stationDataMap = new Map();
      
        // Calculate totalKwh and revenue for each station
        filteredData?.forEach(item => {
          const { locationTH, totalKwh, revenue } = item;
      
          if (!stationDataMap.has(locationTH)) {
            stationDataMap.set(locationTH, {
              totalKwh: parseFloat(totalKwh),
              revenue: parseFloat(revenue),
            });
          } else {
            const currentData = stationDataMap.get(locationTH);
            stationDataMap.set(locationTH, {
              totalKwh: currentData?.totalKwh + parseFloat(totalKwh),
              revenue: currentData?.revenue + parseFloat(revenue),
            });
          }
        });
      
        // Convert map to an array of station objects with totalKwh and revenue
        const stationDataArray = Array.from(stationDataMap, ([locationTH, data]) => ({
          locationTH,
          totalKwh: data?.totalKwh,
          revenue: data?.revenue,
        }));
      
        return stationDataArray;
      }
      


    function filterData(data, filters) {
        const { year, month, stationName } = filters;
      
        let filteredData = filterDataByYear(data, year);
        // console.log("fillter array after year filter : ", filteredData)
        filteredData = filterDataByMonth(filteredData, month);
        const stationDataArray = calculateDataForEachStation(filteredData);
        if(stationDataArray){
            setData2(stationDataArray);
        }

        const dataTem3= [
            {
                title: 'Monthly service fee',
                value:  7392.26 * stationDataArray?.length,
            },
            {
                title: 'Discount',
                value: (7392.26 * stationDataArray?.length) * 0.05,
                red: true,
            },
            {
                title: 'Sub Total',
                value:  7392.26 * stationDataArray?.length - ((7392.26 * stationDataArray?.length) * 0.05)
            },
            {
                title: 'Vat',
                value: 517.46 * stationDataArray?.length,
            },
            {
                title: 'Total',
                bold: true,
                value: (517.46 * stationDataArray?.length) + (7392.26 * stationDataArray?.length - ((7392.26 * stationDataArray?.length) * 0.05))
            }
        ]

        setData3(dataTem3);

        // console.log("Station data : ", stationDataArray);
        // console.log("fillter array after year month : ", filteredData)
        filteredData = filterDataByStation(filteredData, stationName);
      
        return filteredData;
    }
      
      
      


    




    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get("https://api.pea-cpo.com/api/getChargerListCpo", {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            // console.log("Get Charger list : ", res);
            const opArr = [];
            res?.data?.data && res?.data?.data?.map((item) => {
                opArr.push({value: item?.locationTH, label: item?.locationTH});
            })
            // console.log("Options options : ", opArr)
            setStations([{value: 'All', label: 'All'}, ...opArr]);            
        }).catch((err) =>{
            console.log("Error get user : ", err);
        })
    }, [flag]) 


    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://api.pea-cpo.com/api/overviewPageChargingList', {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("Table one data : ", res);            
            setData(res?.data?.data);
            setFilteredData(res?.data?.data);
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            // console.log("current month : ", currentMonth);
            setSelectedMonth(months[currentMonth])
            setLoader(false);        
        }).catch((err) =>{
            setLoader(false);
            console.log("Error get user : ", err);
        })
    }, [flag])


    const caseInsensitiveSort = (rowA, rowB) => {
        const a = Math.ceil(rowA?.duration * 100) / 100;
        const b = Math.ceil(rowB?.duration * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };

    const caseInsensitiveSort2 = (rowA, rowB) => {
        const a = Math.ceil(rowA?.totalKwh * 100) / 100;
        const b = Math.ceil(rowB?.totalKwh * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };

    const caseInsensitiveSort3 = (rowA, rowB) => {
        const a = Math.ceil(rowA?.revenue * 100) / 100;
        const b = Math.ceil(rowB?.revenue * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };


    const caseInsensitiveSort4 = (rowA, rowB) => {
        const a = Math.ceil(rowA?.totalKwh * 100) / 100;
        const b = Math.ceil(rowB?.totalKwh * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };



    const columns = [
        {
            name: "Date",
            selector: row => row?.startDate,
            width: '100px',
            sortable: true,
        },
        {
            name: "Station",
            selector: row => row?.locationTH,
            sortable: true,
        },
        {
            name: "Gun Type",
            // selector: row => (Math.ceil(row?.offPeak * 100) / 100).toLocaleString(),
            selector: row => row?.connector,
            width: '100px',
            sortable: true,
        },
        {
            name: "V Card",
            selector: row => row?.vcard,
        },
        {
            name: "Start Time",
            selector: row => `${row?.startDate} ${row?.startTime}`,
            sortable: true,
        },        
        {
            name: "End Time",
            selector: row => `${row?.endDate} ${row?.endTime}`,
            sortable: true,
        },        
        {
            name: "Duration",
            selector: row => (Math.ceil((row?.duration) * 100) / 100).toLocaleString(),
            sortable: true,
            width: '100px',
            sortFunction: caseInsensitiveSort
        },        
        {
            name: "Energy (Kwh)",
            selector: row => (Math.ceil((row?.totalKwh) * 100) / 100).toLocaleString(),
            sortable: true,
            width: '100px',
            sortFunction: caseInsensitiveSort2
        },        
        {
            name: "Revenue",
            selector: row => (Math.ceil((row?.revenue) * 100) / 100).toLocaleString(),
            sortable: true,
            width: '100px',
            sortFunction: caseInsensitiveSort3
        },        
    ];


    const columns2 = [
        {
            name: "Station Name",
            selector: row => row?.locationTH,
            sortable: true,
        },
        {
            name: "Power Sold",
            // selector: row => row?.totalKwh,
            selector: row => (Math.ceil((row?.totalKwh) * 100) / 100).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort4
        },
        {
            name: "Service Fee (excl vat)",
            selector: row => "7,392.26",
            sortable: true,
        },
        {
            name: "vat",
            selector: row =>  "517.46",
            sortable: true,
        },
        {
            name: "Service fee (incl vat)",
            selector:  row => "7,909.72",
            sortable: true,
        },
    ]

    const columns3 = [
        {
            name: "Title",
            selector: row => row?.bold === true ? <b>{row?.title}</b> : row?.title,
        },
        {
            name: "Value",
            selector: row => row?.bold === true? <b>{(Math.ceil((row?.value) * 100) / 100).toLocaleString()}</b> : row?.red === true ? <span className="text-danger">{(Math.ceil((row?.value) * 100) / 100).toLocaleString()}</span>  :  (Math.ceil((row?.value) * 100) / 100).toLocaleString(),
            // selector: row => row?.value,
        }
    ]

    
   
    useEffect(() => {
        // console.log("Selected Month: ", selectedMonth);
        // console.log("Selected : Year", selectedYear);
        // console.log("Selected Station: ", selectedStation);

        const filters = {
            year: selectedYear?.value,
            month: selectedMonth?.value, // January (0-based index, so 0 represents January)
            stationName: selectedStation?.value,
        };
          
        const filteredDataRes = filterData(data, filters);

        // console.log("Filtered data : ", filteredDataRes)
        
        if(filteredDataRes){
            setFilteredData(filteredDataRes);
        }

    }, [selectedMonth, selectedYear, selectedStation, data])




    // console.log("DATA Two : : : ", data2)



    function convertToWorksheet(tableData) {
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        return worksheet;
    }

    function downloadExcelFile(tableData, fileName) {
        const worksheet = convertToWorksheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }

    function handleDownloadExcel(fileData) {
        const now = new Date();
        const isoDateTime = now.toISOString();    
        const structuredData2 = [];
        const StructureData = fileData?.filter(item => {
            const obj = {"Date": item?.startDate, "Station Name": item?.locationTH, "Gun Type": item?.connector, "Customer": item?.customerName, "Start Time": `${item?.startDate} ${item?.startTime}`, "End Time": `${item?.endDate} ${item?.endTime}`, "Energy Used": item?.totalKwh, "Revenue": item?.revenue};
            structuredData2?.push(obj);
            return obj;
            }
        )

        // console.log("Structured data : ", StructureData)

        // console.log("Structured data 2 : ", structuredData2)
        
        downloadExcelFile(structuredData2, `Billing Report ${isoDateTime}`);
    }
   


    // const stationDataArray = calculateDataForEachStation(filteredData);
    // console.log(stationDataArray);



    if(loader){
        return (
            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '17vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
            </div>
        )
    } else {
        return (
            <div className="report-billing-page">
                
                <div className='w-100 d-flex mt-4 pt-2'>
                    <div className='w-25'>
                        <h3 className='font-style27 mb-0'>Report Billing</h3>
                        {/* <h6 className='font-style28'>ภาพรวมข้อมูลการใช้พลังงานไฟฟ้า</h6> */}
                    </div>
                    <div className='w-75 d-flex justify-content-end ps-5'>
                        <Select
                            className="d-inline-block w-50 mt-1 me-2" style={{width: '250px', height: '46px'}}
                            defaultValue={selectedYear}
                            onChange={setSelectedYear}
                            options={years}
                            placeholder="Select Year"
                            
                        />
                        <Select
                            className="d-inline-block w-50 mt-1 me-2" style={{width: '250px', height: '46px'}}
                            defaultValue={selectedMonth}
                            onChange={setSelectedMonth}
                            options={months}
                            placeholder="Select Month"
                        />
                        <Select
                            className="d-inline-block w-50 mt-1" style={{width: '250px', height: '46px'}}
                            defaultValue={selectedStation}
                            onChange={setSelectedStation}
                            options={stations}
                            placeholder="Select Station"
                        />
                        <button className='btn-style1 ms-2' onClick={() => handleDownloadExcel(filteredData)}>Download</button>
                    </div>
                </div>
                <div className="mt-4 mb-4">
                {filteredData && (
                        <DataTableBase 
                            columns={columns}
                            data={filteredData}
                            // title="Summary"
                            pagination
                        />
                    )}
                </div>

                <div className="mt-4 mb-4">
                {data2 && (
                        <DataTableBase 
                            columns={columns2}
                            data={data2}
                            // title="Summary"
                            // pagination
                        />
                    )}
                </div>

                <div className="d-flex">
                    <div className="w-50">

                    </div>
                    <div className="mt-4 mb-4 w-50 third-table-report">
                        {data3 && (
                            <DataTableBase 
                                columns={columns3}
                                data={data3}
                                // title="Summary"
                                // pagination
                            />
                        )}
                        <div className="text-end pt-3 pe-5">
                            <button className="btn btn-success">Approve</button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default ReportBilling;