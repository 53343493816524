import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ApexChart from './components/ColumnGraph';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from './pages/Login';
import Forget from './pages/Forget';
import Registration from './pages/Registration';
import MasterLogin from './pages/MasterLogin';
import MasterDashboard from './pages/MasterDashboard';
import AddCharger from './pages/AddCharger';
import Chargers from './pages/Chargers';
import UpdateCPOPage from './pages/UpdateCPOPage';
import CPOList from './components/CPOList';
import ViewCPO from './pages/ViewCPO';
import ViewCPOChargers from './pages/ViewCPOChargers';
import CPOUpdatePage from './pages/CPOUpdatePage';
import AdminDashboardPage from './pages/AdminDashboardPage';
import VerifyToken from './pages/VerifyToken';
import VerifyAdminToken from './pages/VerifyAdminToken';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />} />
        <Route path="/verify/:t" element={<VerifyToken />} />
        <Route path="/verify-token/:token/:encryptedString/:encryptedCode" element={<VerifyAdminToken />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registeration" element={<Registration />} />
        <Route path="/forget-password" element={<Forget />} />
        <Route path="/admin/login" element={<MasterLogin />} />
        <Route path="/admin/cpolist" element={<MasterDashboard />} />
        <Route path="/admin/dashboard" element={<AdminDashboardPage />} />
        <Route path="/admin/addcpo" element={<AddCharger/>} />
        <Route path="/admin/updatecpo/:id" element={<UpdateCPOPage />} />
        <Route path="/admin/chargers/:id" element={<Chargers />} />
        <Route path="/admin/viewcpo/profile/:id" element={<ViewCPO />} />
        <Route path="/admin/viewcpo/chargers/:id" element={<ViewCPOChargers />} />
        <Route path="/admin/viewcpo/chargers/:id/update/:cid" element={<CPOUpdatePage />} />
      </Routes>
      {/* <App /> */}
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
