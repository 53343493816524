import React, { useState } from 'react';
import ColumnGraph from '../ColumnGraph';
import calenderIcon from '../../assets/calendar.svg';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; 
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import DatePicker from './DatePicker';
import ColumnGraph2 from '../ColumnGraph2';

const StationGraphCard = ({l1, val1, l2, val2, l3, val3, l4, val4, cols, insight, statRev, data2, label, setFilter, filter, stacked, checkPoint, symbol}) => {
    const [tog, setTog] = useState(3);
    const [display, setDisplay] = useState({
      display:'none'
    })

    const [dateRange, setRange] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    })
  
    const yearLabels =  ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const oneDayLabels =  ["0-1", "1-2", "2-3", "3-4", "4-5", "5-6", "6-7", "7-8", "8-9", "9-10", "10-11", "11-12", "12-13", "13-14" , "14-15", "15-16", "16-17", "17-18", "18-19", "19-20", "20-21", "21-22", "22-23", "23-24"];
    

  function toggleDate(){
    if(display.display === 'none')
    setDisplay({display: 'block'})
    else
    setDisplay({display: 'none'})
  }

  function handleSelect(date){
    setRange({ 
        startDate: date.selection.startDate,
        endDate: date.selection.endDate,
        key: date.selection.key
    })
  }

  

  return (
    <div className=''>
        <div className='graph-div2'>
            {/* <h3 className='graph-sec-title'>Customers</h3>
            <h6 className='graph-sec-subtitle'>This Year: Jan 1, 2022 - Dec 31, 2022</h6> */}
            {/* <div className='w-100'> */}
              {/* <div className='pt-20 w-25'>
                <h4 className='font-style-7'>Statistics</h4>
              </div> */}
              
              {insight && insight}
              <div className='w-100 d-flex justify-content-end'>
                <div className='toggles-graph'>
                  <ul className='graphs-ul'>
                    <li className={tog === 1 ? 'graph-active-li' : 'unactive'}
                    onClick={() => {setTog(1); setFilter('Today');}}
                    >Today</li>
                    {statRev !== true && (
                      <li className={tog === 2 ? 'graph-active-li' : 'unactive'}
                      onClick={() => setTog(2)}
                      >This Month</li>
                    )}
                    <li className={tog === 3 ? 'graph-active-li' : 'unactive'}
                    onClick={() => {setTog(3); setFilter('Year');}}
                    >This Year</li>
                  </ul>
                </div>
                {statRev !== true && (
                  <DatePicker/> 
                )}
                {statRev === "12" && (
                  <p>adfadfadf ad ad adfa  </p>
                )}
              </div>
            <div className='w-100 d-flex graph-card-wrapper'>
                <div className={cols === 4? "graph-card border-right" : "graph-card2 border-right"}>
                    <h6 className='mb-0 pb-20 graph-card-title'>{l1}</h6>
                    {/* <h3 className='graph-card-number'>{val1} </h3> */}
                    {val1}
                </div>
                <div className={cols === 4? "graph-card border-right pl-24" : "graph-card2 border-right pl-24"}>
                    <h6 className='mb-0 pb-20 graph-card-title'>{l2}</h6>
                    {/* <h3 className='graph-card-number'>{val2}</h3> */}
                    {val2}
                </div>
                <div className={cols === 4? "graph-card border-right pl-24" : "graph-card2  pl-24"}>
                    <h6 className='mb-0 pb-20 graph-card-title'>{l3}</h6>
                    {/* <h3 className='graph-card-number'>{val3}</h3> */}
                    {val3}
                </div>
                <div className={cols === 4? "graph-card pl-24" : "d-none"}>
                    <h6 className='mb-0 pb-20 graph-card-title'>{l4}</h6>
                    {/* <h3 className='graph-card-number'>{val4}</h3> */}
                    {val4}
                </div>
            </div>
            <div className='w-100'>
              
                {/* data2 */}
              {stacked === true ? (
                  data2 && (
                    <>

                      {/* <p>stacked chart</p> */}
                      <ColumnGraph2 labs={filter === 'Today' ? oneDayLabels : yearLabels} unique={data2?.unique} repeated={data2?.repeated}  />
                    </>
                  )
                 
              ) : (
                <>
                {/* <p>column chart</p> */}
                <p className='mb-0 pb-0 ms-3'>{symbol}</p>
                <ColumnGraph labs={filter === 'Today' ? oneDayLabels : yearLabels} tog={tog} data2={data2} label={label} />
                </>
              )}
            </div>
        </div>
    </div>
  )
}

export default StationGraphCard;