import React from "react";
import MASidebar from "../components/MASidebar";
import MANavbar from "../components/MANavbar";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AdminDashboard from "../components/AdminDashboard";


const AdminDashboardPage = () => {
    const [user, setUser] = useState()
    
    const navigate = useNavigate();


    useEffect(() => {
        const access_token = localStorage.getItem('master_token');
        if(!access_token){
            navigate('/admin/login')
        }
    }, [])

    useEffect(() => {
        const access_token = localStorage.getItem('master_token');
        
        // console.log("token found value is : ", token);
        axios.get("https://api.pea-cpo.com/api/getAdminProfile", {headers: {'Authorization' : `Bearer ${access_token}`}}).then((res) => {
          console.log("user request response : ", res);
          setUser(res?.data?.username);
        }).catch((err) =>{
          console.log("Error get user : ", err);
        })
    }, [])



    return (
        <>
            <div className='d-flex'>
                <MASidebar />
                <div className='content-div'>
                    <MANavbar user={user}  /> 
                    <AdminDashboard />
                </div>
            </div>
        </>
    )
}


export default AdminDashboardPage;