import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Overview from './components/Overview';
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Stations from './pages/Stations';
import StationDetail from './pages/StationDetail';
import StationLog from './components/StationLog';
import StationOverview from './components/StationOverview';
import StationRevenue from './components/StationRevenue';
import StationTransaction from './components/StationTransaction';
import StationEnergyUse from './components/StationEnergyUse';
import StationCustomer from './components/StationCustomer';
import StationSetting from './components/StationSetting';
import Report from './pages/Report';
import StationInsight from './components/StationInsight';
import Map from './pages/Map';
import StationControl from './components/StationControl';
import AccountSetting from './pages/AccountSetting';
import MyProfile from './components/MyProfile';
import Subscription from './components/Subscription';
import Notification from './components/Notification';
import ChangePassword from './components/ChangePassword';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import TestingMap from './pages/TestingMap';
import Notify from './components/Notify';

function App() {
  const navigate = useNavigate();
  const [access_token, setAccessToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token === null) {
      navigate('/login');
    }else{     
      setLoading(true)
    }
  }, [])

  const token = localStorage.getItem('token');
  useEffect(() => {
    if(!access_token){
      setAccessToken(localStorage.getItem('token'));
    }
    // console.log("token found value is : ", token);
    axios.get("https://api.pea-cpo.com/api/getUser", {headers: {'Authorization' : `Bearer ${access_token}`}}).then((res) => {
      console.log("user profile. request response : ", res);
      setUser(res?.data);
    }).catch((err) =>{
      console.log("Error get user : ", err);
      if(err?.response?.status === 401){
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('pea_token');
        localStorage.removeItem('token');
        // localStorage.clear();
        // navigate('/login')
      }
    })
  }, [access_token])

  

  if(loading){
  return (
    <>
    {loader? (
      <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '37vh'}}>
        <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#814097"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
        />
      </div>
    ) : (
    <div className='d-flex'>
      <Sidebar />
      <div className='content-div'>
        <Navbar user={user} setLoader={setLoader} />
        <div className='main-div'>
          <Routes>
            <Route path="/" element={<Overview user={user} />} />
            <Route path="/stations" element={<Stations />} />
            <Route path="/stations/detail/:id" element={<StationDetail />}>
              <Route index element={<StationOverview/>} />
              <Route path="overview" element={<StationOverview/>} />
              <Route path="station-log" element={<StationLog/>} />
              <Route path="revenue" element={<StationRevenue />} />
              <Route path="transaction" element={<StationTransaction />} />
              <Route path="energy-use" element={<StationEnergyUse />} />
              <Route path="customer" element={<StationCustomer />} />
              <Route path="station-setting" element={<StationSetting/>} />
              <Route path="station-insight" element={<StationInsight />} />
              <Route path="station-control" element={<StationControl />} />
            </Route>
            <Route path="notifications" element={<Notify />} />
            <Route path="/account-settings" element={<AccountSetting />}>
              <Route path="profile" element={<MyProfile user={user} />} />
              <Route path="subscription" element={<Subscription />} />
              <Route path="notification" element={<Notification />} />
              <Route path="change-password" element={<ChangePassword />} />
            </Route>
            {/* <Route path="/map" element={<Map/>} /> */}
            <Route path="/map" element={<TestingMap />} />
            <Route path="/report" element={<Report />} />
          </Routes> 
        </div>
      </div>
    </div>)}
    </>
  );
  }else{
    return(<div></div>);
  }
}

export default App;
