
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Overview from '../components/Overview';
import { Route, useNavigate,
  useParams,
} from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import MASidebar from '../components/MASidebar';
import MANavbar from '../components/MANavbar';
import Chargers from './Chargers';
import { BallTriangle } from 'react-loader-spinner';
// import CPOList from '../components/CPOList';
// import { Children } from 'react';

const dataa = [
  {
      chargerID: "T53-IT1-4816-013",
      number: "PEAC0200011201",
      location: "PEA VOLTA Pattaya",
      description: "321\/1 Village No.10 Nong Prue Subdistrict, Bang Lamung Chonburi 20150 Tel: - Fax: -",
      chargerPhoto: "https:\/\/volta-webapp.pea.co.th\/images\/sideBarStation\/T53-IT1-4816-013.jpg",
  },
  {
      chargerID: "T53-IT1-4816-012",
      number: "PEAS0100021201",
      location: "PEA VOLTA  Hua Hin ",
      description: "2\/7 Phet Kasem Rd, Nong Kae, Hua Hin, Prachuap Khiri Khan 77110 Tel: 0-3251-2215  Fax ",
      chargerPhoto: "https:\/\/volta-webapp.pea.co.th\/images\/sideBarStation\/T53-IT1-4816-012.jpg",
  },
  {
      chargerID: "T53-IT1-0717-012",
      number: "PEAS0100011201",
      location: "PEA VOLTA Khao Yoi",
      description: "135 Village No.4 Phet Kasem Road, Sa Phang, Khao Yoi, Phetchaburi 76140 Tel: 0-3256-2455 Fax:",
      chargerPhoto: "https:\/\/volta-webapp.pea.co.th\/images\/sideBarStation\/T53-IT1-0717-012.jpg",
  },
  {
      chargerID: "WAJU20030001",
      number: "PEAC0100021201",
      location: "PEA VOLTA Rangsit ",
      description: "60 Village No.6 Phahonyothin Road, Khlong Nueng Subdistrict Klong Luang Distinct Pathum Thani Province 12120 Tel: 02-516-8659   Fax ",
      chargerPhoto: "https:\/\/volta-webapp.pea.co.th\/images\/sideBarStation\/WAJU20030001.jpg",
  },
  {
      chargerID: "T53-IT1-0817-002",
      number: "PEAH0100011201",
      location: "PEA VOLTA HEAD OFFICE #1",
      description: "200 Ngamwongwan Road, Ladyao, Chatuchak, Bangkok 10900 Tel: 0-2589-0100 Fax: -  (Temporary close  on 16\/12\/2022 01:00 - 02:30 PM )",
      chargerPhoto: "https:\/\/volta-webapp.pea.co.th\/images\/sideBarStation\/T53-IT1-0817-002.jpg",
  },
  {
      chargerID: "T53-IT1-0717-006",
      number: "PEAC0300021201",
      location: "PEA VOLTA Nakhon Chai Si",
      description: "9 Village No.1 Thai Yawat Subdistrict, Nakhon Chai Si, Nakhon Pathom 73121 Tel: 034-228-716 Fax",
      chargerPhoto: "https:\/\/volta-webapp.pea.co.th\/images\/sideBarStation\/T53-IT1-0717-006.jpg",
  },
];

function ViewCPO() {
  const navigate = useNavigate();
  const [access_token, setAccessToken] = useState(localStorage.getItem('master_token'));
  const [user, setUser] = useState(null);
  const [tog, setTog] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [data1, setData1] = useState(false);
  const [number, setNumber] = useState(null);
  const [location, setLocation] = useState(null);
  // const [numArr, setNumArr] = useState([]);

  const [data, setData] = useState(dataa);
  const [filterData, setFilterData] = useState(dataa);

  const {id} = useParams();

useEffect(() => {
  const access_token = localStorage.getItem('master_token');
  axios.get(`https://api.pea-cpo.com/api/getCpoDetail/${id}`, {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
      console.log("Cpo request res : ", res);
      console.log("Cpo Response data : ", res.data);
      setData1(res?.data);
      setLoader(false);
      if(res?.data?.status === 200){
      }
  }).catch((err) => {
      console.log("Error : ", err);
  })
}, [])
  
  useEffect(() => {
    const token = localStorage.getItem('master_token');
    if (token === null) {
      navigate('/admin/login');
    }
    // else{     
    //   setLoading(true)
    // }
  }, [])

  const token = localStorage.getItem('master_token');
  useEffect(() => {
    if(!access_token){
      setAccessToken(localStorage.getItem('master_token'));
    }
    console.log("token found value is : ", token);
    axios.get("https://api.pea-cpo.com/api/getAdminProfile", {headers: {'Authorization' : `Bearer ${access_token}`}}).then((res) => {
      console.log("Admin user request response : ", res);
      setUser(res?.data?.username);
    }).catch((err) =>{
      console.log("Error get user : ", err);
    })
  }, [access_token])



  // function matcharrays(a, arr){
  //   let flaggg = 0;
  //   arr && arr.map((i, index) => {
  //     if(i === a){
  //       flaggg =1;
  //       console.log("matcheeeeeeeeeeeeeeeeeeeed")
  //     }
  //     console.log("runnnnnnnnnnnnn ", i, index)
  //   })
  //   console.log("nottttttttttttttt matcheeeeeeeeeeeeeeeeeeeed")
  //   return flaggg;
  // }


  // useEffect(() => {
  //   console.log("location work work ", location)

  //   let result = [];
  //   let result2 = [];

  //   if(location){
  //     console.log("location conditionnnnnnnnnn")
  //     result = data.filter((item) => {
        
  //       console.log("Name : ", item.location.toLocaleLowerCase().match(location.toLocaleLowerCase()));
        
  //       return item.location.toLocaleLowerCase().match(location.toLocaleLowerCase());
  //     });
  //     console.log("Result : ", result);
      
  //     setFilterData(result);
  //   }

  //   if(number){
  //     console.log("address conditionnnnnnnnnn")
  //     result2 = data.filter((item) => {
  //       console.log("Address : ", item.description.toLocaleLowerCase().match(number.toLocaleLowerCase()));
  //       return item.description.toLocaleLowerCase().match(number.toLocaleLowerCase());
  //     });
  //     console.log("Result : ", result2);

  //     console.log("result one ", result)
  //     console.log("result two ", result2)

  //     const result3 = [...result, ...result2];

  //     console.log("result 3: ", result3);


  //     let arrr = [];

  //     let finalResult = [];
      
  //     const result4 = result3 && result3.map((item) => {
  //       console.log("check : ", item.chargerID)
  //       console.log("check arr: ", arrr)
  //       if(matcharrays(item.chargerID, arrr) === 0){
  //         arrr.push(item.chargerID);
  //         finalResult.push(item);
  //         return item;
  //       } else {

  //         arrr.push(item.chargerID);
  //       }
  //       // if(numArr){
  //       //   setNumArr([...numArr, item.chargerID]);
  //       // }else{
  //       //   setNumArr([item.chargerID]);

  //       // }
  //     })

  //     console.log("final array : ", result4);
  //     console.log("final final array : ", finalResult);
  //     setFilterData(finalResult);

  //     // setFilterData(result2);
  //   }

  // }, [location, number])

  // useEffect(() => {
  //   console.log("location L ", location)
  //   if(number !== null){
  //     console.log("location conditionnnnnnnnnn")
  //     const result2 = data.filter((item) => {
  //       console.log("Address : ", item.description.toLocaleLowerCase().match(number.toLocaleLowerCase()));
  //       return item.description.toLocaleLowerCase().match(number.toLocaleLowerCase());
  //     });
  //     console.log("Result : ", result2);
      
  //     setFilterData(result2);
  //   }
  // }, [number])

  useEffect(() => {
    console.log("location L ", location)
    if(location !== null && number === null ){
      console.log("location conditionnnnnnnnnn");
      const result = data.filter((item) => {
        console.log("Addressss : : ", item.location.toLocaleLowerCase().match(location.toLocaleLowerCase()));
        return item.location.toLocaleLowerCase().match(location.toLocaleLowerCase());
      });
      console.log("Result : ", result);  
      setFilterData(result);
    }
    if(location !== null && number !== null){
      console.log("location conditionnnnnnnnnn");
      const result = data && data.filter((item) => {
        console.log("Addressss : : ", item.location.toLocaleLowerCase().match(location.toLocaleLowerCase()));
        return item.location.toLocaleLowerCase().match(location.toLocaleLowerCase()) && item.description.toLocaleLowerCase().match(number.toLocaleLowerCase());
      });
      console.log("Result : ", result);  
      setFilterData(result);
    }


  }, [location || number])

  useEffect(() => {
    console.log("location L ", location)
    if(number !== null && location === null){
      console.log("location conditionnnnnnnnnn")
      const result2 = data.filter((item) => {
        console.log("Nameeeee : : ", item.description.toLocaleLowerCase().match(number.toLocaleLowerCase()));
        return item.description.toLocaleLowerCase().match(number.toLocaleLowerCase());
      });
      console.log("Result : ", result2);
      
      setFilterData(result2);
  
    }

    if(number !== null && location !== null){
      console.log("location conditionnnnnnnnnn")
      const result2 = data.filter((item) => {
        console.log("Nameeeee : : ", item.description.toLocaleLowerCase().match(number.toLocaleLowerCase()));
        return item.description.toLocaleLowerCase().match(number.toLocaleLowerCase()) && item.location.toLocaleLowerCase().match(location.toLocaleLowerCase());
      });
      console.log("Result : ", result2);
      
      setFilterData(result2);
  
    }

  }, [number || location])
  

  // if(loading){
  return (
    <div className='d-flex'>
      <MASidebar />
      <div className='content-div'>
        <MANavbar user={user} data={data1} /> 

            {loader ? (
                <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '37vh'}}>
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#814097"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                </div>
            ) : ( 
        <div className='main-div' style={{padding: '16px 32px'}}>
                {/* <h4 className='mb-0'>View CPO Page</h4> */}
            <div className='Station-toggle m-0' style={{border: 'none'}}>
                <ul className='mt-0'>
                    <li className='active-tog mt-0' onClick={() => navigate(`/admin/viewcpo/profile/${id}`)}>CPO Profile</li>
                    <li className='mt-0' onClick={() => navigate(`/admin/viewcpo/chargers/${id}`)}>Chargers</li>
                </ul>
            </div>
              <div>
                  <div className="charger-box-div d-flex" style={{margin: '16px 0'}} key={id}>
                  <div className="cbd-a">
                      <img src={data1?.CompanyProfile?.ComparyLogo} height="300px" width="410px" alt="imgg" />
                  </div>
                  <div>
                      <h6 className='mb-3 mt-4'>{data1?.CompanyProfile?.PartnerId}</h6>
                      <h1 className='mb-3'>{data1?.CompanyProfile?.ProviderName}</h1>
                      <h5 className='mb-3'>{data1?.Email}</h5>
                      <h5 className='mb-3'>{data1?.CompanyProfile?.AddressNo}</h5>
                      <p><b>Address: </b>{data1?.CompanyProfile?.AddressNo} {data1?.CompanyProfile?.Subdistrict} {data1?.CompanyProfile?.District} {data1?.CompanyProfile?.Province}</p>
                      <p><b>Subscription: </b>{data1?.Subscription}</p>
                  </div>
                </div>
              </div>
            </div>)}
      </div>
    </div>
  );
  // }else{
  //   return(<div></div>);
  // }
}

export default ViewCPO;
