import React, { useState } from 'react'
import upwardArrowIcon from '../assets/greenArrow.svg';
import downwardArrowIcon from '../assets/redarrow.svg';
import ColumnGraph from './ColumnGraph';
import ColumnGraph2 from './ColumnGraph2';

export const CustomersOV = ({customLabels, statistics, chartData, datee}) => {
  const [tog, setTog] = useState(1);
  
  // console.log("Chart Data : : ", chartData);

  const yearLabels =  ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const oneDayLabels =  ["0-1", "1-2", "2-3", "3-4", "4-5", "5-6", "6-7", "7-8", "8-9", "9-10", "10-11", "11-12", "12-13", "13-14" , "14-15", "15-16", "16-17", "17-18", "18-19", "19-20", "20-21", "21-22", "22-23", "23-24"];
  const weekLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const month28 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'];
  const month29 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'];
  const month30 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
  const month31 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];

  const date2 = new Date();

  const yearc = date2.getFullYear();
  const monthc = date2.getMonth();

  // Get the last day of the month
  const lastDayc = new Date(yearc, monthc + 1, 0);

  // Get the number of days in the month
  const numDaysc = lastDayc.getDate();

  return (
    <div className='section-graph'>
        <div className='graph-div'>
            <h3 className='graph-sec-title'>Customers</h3>
            <h6 className='graph-sec-subtitle'>{datee?.option === 'Today' || datee?.option === 'Yesterday' ? datee?.option+' : '+datee?.startDate : datee !== null ? datee?.option+' : '+datee?.startDate+' - '+datee?.endDate : 'This Year: Jan 1, 2022 - Dec 31, 2022'}</h6>
            <div className='w-100 d-flex graph-card-wrapper'>
                {/* <div className="graph-card border-right">
                    <h6 className='mb-0 pb-20 graph-card-title'>PEA VOLTA Users</h6>
                    <h3 className='graph-card-number'>0 </h3>
                    <h6 className='font-style204 mb-0'><img src={upwardArrowIcon} className="mr-8o16" width="11.67px" height="11.67px" alt="" /> 48% <span className='font-style205'>vs last year</span></h6>
                </div> */}
                <div className="graph-card2 border-right ">
                    <h6 className='mb-0 pb-20 graph-card-title'>All Your Customers</h6>
                    <h3 className='graph-card-number'>{(statistics?.customers)?.toLocaleString()} </h3>
                    {/* <h6 className='font-style206 mb-0'><img src={downwardArrowIcon} className="mr-8o16" width="11.67px" height="11.67px" alt="" /> 24% <span className='font-style205'>vs last year</span></h6> */}
                </div>
                <div className="graph-card2 border-right pl-24">
                    <h6 className='mb-0 pb-20 graph-card-title'>Your New Customers</h6>
                    <h3 className='graph-card-number'>{(statistics?.uniqueCustomers)?.toLocaleString()}</h3>
                    {/* <h6 className='font-style204 mb-0'><img src={upwardArrowIcon} className="mr-8o16" width="11.67px" height="11.67px" alt="" /> 16% <span className='font-style205'>vs last year</span></h6> */}
                </div>
                <div className="graph-card2 pl-24">
                    <h6 className='mb-0 pb-20 graph-card-title'>Your Repeat Customers</h6>
                    <h3 className='graph-card-number'>{(statistics?.customers - statistics?.uniqueCustomers)?.toLocaleString()}</h3>
                    {/* <h6 className='font-style204 mb-0'><img src={upwardArrowIcon} className="mr-8o16" width="11.67px" height="11.67px" alt="" /> 56% <span className='font-style205'>vs last year</span></h6> */}
                </div>
            </div>
            <div className='w-100 d-flex'>
              <div className='pt-20 w-25'>
                <h4 className='font-style-7'>Statistics</h4>
              </div>
              <div className='w-75'>
                {/* <div className='toggles-graph'>
                  <ul className='graphs-ul'>
                    <li className={tog === 1 ? 'graph-active-li' : 'unactive'}
                    onClick={() => setTog(1)}
                    >Revenue</li>
                    <li className={tog === 2 ? 'graph-active-li' : 'unactive'}
                    onClick={() => setTog(2)}
                    >Charging Sessions</li>
                    <li className={tog === 3 ? 'graph-active-li' : 'unactive'}
                    onClick={() => setTog(3)}
                    >Customers</li>
                    <li className={tog === 4 ? 'graph-active-li' : 'unactive'}
                    onClick={() => setTog(4)}
                    >Energy Used</li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className='w-100'>
                <ul className='new-repeat-customers'>
                    <li><span></span> New Customers</li>
                    <li className='ms-4'><span className='li-bg-clr2'></span> Repeat Customers</li>
                </ul>
            </div>
            <div className='w-100'>
              {/* <p className='mb-0 pb-0 ms-3'>No. of Customers</p> */}
              {/* <ColumnGraph tog={tog} /> */}
              {chartData?.uniqueCustomers && chartData?.repeatCustomers && (
                <ColumnGraph2 labs={datee?.option === 'Today' || datee?.option === 'Yesterday' ? oneDayLabels : datee?.option === 'Custom Range' ? customLabels : datee?.option === 'This Week' || datee?.option === 'Last Week' ? weekLabels : datee?.option === 'This Month' || datee?.option === 'Last Month' ? (numDaysc === 28 ? month28 : numDaysc === 29 ? month29 : numDaysc === 31 ? month31 : month30) : yearLabels} unique={chartData?.uniqueCustomers} repeated={chartData?.repeatCustomers}  />
              )}
            </div>
        </div>
    </div>
  )
}
