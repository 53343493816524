// import React, { useState } from 'react';
// import Chart from 'react-apexcharts'

// const ColumnGraph2 = ({tog}) => {
//   const data =  [{
//     name: 'Repeat Customers',
//     data: [5.3, 3.1, 7.0, 10.1, 4.0, 8.6, 6.2, 7.3, 1.4, 8, 5, 8.2]
//   }, {
//     name: 'New Customers',
//     data: [8.3, 3.1, 9.0, 7.1, 4.0, 6.6, 3.2, 7.3, 9.4, 8, 5, 6]
//   }]
//   const option = {
//     chart: {
//       type: 'bar',
//       height: 500,
//       stacked: true,
//       toolbar: {
//         show: true
//       },
//       zoom: {
//         enabled: true
//       },
//     },
//     plotOptions: {
//           bar: {
//             borderRadius: 5,
//             dataLabels: {
//               position: 'top', // top, center, bottom
//             },
//           }
//         },
//     dataLabels: {
//       enabled: true,
//       formatter: function (val) {
//         return val + "%";
//       },
//       offsetY: -20,
//       style: {
//         colors: ["transparent"]
//       }
//     },
//     responsive: [{
//       breakpoint: 480
//     }],
//     xaxis: {
//           categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//           position: 'bottom',
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false
//           },
//           crosshairs: {
//             fill: {
//               type: 'gradient',
//               gradient: {
//                 colorFrom: '#D8E3F0',
//                 colorTo: '#BED1E6',
//                 stops: [0, 100],
//                 opacityFrom: 0.4,
//                 opacityTo: 0.5,
//               }
//             }
//           },
//           tooltip: {
//             enabled: true,
//           }
//         },
//     yaxis: {
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false,
//           },
//           labels: {
//             show: false,
//             formatter: function (val) {
//               return val + "%";
//             }
//           }    
//         },
//     fill: {
//       opacity: 1,
//       colors: ['#672F7A', '#B76AD1']
//     }
//   }

//   return (
//     <div id="chart">
//       <Chart options={option} series={ data } type="bar" height={350} />
//     </div>
//   )
// }

// export default ColumnGraph2


import React, { useState } from 'react';
import Chart from 'react-apexcharts';

function ColumnGraph2({unique, repeated, labs}) {
  const [options, setOptions] = useState({
    chart: {
      id: 'customers-bar'
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "";
      },
      offsetY: -20,
      style: {
        colors: ["transparent"]
      }
    },
    responsive: [{
      breakpoint: 480
    }],                               
    xaxis: {
      // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      categories: labs,
      position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        // show: false,
        show: true,
        formatter: function (val) {
          return val + "";
        }
      }    
    },
    fill: {
      opacity: 1,
      colors: ['#B76AD1', '#672F7A']
    }
  });

  const [series, setSeries] = useState([
    { 
      name: 'New Customers',
      data: unique && unique
      // data: [500, 450, 520, 250, 440, 350, 400, 450, 500, 550, 600, 650]
    }, 
    {
      name: 'Repeated Customers',
      data: repeated && repeated
      // data: [440, 475, 500, 325, 150, 175, 200, 225, 250, 275, 300, 325]
    }
  ]);

  return (
    <div>
      <p className='mb-0 pb-0 ms-3'>No. of Customers</p>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
}

export default ColumnGraph2;
