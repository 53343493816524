import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const HeatMapChart = () => {
    function generateData(count, yrange) {
        var i = 0;
        var ab = 0;
        var series = [];
        while (i < count) {
          var x = (ab + 0).toString();
          var y =
            Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      
          series.push({
            x: x,
            y: y
          });
          i++;
          ab = ab+2;
        }
        return series;
      }

    const [options, setOptions] = useState({
        chart: {
          height: 350,
          type: 'heatmap',
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#672F7A"],
        title: {
          text: ''
        },
      });
    const [series, setSeries] = useState([{
        name: 'Mon',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Tue',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Wed',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Thu',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Fri',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Sat',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Sun',
        data: generateData(18, {
          min: 0,
          max: 90
        })
      }
      ]);


    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="heatmap" height={350} />
        </div>
    )
}

export default HeatMapChart





// class ApexChart extends React.Component {
//     constructor(props) {
//       super(props);

//       this.state = {
      
//         series: [{
//           name: 'Metric1',
//           data: generateData(18, {
//             min: 0,
//             max: 90
//           })
//         },
//         {
//           name: 'Metric2',
//           data: generateData(18, {
//             min: 0,
//             max: 90
//           })
//         },
//         {
//           name: 'Metric3',
//           data: generateData(18, {
//             min: 0,
//             max: 90
//           })
//         },
//         {
//           name: 'Metric4',
//           data: generateData(18, {
//             min: 0,
//             max: 90
//           })
//         },
//         {
//           name: 'Metric5',
//           data: generateData(18, {
//             min: 0,
//             max: 90
//           })
//         },
//         {
//           name: 'Metric6',
//           data: generateData(18, {
//             min: 0,
//             max: 90
//           })
//         },
//         {
//           name: 'Metric7',
//           data: generateData(18, {
//             min: 0,
//             max: 90
//           })
//         },
//         {
//           name: 'Metric8',
//           data: generateData(18, {
//             min: 0,
//             max: 90
//           })
//         },
//         {
//           name: 'Metric9',
//           data: generateData(18, {
//             min: 0,
//             max: 90
//           })
//         }
//         ],
//         options: {
//           chart: {
//             height: 350,
//             type: 'heatmap',
//           },
//           dataLabels: {
//             enabled: false
//           },
//           colors: ["#008FFB"],
//           title: {
//             text: 'HeatMap Chart (Single color)'
//           },
//         },
      
      
//       };
//     }

  

//     render() {
//       return (
        

//   <div id="chart">
// <ReactApexChart options={this.state.options} series={this.state.series} type="heatmap" height={350} />
// </div>


//       );
//     }
//   }