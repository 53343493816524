import React from 'react'
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react'
function GoogleMap(props) {
  return (
    <Map
        google={props.google}
        style={{width: props.width, height: props.height}}
        zoom={6}
        center={{width: props.width, height: props.height}}
        initialCenter={{
            lat: props.lat, lng: props.lng
        }}
        fullscreenControl={false}
        mapTypeControl={false}
        zoomControl={false}
        scaleControl={false}
        streetViewControl={false}
        >
        <Marker position={{
            lat: props?.lat, lng: props?.lng
        }}/>
        {/* <Marker position={{
            lat: 13.54332638, lng: 100.26477051
        }}/> */}
    </Map>
  )
}
export default GoogleApiWrapper({
    apiKey:'AIzaSyAJ6pz3IkaaSuapZIgBDgEYA_t2qweG6Fo'
})(GoogleMap)