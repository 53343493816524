

import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
// import icon_one from '../../assets/map_icon_one.svg';
// import icon_two from '../../assets/map_icon_two.svg';
// import icon_three from '../../assets/map_icon_three.svg';
// import popup_img from '../../assets/map-boxpic.svg';
import box_icon1 from '../../assets/ev_station.svg';
import box_icon2 from '../../assets/electrical_services.svg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const GoogleMapTwo = (props) => {
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [stations, setStations] = useState([]);
  const [allStations, setAllStations] = useState([]);
  const [name, setName] = useState([]);
  const [flag, setFlag] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios
      .get('https://api.pea-cpo.com/api/getChargerListCpo', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log('Get Charger list : ', res);
        setStations(res?.data?.data);
      })
      .catch((err) => {
        console.log('Error get user : ', err);
      });

  }, [flag]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios
      .get('https://api.pea-cpo.com/api/getChargerListCpoMap', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // console.log('getChargerListCpoMap Response : ', res);
        setAllStations(res?.data?.data);
        // console.log('all stations props : ', props?.allStations);
      })
      .catch((err) => {
        console.log('Error get user : ', err);
      });
  }, [flag])

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onMapClicked = (props) => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  function createMapOptions (maps) {
    return {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
    }
  }

  const myStationsCheck = (cid) => {
    let sign = 0;
    stations && stations.map((item) => {
      if(item?.id === cid){
        sign = sign + 1;
      }
    })
    if(sign === 1){
      return 1;
    } else {
      return 0;
    }
  }

  const navFun = () => {
    alert("Alert clicked"); navigate(`/stations/detail/${selectedPlace?.station?.id}`);
  }

  return (
    <Map
      google={props.google}
      style={{ width: props?.width, height: props?.height }}
      // center={{ width: props?.width_, height: props?.height_}}
        // lat: 33.684422, lng: 73.047882
      initialCenter={{
        lat: 7.87005281,
        lng: 99.16368866,
      }}
      zoom={5}
      onClick={onMapClicked}
      options={createMapOptions}
      fullscreenControl={false}
        mapTypeControl={false}
        zoomControl={false}
        scaleControl={false}
        streetViewControl={false}
    >
      {allStations && allStations.map((item, index) => (
        <Marker
          key={index}
          position={{ lat: item?.lat, lng: item?.lng }}
          icon={{
            url: item?.chargerStatusImage,
            anchor: new props.google.maps.Point(7.5, 10),
            scaledSize: new props.google.maps.Size(50, 50),
          }}
          // options={defaultMapOptions}
          onClick={onMarkerClick}
          name={item?.locationEN}
          station={item}
        />
      ))}
      <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
        <div className='map_popup_div d-flex'>
          <div className='map_popup_div1'>
            <img
              src={selectedPlace?.station?.chargerPhoto}
              width='176px'
              height='167px' />
          </div>
          <div className='map_popup_div2'>
            <p className='mapb-s1 mb-0 w-100'>24 THAILAND TOP Charging Station  </p>
            <h6 className='mapb-s2 mb-0 mt-1'>{selectedPlace?.station?.locationTH}</h6>
            <p className='mapb-s3 mb-0 mt-1'>{selectedPlace?.station?.descriptionTH} </p>
            <p className='mb-0 mt-2'>
              <span className='mapb-s5-x'>{selectedPlace?.station?.opening?.startTime} - {selectedPlace?.station?.opening?.endTime}.</span>
            </p>
            <p className='mb-0 mt-3'>
              <span className='mapb-s6'>
                <img src={box_icon1} width="15px" height="15px" />
                <span className='mapb-s7'><b>2</b>/ 2</span>
              </span>

              <span className='mapb-s8'></span>

              <span className='mapb-s6'>
                <img src={box_icon2} width="15px" height="15px" />
                <span className='mapb-s7 mapb-s9'><b>2</b>/ 5</span>
              </span>

              <span className='mapb-s8'></span>

              <span className='mapb-s10'>Fee:</span>
              <span className='mapb-s11'>TOU</span>
            </p>
                {myStationsCheck(selectedPlace?.station?.id) === 1 && <button className='btn-view-map' type='button' onClick={console.log("cliedkeed")}>View</button>}
          </div>
        </div>
      </InfoWindow>
    </Map>
  )
}

export default GoogleApiWrapper({
    apiKey:'AIzaSyAJ6pz3IkaaSuapZIgBDgEYA_t2qweG6Fo'
})(GoogleMapTwo)












// import React, { Component, useState } from 'react'
// import {Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react'
// import icon_one from '../../assets/map_icon_one.svg'
// import icon_two from '../../assets/map_icon_two.svg'
// import icon_three from '../../assets/map_icon_three.svg'
// import popup_img from '../../assets/map-boxpic.svg';
// import box_icon1 from '../../assets/ev_station.svg';
// import box_icon2 from '../../assets/electrical_services.svg';
// import axios from 'axios'



// class GoogleMapTwo extends Component {
//     state = {
//       showingInfoWindow: false,
//       activeMarker: {},
//       selectedPlace: {},
//       stations: [],
//       // allStations: [],
//       name: [],
//     };

//     componentDidMount(){
//       const token = localStorage.getItem('token');
//         // console.log("token found value is : ", token);
//         axios.get("https://api.pea-cpo.com/api/getChargerListCpo", {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
//         console.log("Get Charger list : ", res);
//         this.setState({stations: res?.data?.username})

//         }).catch((err) =>{
//         console.log("Error get user : ", err);
//         });


//         axios.get("https://api.pea-cpo.com/api/getChargerListCpoMap", {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
//         console.log("getChargerListCpoMap Response : ", res);
//         this.setState({allStations: res?.data?.username})
//         console.log("all  stations props : ", this.props?.allStations);

//         }).catch((err) =>{
//         console.log("Error get user : ", err);
//         })

//         // console.log("search props : ", this.props?.search);
//         // console.log("stations props : ", this.props?.stations);
//         // console.log("all  stations props : ", this.props?.allStations);
//       }
   
//     onMarkerClick = (props, marker, e) => {
//       // console.log("seletedplse", props);
//       // console.log("active marker", marker);
//       this.setState({
//         selectedPlace: props,
//         activeMarker: marker,
//         showingInfoWindow: true
//       });
//     }

      
//       onMapClicked = (props) => {
//         if (this.state.showingInfoWindow) {
//           this.setState({
//             showingInfoWindow: false,
//             activeMarker: null
//           })
//         }
//       };
    
//     render() {
//       return (
//           <Map google={this.props.google}
//             style={{width: this.props?.width, height: this.props?.height}}
//             center={{width: this.props?.width, height: this.props?.height}}
//             initialCenter={{
//                 // lat: 33.684422, lng: 73.047882
//                 lat: 7.87005281, lng: 99.16368866
//             }}
//             zoom={5}
//             onClick={this.onMapClicked}>
              
              
//               {this.props.allStations && this.props.allStations.map((item, index) => (
//                 <Marker key={index} position={{lat: item?.lat, lng: item?.lng}} 
//                   icon={{
//                       url: item?.chargerStatusImage,
//                       anchor: new this.props.google.maps.Point(7.5, 10),
//                       scaledSize: new this.props.google.maps.Size(50, 50)
//                   }}
//                   onClick={ this.onMarkerClick}
//                   name={item?.locationEN} 
//                   station={item}
//                 />  
//               ))}
   
//           <InfoWindow
//             marker={this.state.activeMarker}
//             visible={this.state.showingInfoWindow}>
//               <div className='map_popup_div d-flex'>
//                 <div className='map_popup_div1'>
//                   <img src={this.state.selectedPlace?.station?.chargerPhoto} width="176px" height='167px' />
//                 </div>
//                 <div className='map_popup_div2'>
//                   <p className='mapb-s1 mb-0'>24 THAILAND TOP Charging Station</p>
//                   <h6 className='mapb-s2 mb-0 mt-1'>{this.state.selectedPlace?.station?.locationTH}</h6>
//                   <p className='mapb-s3 mb-0 mt-1'>{this.state.selectedPlace?.station?.descriptionTH} </p>
//                   <p className='mb-0 mt-2'>
//                     <span className='mapb-s5-x'>{this.state.selectedPlace?.station?.opening?.startTime} - {this.state.selectedPlace?.station?.opening?.endTime}.</span>
//                   </p>
//                   <p className='mb-0 mt-3'>
//                     <span className='mapb-s6'>
//                       <img src={box_icon1} width="15px" height="15px" />
//                       <span className='mapb-s7'><b>2</b>/ 2</span>
//                     </span>

//                     <span className='mapb-s8'></span>

//                     <span className='mapb-s6'>
//                       <img src={box_icon2} width="15px" height="15px" />
//                       <span className='mapb-s7 mapb-s9'><b>2</b>/ 5</span>
//                     </span>

//                     <span className='mapb-s8'></span>

//                     <span className='mapb-s10'>Fee:</span>
//                     <span className='mapb-s11'>TOU</span>
//                   </p>
//                 </div>
//               </div>
//           </InfoWindow>
//         </Map>
//       )
//     }

// }

// export default GoogleApiWrapper({
//     apiKey:'AIzaSyDKXKdHQdtqgPVl2HI2RnUa_1bjCxRCQo4'
// })(GoogleMapTwo)




















// import React from 'react'
// import {Map, GoogleApiWrapper, Marker} from 'google-maps-react'
// import icon_one from '../../assets/map_icon_one.svg'
// import icon_two from '../../assets/map_icon_two.svg'
// import icon_three from '../../assets/map_icon_three.svg'
// function GoogleMapTwo(props) {
//   return (
//         <Map
//             google={props.google}
//             style={{width: props.width, height: props.height}}
//             zoom={6}
//             center={{width: props.width, height: props.height}}
//             initialCenter={{
//                 lat: 33.684422, lng: 73.047882
//             }}
//             >
//             <Marker position={{
//                 lat: 33.565109, lng: 73.016914
//             }}
//             options={{
//                 icon: icon_one
//             }}/>
//             <Marker position={{
//                 lat: 32.3064, lng: 74.0365
//             }}
//             options={{
//                 icon: icon_two
//             }}/>
//             <Marker position={{
//                  lat: 33.684422, lng: 73.047882
//             }}
//             options={{
//                 icon: icon_three
//             }}/>
//             </Map>
      
//       )
// }
// export default GoogleApiWrapper({
//     apiKey:'AIzaSyDKXKdHQdtqgPVl2HI2RnUa_1bjCxRCQo4'
// })(GoogleMapTwo)
