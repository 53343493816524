import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from 'react-apexcharts'


const LineGraph = ({labs}) => {
    const [series, setSeries] = useState([{
        name: 'XYZ MOTORS',
        data: [
            {
              x: new Date("2020-03-17T00:00:00.025385Z").getTime(),
              y: 0
            },
            {
              x: new Date("2020-03-17T01:00:00.025385Z").getTime(),
              y: 5
            },
            {
              x: new Date("2020-03-17T02:00:00.025385Z").getTime(),
              y: 3
            },
            {
              x: new Date("2020-03-17T03:00:00.025385Z").getTime(),
              y: 2
            },
            {
              x: new Date("2020-03-17T04:00:00.025385Z").getTime(),
              y: 0
            },
            {
              x: new Date("2020-03-17T05:00:00.025385Z").getTime(),
              y: 0
            },
            {
              x: new Date("2020-03-17T06:00:00.025385Z").getTime(),
              y: 0
            },
            {
              x: new Date("2020-03-17T07:00:00.025385Z").getTime(),
              y: 56
            },
            {
              x: new Date("2020-03-17T08:00:00.025385Z").getTime(),
              y: 22
            },
            {
              x: new Date("2020-03-17T09:00:00.025385Z").getTime(),
              y: 35
            },
            {
              x: new Date("2020-03-17T10:00:00.025385Z").getTime(),
              y: 20
            },
            {
              x: new Date("2020-03-17T11:00:00.025385Z").getTime(),
              y: 25
            },
            {
              x: new Date("2020-03-17T12:00:00.025385Z").getTime(),
              y: 30
            },
            {
              x: new Date("2020-03-17T13:00:00.025385Z").getTime(),
              y: 32
            },
            {
              x: new Date("2020-03-17T14:00:00.025385Z").getTime(),
              y: 43
            },
            {
              x: new Date("2020-03-17T15:00:00.025385Z").getTime(),
              y: 41
            },
            {
              x: new Date("2020-03-17T16:00:00.025385Z").getTime(),
              y: 42
            },
            {
              x: new Date("2020-03-17T17:00:00.025385Z").getTime(),
              y: 56
            },
            {
              x: new Date("2020-03-17T18:00:00.025385Z").getTime(),
              y: 78
            },
            {
              x: new Date("2020-03-17T19:00:00.025385Z").getTime(),
              y: 50
            },
            {
              x: new Date("2020-03-17T20:00:00.025385Z").getTime(),
              y: 45
            },
            {
              x: new Date("2020-03-17T21:00:00.025385Z").getTime(),
              y: 10
            },
            {
              x: new Date("2020-03-17T22:00:00.025385Z").getTime(),
              y: 15
            },
            {
              x: new Date("2020-03-17T23:00:00.025385Z").getTime(),
              y: 5
            },
            {
              x: new Date("2020-03-17T23:00:00.025385Z").getTime(),
              y: 3
            }
          ]
      }]);
    
    const [options, setOptions] = useState({
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        title: {
          text: '',
          align: 'left'
        },
        fill: {
          type: 'gradient',
        //   gradient: {
        //       colorFrom: '#7718BC',
        //       colorTo: '#7718BC',
        //       shadeIntensity: 1,
        //       inverseColors: false,
        //       opacityFrom: 0.95,
        //       opacityTo: 0.4,
        //       stops: [0, 90, 100]
        //     },
        gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.7,
            opacityTo: 0.15,
          }
        },
        colors: ['#7718BC'],
        yaxis: {
          labels: {
            show: labs,
            formatter: function (val) {
              return (val / 1000000).toFixed(0);
            },
          },
          title: {
            text: ''
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return (val / 1000000).toFixed(0)
            }
          }
        }
      })

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="area" height={350} />
        </div>
    )
}

export default LineGraph;










