import React, { useState } from 'react'

export default function MyProfile({user}) {
        // setImgPreview([res?.data?.data[0]?.chargerPhoto])
    console.log("User user : : ", user)
        const [imgPreview, setImgPreview] = useState([user?.CompanyProfile?.ComparyLogo])
    function handleFile(event){ 
        if(event.target.files){
            const files = event.target.files
            let array = []
            for(var i=0;i<files.length;i++){
                array.push(URL.createObjectURL(files[i]))
            }
            setImgPreview([...array])
        }
    }



    function removeImg(index){
        const array = []
        for(var i=0;i<imgPreview.length;i++){
            if(i !== index){
                array.push(imgPreview[i])
            }
        }
        setImgPreview([...array])
    }

  return (
<div className='station-overview'>
    <div className='overview-section-heading'>
        <h3>Company Profile</h3>
        <p>Update your company details and address.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Provider name</h5>
                <p>This will be displayed to customer.</p>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' readOnly defaultValue={user?.CompanyProfile?.ProviderName} placeholder='Charge Wai Co., Ltd.'/>
            </div>
        </div>
        <div className='station-field location'>
            <div className='station-field-div1'>
                <h5>Company Logo</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='img-preview'>
                <div>
                {imgPreview.length > 0 && imgPreview.map((url, index)=>
                    <div key={index}>
                        <span style={{cursor:'pointer'}} onClick={()=>removeImg(index)}>&times;</span>
                        <img src={url} alt={url}/>
                    </div>
                )}
                </div>
                <input type='file' readOnly className='form-control' accept="image/*"  onChange={handleFile}/>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Tax ID</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' readOnly defaultValue={user?.CompanyProfile?.TaxId} placeholder='1-3859-00043-33-1'/>
            </div>
        </div>
        <div className='station-field' style={{marginTop:'2rem'}}>
            <div className='station-field-div1'>
                <h5>Company Address</h5>
                <p>Address that available for contact</p>
            </div>
            <div>
                <div style={{width:'100%'}}>
                    <p style={{marginBottom:'0.3rem'}}>Street address</p>
                    <input className='form-control' readOnly defaultValue={user?.CompanyProfile?.AddressNo} placeholder='888 Soi Rangsit - Nakornnayok 49, Prachathipat'/>
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Apartment, Suite, etc (optional)</p>
                        <input className='form-control' readOnly placeholder='-'/>
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>City</p>
                        <input className='form-control' readOnly defaultValue={user?.CompanyProfile?.District} placeholder='Thunyaburi'/>
                    </div>
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Province</p>
                        <input className='form-control' readOnly defaultValue={user?.CompanyProfile?.Province} placeholder='Pathumthani '/>
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Zip code</p>
                        <input className='form-control' readOnly defaultValue={user?.CompanyProfile?.ZipCode} placeholder='12130'/>
                    </div>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Telephone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' readOnly defaultValue={user?.CompanyProfile?.TelephoneNumber} placeholder='02-236-0192' />
            </div>
        </div>
    </div>
    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>Personal Profile</h3>
        <p>Update your personal details and address.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input className='form-control' readOnly defaultValue={user?.PersonalProfile?.Name} placeholder='Charge'/>
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input className='form-control' readOnly defaultValue={user?.PersonalProfile?.Prename} placeholder='Wai'/>
                </div>
            </div>
        </div>
        <div className='station-field' style={{marginTop:'2rem'}}>
            <div className='station-field-div1'>
                <h5>Address</h5>
                <p>Address that available for contact</p>
            </div>
            <div>
                <div style={{width:'100%'}}>
                    <p style={{marginBottom:'0.3rem'}}>Street address</p>
                    <input className='form-control' readOnly defaultValue={user?.PersonalProfile?.AddressNo} placeholder='888 Soi Rangsit - Nakornnayok 49, Prachathipat'/>
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Apartment, Suite, etc (optional)</p>
                        <input className='form-control' readOnly placeholder='-'/>
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>City</p>
                        <input className='form-control' readOnly defaultValue={user?.PersonalProfile?.District} placeholder='Thunyaburi'/>
                    </div>
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Province</p>
                        <input className='form-control' readOnly defaultValue={user?.PersonalProfile?.Province} placeholder='Pathumthani '/>
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Zip code</p>
                        <input className='form-control' readOnly defaultValue={user?.PersonalProfile?.ZipCode} placeholder='12130'/>
                    </div>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" className='form-control' readOnly defaultValue={user?.PersonalProfile?.Email} placeholder='charge.wai@peavolta.com'/>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' readOnly defaultValue={user?.PersonalProfile?.MobilePhoneNumber} placeholder='099-234-9877'/>
            </div>
        </div>
    </div>

    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>Accountant Contact</h3>
        <p>Update your accountant contact.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input className='form-control' readOnly defaultValue={user?.AccountantContact?.Name} placeholder='Charge'/>
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input className='form-control' readOnly defaultValue={user?.AccountantContact?.Surname} placeholder='Wai'/>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" className='form-control' readOnly placeholder='charge.wai@peavolta.com'/>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' readOnly placeholder='099-234-9877'/>
            </div>
        </div>
    </div>

    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>Maintenance Personnel Contact</h3>
        <p>Update your maintenance personnel contact.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input className='form-control' readOnly defaultValue={user?.MaintenancePersonalContact?.Name} placeholder='Charge'/>
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input className='form-control' readOnly defaultValue={user?.MaintenancePersonalContact?.Surname} placeholder='Wai'/>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" className='form-control' readOnly defaultValue={user?.MaintenancePersonalContact?.Email} placeholder='charge.wai@peavolta.com'/>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' readOnly defaultValue={user?.MaintenancePersonalContact?.PhoneNumber} placeholder='099-234-9877'/>
            </div>
        </div>
    </div>

    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>On-site Service Contact</h3>
        <p>Update your On-site service contact.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input className='form-control' readOnly defaultValue={user?.OnSiteServiceContact?.Name} placeholder='Charge'/>
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input className='form-control' readOnly defaultValue={user?.OnSiteServiceContact?.Surname} placeholder='Wai'/>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" className='form-control' readOnly defaultValue={user?.OnSiteServiceContact?.Email} placeholder='charge.wai@peavolta.com'/>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' readOnly defaultValue={user?.OnSiteServiceContact?.PhoneNumber} placeholder='099-234-9877'/>
            </div>
        </div>
    </div>
    <div className='station-setting-btns' style={{marginTop:'3rem'}}>
        <button className='cancel-btn'>Cancel</button>
        <button className='save-btn'>Save Changes</button>
    </div>
</div>
  )
}
