import React, { useEffect, useState } from 'react'
import vectorIcon from '../assets/Vector.svg';
import ellipseIcon from '../assets/symbol.svg';
import groupIcon from '../assets/Group 31.svg';
import penMarkerIcon from '../assets/pin-marker.svg';
import grayPenMarkerIcon from '../assets/grap-penmarker.svg';
import symbolIcon from '../assets/symbol1.svg';
import calenderIcon from '../assets/calendar.svg';
import { DashGraph } from './DashGraph';
import StackedGraph from './StackedGraph';
import DataTableBase from './reuseable/DataTableBase';
import { Col, Row } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import DatePicker from './reuseable/DatePicker';
import { CustomersOV } from './CustomersOV';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { BallTriangle } from 'react-loader-spinner';

import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import DataTable from 'react-data-table-component';
import { getCurrentMonth, getCurrentWeekDates, getCurrentYear } from '../utility';

// import { AES, enc } from 'crypto-js';


const CustomTableHeader = ({title, time, setFilter}) => {
  const [tog, setTog] = useState(1);
  return (
    <div className='w-100'>
      <Row className="custom-table-hdr">
        <Col md={6}>
          <h3 className='font-style24 mb-0 pb-0'>{title}</h3>
          <h5 className='font-style25 mb-0 pb-0'>{time}</h5>
        </Col>
        <Col md={6}>
          <span className={tog === 1 ? 'font-style26 font-style26-active' : 'font-style26'} onClick={() => {setFilter('Week'); setTog(1)}}>Week</span>
          <span className={tog === 2 ? 'font-style26 font-style26-active' : 'font-style26'} onClick={() => {setFilter('Month'); setTog(2);}}>Month</span>
          <span className={tog === 3 ? 'font-style26 font-style26-active' : 'font-style26'} onClick={() => {setFilter('Year');setTog(3);}}>Year</span>
        </Col>
      </Row>
    </div>
  )
}



const rowHeight = 60;

const Table = ({lateastTransactions, columns}) => {
  const rowRenderer = ({ index, style }) => {
    const row = lateastTransactions[index];
    return (
      <div style={style}>
        <DataTable.Row {...row.getRowProps()}>
          {row.cells.map((cell) => (
            <DataTable.Cell {...cell.getCellProps()}>{cell.render('Cell')}</DataTable.Cell>
          ))}
        </DataTable.Row>
      </div>
    );
  };

  const TableComponent = ({ height }) => (
    <AutoSizer disableWidth>
      {({ width }) => (
        <List
          height={height}
          itemCount={lateastTransactions?.length}
          itemSize={rowHeight}
          width={width}
        >
          {rowRenderer}
        </List>
      )}
    </AutoSizer>
  );

  return (
    <div>
      <DataTable
        columns={columns}
        data={[]}
        noHeader
        dense
        pagination
        paginationPerPage={10}
        subHeader
        subHeaderComponent={<TableComponent height={500} />}
      />
    </div>
  );
};



const Overview = ({user}) => {
  const [dateRange, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const [overview, setOverview] = useState(null);
  const [chargingStations, setChargingStations] = useState(null);

  const [dateRange2, setDateRange2] = useState(null);

  const [topStations, setTopStations] = useState(null);

  const [topConnectors, setTopConnectors] = useState(null);

  const [loader, setLoader] = useState(true);
  const [loading2, setLoading2] = useState(false);

  const [tog2, setTog2] = useState(1);

  const [flag, setFlag] = useState(1);

  const [filter, setFilter] = useState('Week');
  const [filter2, setFilter2] = useState('Week')
  
  const [labels, setLabels] = useState([]);

  const [checkPoint, setCheckPoint] = useState(false);

  const [stationMatrix, setStationMatrix] = useState(null);
  const [stationChartMatrix, setStationChartMatrix] = useState(null);

  const [display, setDisplay] = useState({
    display:'none'
  });

  function toggleDate(){
    if(display.display === 'none')
    setDisplay({display: 'block'})
    else
    setDisplay({display: 'none'})
  }

  function handleSelect(date){
    setRange({ 
        startDate: date.selection.startDate,
        endDate: date.selection.endDate,
        key: date.selection.key
    })
  }





  // const encryptedString = 'eyJpdiI6ImFyTVRZbDlyN2FKelBYdTBEdXBOSXc9PSIsInZhbHVlIjoicHFGZmNqY0pleGJmUHJRYmZxYURYWkNESHJlSzREbUNXQXpWRStjcW9mST0iLCJtYWMiOiI0YTg1MTI4ZGU1NjhhNTRkYjRmYTdjMzdkNjEzYWQ4ZDA1M2Y1NTRiYjExNjI2ZTJiZTQ0YWMxNDBhYjIwNzZiIiwidGFnIjoiIn0='; // Replace with your encrypted data
  // const secretKey ='base64:2W2g9YbA52NaBLc3TICjmM5S0LZdpRguoH9DR2gnChQ='; // Replace with your encryption key
  
  // const secretKey = 'your-secret-key'; // Replace with your secret key
  // const encryptedString = 'your-encrypted-string'; // Replace with your encrypted string
  
  // const [encryptedString, setEncryptedString] = useState('');
  // const [decryptedString, setDecryptedString] = useState('');

  // const secretKey = '2W2g9YbA52NaBLc3TICjmM5S0LZdpRguoH9DR2gnChQ='; // Replace with your secret key
  // const originalString = '18218685214'; // The string to encrypt and decrypt
  

  // useEffect(() => {
  //   // Encrypt the original string
  //   // const encrypted = AES.encrypt(originalString, secretKey).toString();
  //   // setEncryptedString(encrypted);

  //   // // Decrypt the encrypted string
  //   // const bytes = AES.decrypt(encrypted, secretKey);
  //   // const decrypted = bytes.toString(enc.Utf8);
  //   // setDecryptedString(decrypted);


  //   // Encryption settings
  //   const encryptionKey = 'your_secret_key_here'; // Same key as used in Laravel
  //   const cipher = 'AES-256-CBC'; // Same cipher as used in Laravel

  //   // Convert the key from base64 (if necessary)
  //   const key = CryptoJS.enc.Base64.parse(encryptionKey);

  //   // Decrypt the value
  //   const decryptedBytes = CryptoJS.AES.decrypt(encryptedText, key, {
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //   });

  //   const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
  //   console.log('Decrypted Text:', decryptedText)

  // }, [secretKey, originalString]);


  // useEffect(() => {
  //   console.log("encryptedString string : ", encryptedString)
  //   console.log("decryptedString string : ", decryptedString)
  // }, [encryptedString, decryptedString])



  
  const columns = [
    {
        name: "Station Name",
        selector: row => row?.stationName,
    },
    {
        name: "Date Time",
        selector: row => `${row?.updated_at}`,
        sortable: true,
    },
    {
        name: "Message",
        selector: row => row?.reasonErrorMsg ? row?.reasonErrorMsg : "null" ,
        sortable: true,
    }
  ];



const columns2 = [
  {
      name: "Station Name",
      // selector: row => row?.location,
      selector: row => row?.location,
  },
  {
      name: "Connector",
      selector: row => row?.connector,
      sortable: true,
  },
  {
      name: "Start Charge",
      selector: row => `${row?.startDate} ${row?.startTime}`,
      sortable: true,
  },
  {
      name: "End Charge",
      selector: row => `${row?.endDate} ${row?.endTime}`,
      sortable: true,
  },
  {
      name: "Duration (mins)",
      selector: row => Math.ceil(Number(row?.duration) * 100) / 100,
      sortable: true,
  },
  {
      name: "Energy (kWh)",
      selector: row => Math.ceil(Number(row?.totalKwh) * 100) / 100,
      sortable: true,
  },
  {
      name: "Revenue (THB)",
      selector: row => Math.ceil(Number(row?.revenue) * 100) / 100,
      sortable: true,
  },
];



const columns3 = [
  {
    name: "Station Name",
    selector: row => row?.location,
    // sortable: true,
  },
  {
    name: "Sessions",
    selector: row => (row?.total_session).toLocaleString(),
  },
  {
    name: "Customers",
    selector: row => (row?.unique_customers).toLocaleString(),
  },
  {
    name: "Revenue",
    selector: row => (Math.ceil(row?.total_revenue * 100) / 100).toLocaleString(),
  },
]


const columns4 = [
  {
    name: "Customer ID",
    selector: row => row?.vcard,
    // sortable: true,
  },
  {
    name: "Sessions",
    selector: row => (row?.total_session).toLocaleString(),
  },
  {
    name: "Energy Used",
    selector: row => (Math.ceil(row?.total_energy * 100) / 100).toLocaleString(),
  },
  {
    name: "Revenue",
    selector: row => (Math.ceil(row?.total_revenue * 100) / 100).toLocaleString(),
  },
]

  // useEffect(() => {
  //   const access_token = localStorage.getItem('token');
  //   axios.get('https://api.pea-cpo.com/api/overviewPage', {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
  //       console.log("Overview Page respone : ", res);
  //       // console.log("Cpo Response data : ", res.data);
  //       setOverview(res?.data);
  //     }).catch((err) => {
  //       console.log("Error : ", err);
  //   })
  // }, [])


  useEffect(() => {
    const access_token = localStorage.getItem('token');
    axios.get('https://api.pea-cpo.com/api/overviewPageStatus', {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
        // console.log("Cpo request res : ", res);
        console.log("Overview page status Response : ", res.data);
        setOverview(res?.data?.data);
        // setLoader(false);
      }).catch((err) => {
        console.log("Error : ", err);
    })
  }, [flag])

  

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    axios.get('https://api.pea-cpo.com/api/overviewPageChargingList', {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
        // console.log("Cpo request res : ", res);
        console.log("Overview page Charging list Response : ", res);
        setChargingStations(res?.data?.data);
        const matrix = calculateMetrics(res?.data?.data);
        // console.log("MATRIX data : ", matrix);
        setStationMatrix(matrix);
        const matrix2 = calculateChartMatrics(res?.data?.data);
        // console.log("Matrix chaart : ", matrix2);
        setStationChartMatrix(matrix2);
        setLoader(false);
      }).catch((err) => {
        console.log("Charging list Error : ", err);
        setLoader(false);
    })
  }, [flag])

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    axios.get('https://api.pea-cpo.com/api/overviewPageTopStations', {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
        console.log("overview Page TopStations : ", res);
        // console.log("overviewPageTopStations Response : ", res);
        // console.log("overviewPageTopStations Response Data : ", res?.data);
        setTopStations(res?.data);
      }).catch((err) => {
        console.log("overviewPageTopStations Error : ", err);
    })
  }, [flag])

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    axios.get('https://api.pea-cpo.com/api/overviewPageTopCustomers', {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
        // console.log("Cpo request res : ", res);
        // console.log("overviewPageTopCustomers Response : ", res);
        // console.log("overviewPageTopCustomers Response Data : ", res?.data);
        setTopConnectors(res?.data);
      }).catch((err) => {
        console.log("overviewPageTopCustomers Error : ", err);
    })
  }, [flag])


  


  useEffect(() => {
    console.log("triggered....................")
    if(dateRange2 !== null){
      if(dateRange2?.option === 'Today' || dateRange2?.option === 'Yesterday'){
        setLoading2(true);
        console.log(dateRange2?.option);
        const chartMetrics = {
          revenue: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          chargingSessions: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          customers: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          energyUsed: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          uniqueCustomers: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          repeatCustomers: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        };
        const metrics = {
          revenue: 0,
          chargingSessions: 0,
          customers: 0,
          uniqueCustomers: 0,
          energyUsed: 0,
        };

        let customersCheckArray2 = [];

        function uniqueCustomer2(cus){
          let flg = 1;
          for (let index = 0; index < customersCheckArray2?.length; index++) {
            if(customersCheckArray2[index] === cus){
              flg = 0;
              break;
            }
          }
          customersCheckArray2.push(cus);
          return flg;
        }

        let customersCheckArray3 = [];

        function uniqueCustomer3(cus){
          let flg = 1;
          for (let index = 0; index < customersCheckArray3?.length; index++) {  
            if(customersCheckArray3[index] === cus){
              flg = 0;
              break;
            }
          }
          customersCheckArray3.push(cus);
          return flg;
        }



        for (let i = 0; i < chargingStations.length; i++) {
          const revenue = parseFloat(chargingStations[i].revenue);
          // const duration = parseFloat(chargingStations[i].duration);
          const duration = 1;
          const energy = parseFloat(chargingStations[i].totalKwh);
          const customer = chargingStations[i].email;
          const custom = 1;
          const startDate = new Date(chargingStations[i].startDate);
          // const startTime = new Date(chargingStations[i].startTime);
          const startTime = chargingStations[i].startTime;
          
          const today = new Date();


          if(dateRange2?.option === 'Today'){
            if (
              startDate.getFullYear() !== today.getFullYear() ||
              startDate.getMonth() !== today.getMonth() ||
              startDate.getDate() !== today.getDate()
            ) {
              // console.log("Wrong today");
              continue;
            }
          } else {
            let yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            if (
              startDate.getFullYear() !== yesterday.getFullYear() ||
              startDate.getMonth() !== yesterday.getMonth() ||
              startDate.getDate() !== yesterday.getDate()
            ) {
              continue;
            }
          }
          
          const hour = Number(startTime.slice(0,2));
          
          chartMetrics.revenue[hour] += revenue;
          chartMetrics.chargingSessions[hour] += duration;
          chartMetrics.customers[hour] += 1;
          chartMetrics.uniqueCustomers[hour] += uniqueCustomer3(customer);
          chartMetrics.energyUsed[hour] += energy;

          metrics.revenue += Number(revenue);
          metrics.chargingSessions += Number(duration);
          metrics.customers += 1;
          metrics.uniqueCustomers += uniqueCustomer2(customer);
          // metrics.energyUsed += item.sessions * item.kWh;
          metrics.energyUsed += Number(energy);
          
          
        }
        setStationMatrix(null);  
        setStationChartMatrix(null);
        setCheckPoint(!checkPoint);
        
        chartMetrics.repeatCustomers = [chartMetrics.customers[0] - chartMetrics.uniqueCustomers[0], 
        chartMetrics.customers[1] - chartMetrics.uniqueCustomers[1],
        chartMetrics.customers[2] - chartMetrics.uniqueCustomers[2],
        chartMetrics.customers[3] - chartMetrics.uniqueCustomers[3],
        chartMetrics.customers[4] - chartMetrics.uniqueCustomers[4],
        chartMetrics.customers[5] - chartMetrics.uniqueCustomers[5],
        chartMetrics.customers[6] - chartMetrics.uniqueCustomers[6],
        chartMetrics.customers[7] - chartMetrics.uniqueCustomers[7],
        chartMetrics.customers[9] - chartMetrics.uniqueCustomers[9],
        chartMetrics.customers[10] - chartMetrics.uniqueCustomers[10],
        chartMetrics.customers[11] - chartMetrics.uniqueCustomers[11],
        chartMetrics.customers[12] - chartMetrics.uniqueCustomers[12],
        chartMetrics.customers[13] - chartMetrics.uniqueCustomers[13],
        chartMetrics.customers[14] - chartMetrics.uniqueCustomers[14],
        chartMetrics.customers[15] - chartMetrics.uniqueCustomers[15],
        chartMetrics.customers[16] - chartMetrics.uniqueCustomers[16],
        chartMetrics.customers[18] - chartMetrics.uniqueCustomers[18],
        chartMetrics.customers[19] - chartMetrics.uniqueCustomers[19],
        chartMetrics.customers[20] - chartMetrics.uniqueCustomers[20],
        chartMetrics.customers[21] - chartMetrics.uniqueCustomers[21],
        chartMetrics.customers[22] - chartMetrics.uniqueCustomers[22],
        chartMetrics.customers[23] - chartMetrics.uniqueCustomers[23],
        ]
        
        
        // console.log("Today analytic data Matrics : ", metrics);
        // console.log("Today chart data Matrics : ", chartMetrics);
        
        
        setStationMatrix(metrics);
        setStationChartMatrix(chartMetrics);
        setTimeout(() => {
          setLoading2(false);
        }, 2000)
      } else if(dateRange2?.option === 'This Week' || dateRange2?.option === 'Last Week'){
        console.log(dateRange2?.option);
        setLoading2(true);        
        const chartMetrics = {
          revenue: [0,0,0,0,0,0,0],
          chargingSessions: [0,0,0,0,0,0,0],
          customers: [0,0,0,0,0,0,0],
          energyUsed: [0,0,0,0,0,0,0],
          uniqueCustomers: [0,0,0,0,0,0,0],
          repeatCustomers: [0,0,0,0,0,0,0],
        };

        const metrics = {
          revenue: 0,
          chargingSessions: 0,
          customers: 0,
          uniqueCustomers: 0,
          energyUsed: 0,
        };

        let customersCheckArray2 = [];

        function uniqueCustomer2(cus){
          let flg = 1;
          for (let index = 0; index < customersCheckArray2?.length; index++) {
            if(customersCheckArray2[index] === cus){
              flg = 0;
              break;
            }
          }
          customersCheckArray2.push(cus);
          return flg;
        }

        let customersCheckArray3 = [];

        function uniqueCustomer3(cus){
          let flg = 1;
          for (let index = 0; index < customersCheckArray3?.length; index++) {  
            if(customersCheckArray3[index] === cus){
              flg = 0;
              break;
            }
          }
          customersCheckArray3.push(cus);
          return flg;
        }
        
        let count = 1;
        for (let i = 0; i < chargingStations.length; i++) {
          const revenue = parseFloat(chargingStations[i].revenue);
          const duration = 1;
          const energy = parseFloat(chargingStations[i].totalKwh);
          const customer = chargingStations[i].email;
          const custom = 1;
          const startDate = new Date(chargingStations[i].startDate);
          // const startTime = new Date(chargingStations[i].startTime);
          const startTime = chargingStations[i].startTime;
          
          const today = new Date();

          const thisWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
          const thisWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 6);

          if(dateRange2?.option === 'This Week'){
            if (startDate >= thisWeekStart && startDate <= thisWeekEnd) {
              // console.log("matched. ");
            } else {
              // console.log("Wrong");
              continue;
            }
          } else {
            const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

            // Get the start and end dates of last week
            const lastWeekStart = new Date(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate() - lastWeek.getDay() + 1);
            const lastWeekEnd = new Date(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate() - lastWeek.getDay() + 7);

            // Format the dates as strings
            // const lastWeekStartDateString = lastWeekStart.toISOString().slice(0, 10);
            // const lastWeekEndDateString = lastWeekEnd.toISOString().slice(0, 10);

            if (startDate >= lastWeekStart && startDate <= lastWeekEnd){
              // console.log("Last Week matched")
            } else {
              // console.log("Wrong yesterday");
              continue;
            }
          }
          
          // const month3 = String(startDate.getDay() + 1).padStart(2, '0');
          const day3 = startDate.getDay(); 
          // console.log("Start date : ", startDate);
          // console.log("week day : : ", day3)
 
          // console.log("Index  : ", i)
          // console.log("counter  : ", count);
          count++;
          
          const hour = Number(startTime.slice(0,2));
          chartMetrics.revenue[day3] += revenue;
          chartMetrics.chargingSessions[day3] += duration;
          chartMetrics.customers[day3] += 1;
          chartMetrics.uniqueCustomers[day3] += uniqueCustomer3(customer);
          chartMetrics.energyUsed[day3] += energy;

          metrics.revenue += Number(revenue);
          metrics.chargingSessions += Number(duration);
          metrics.customers += 1;
          metrics.uniqueCustomers += uniqueCustomer2(customer);
          metrics.energyUsed += Number(energy);
          
          
        }
        setStationMatrix(null);  
        setStationChartMatrix(null);
        setCheckPoint(!checkPoint);


        chartMetrics.repeatCustomers = [
          chartMetrics.customers[0] - chartMetrics.uniqueCustomers[0], 
          chartMetrics.customers[1] - chartMetrics.uniqueCustomers[1],
          chartMetrics.customers[2] - chartMetrics.uniqueCustomers[2],
          chartMetrics.customers[3] - chartMetrics.uniqueCustomers[3],
          chartMetrics.customers[4] - chartMetrics.uniqueCustomers[4],
          chartMetrics.customers[5] - chartMetrics.uniqueCustomers[5],
          chartMetrics.customers[6] - chartMetrics.uniqueCustomers[6],
        ]
        
        
        console.log("Today analytic data Matrics : ", metrics);
        console.log("Today chart data Matrics : ", chartMetrics);
        
        
        setStationMatrix(metrics);
        setStationChartMatrix(chartMetrics)
        setTimeout(() => {
          setLoading2(false);
        }, 2000)

      } else if(dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Month'){
        setLoading2(true);

        console.log("This Month true ")

        const date2 = new Date();

        const yearc = date2.getFullYear();
        const monthc = date2.getMonth();

        // Get the last day of the month
        const lastDayc = new Date(yearc, monthc + 1, 0);

        // Get the number of days in the month
        const numDaysc = lastDayc.getDate();

        // console.log("Number of days in current month", numDaysc); // Outputs the number of days in the current month

        // const arr28 = [10,10,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
        // const arr29 = [10,10,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
        // const arr30 = [10,10,10,10,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
        // const arr31 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

        const chartMetrics = {
          revenue: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          chargingSessions: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          customers: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          energyUsed: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          uniqueCustomers: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          repeatCustomers: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        };
        // console.log("arr 30 : ", arr30)
        console.log("chart metrics : ", chartMetrics)

        const metrics = {
          revenue: 0,
          chargingSessions: 0,
          customers: 0,
          uniqueCustomers: 0,
          energyUsed: 0,
        };

        let customersCheckArray2 = [];

        function uniqueCustomer2(cus){
          let flg = 1;
          for (let index = 0; index < customersCheckArray2?.length; index++) {
            if(customersCheckArray2[index] === cus){
              flg = 0;
              break;
            }
          }
          customersCheckArray2.push(cus);
          return flg;
        }

        let customersCheckArray3 = [];

        function uniqueCustomer3(cus){
          let flg = 1;
          for (let index = 0; index < customersCheckArray3?.length; index++) {  
            if(customersCheckArray3[index] === cus){
              flg = 0;
              break;
            }
          }
          customersCheckArray3.push(cus);
          return flg;
        }


        let count = 1;
        for (let i = 0; i < chargingStations.length; i++) {
          const revenue = parseFloat(chargingStations[i].revenue);
          const duration = 1;
          const energy = parseFloat(chargingStations[i].totalKwh);
          const customer = chargingStations[i].email;
          const custom = 1;
          // console.log("Start date : ", chargingStations[i].startDate);
          const startDate = new Date(chargingStations[i].startDate);
          // const startTime = new Date(chargingStations[i].startTime);
          const startTime = chargingStations[i].startTime;
          
          const date = new Date();
          const today = new Date();
          const year = date.getFullYear();
          const month = date.getMonth();

          // Get the first day of the month
          const firstDay = new Date(year, month, 1);

          // Get the last day of the month
          const lastDay = new Date(year, month + 1, 0);

          // Format the dates as YYYY-MM-DD
          const mstartDate = `${year}-${(month + 1).toString().padStart(2, '0')}-01`;
          const mendDate = `${year}-${(month + 1).toString().padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`;

          // console.log("month start date :", mstartDate); // Outputs the start date of the month in YYYY-MM-DD format
          // console.log("month end date :", mendDate); // Outputs the end date of the month in YYYY-MM-DD format
          // console.log("trans date --- ", startDate.toISOString().slice(0, 10));
          // console.log("trans date --- ", startDate);
          const stingStartDate = startDate.toISOString().slice(0, 10);

          const monthStart = new Date(dateRange2?.startDate);
          const monthEnd = new Date(dateRange2?.endDate);

          if(dateRange2?.option === 'This Month'){
            if (stingStartDate >= mstartDate && stingStartDate <= mendDate) {
            // if (startDate >= monthStart && startDate <= monthEnd) {
              // console.log("matched. ");
            } else {
              // console.log("Wrong");
              continue;
            }
          } 
          else {

          //   console.log("month starting date : ", dateRange2?.startDate)
          //   console.log("month starting date : ", dateRange2?.endDate)
          // console.log("Start date : ", startDate);

            
            if (stingStartDate >= dateRange2?.startDate && stingStartDate <= dateRange2?.endDate){
              // console.log("Last Month matched")
            } else {
              continue;
            }
          }
          
          // const month3 = String(startDate.getDay() + 1).padStart(2, '0');
          const day3 = startDate.getDate() - 1; 
          // console.log("Start date : ", startDate);
          // console.log("week day : : ", day3)
 
          // console.log("Index  : ", i)
          // console.log("counter  : ", count);
          count++;
          
          // const hour = Number(startTime.slice(0,2));
          // console.log("chart metrics 4 : ", chartMetrics)
          // console.log("rev : ", revenue, day3)
          chartMetrics.revenue[day3] = chartMetrics.revenue[day3] + revenue;
          chartMetrics.chargingSessions[day3] += 1;
          chartMetrics.customers[day3] += 1;
          chartMetrics.uniqueCustomers[day3] += uniqueCustomer3(customer);
          chartMetrics.energyUsed[day3] += energy;
          
          // console.log("chart metrics 4: ", chartMetrics)
          metrics.revenue += Number(revenue);
          metrics.chargingSessions += Number(duration);
          metrics.customers += 1;
          metrics.uniqueCustomers += uniqueCustomer2(customer);
          metrics.energyUsed += Number(energy);
          
          
        }
        setStationMatrix(null);  
        setStationChartMatrix(null);
        setCheckPoint(!checkPoint);

        console.log("chart metrix : ", chartMetrics)
        
        for(let a = 0; a < numDaysc; a++){
          chartMetrics.repeatCustomers[a] = chartMetrics.customers[a] - chartMetrics.uniqueCustomers[a];
        }
        
        console.log("chart metrix 2 : ", chartMetrics)
        // console.log("Today analytic data Matrics : ", metrics);
        // console.log("Today chart data Matrics : ", chartMetrics);
        
        setStationMatrix(metrics);
        setStationChartMatrix(chartMetrics)
        
        setTimeout(() => {
          setLoading2(false);
        }, 2000)

      } else if(dateRange2?.option === "Custom Range"){

        setLoading2(true);


        const dateArray = [];

        const chartMetrics = {
          revenue: [],
          chargingSessions: [],
          customers: [],
          energyUsed: [],
          uniqueCustomers: [],
          repeatCustomers: [],
        };
        
        console.log("chart metrics : ", chartMetrics);

        const metrics = {
          revenue: 0,
          chargingSessions: 0,
          customers: 0,
          uniqueCustomers: 0,
          energyUsed: 0,
        };

        let customersCheckArray4 = [];

        function uniqueCustomer4(cus){
          let flg = 1;
          for (let index = 0; index < customersCheckArray4?.length; index++) {  
            if(customersCheckArray4[index] === cus){
              flg = 0;
              break;
            }
          }
          customersCheckArray4.push(cus);
          return flg;
        }

        let customersCheckArray5 = [];

        function uniqueCustomer5(cus){
          let flg = 1;
          for (let index = 0; index < customersCheckArray5?.length; index++) {  
            if(customersCheckArray5[index] === cus){
              flg = 0;
              break;
            }
          }
          customersCheckArray5.push(cus);
          return flg;
        }

        const start = new Date(dateRange2.startDate);
        const end = new Date(dateRange2?.endDate);

        // Initialize filteredData for each day between startDate and endDate
        
        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
          const dateString = d.toISOString().split('T')[0];
          // console.log("datee d d : ", d)
          // console.log("datee d d : ", dateString);
          dateArray?.push(dateString);
          chartMetrics?.revenue?.push(0);
          chartMetrics?.chargingSessions?.push(0);
          chartMetrics?.customers?.push(0);
          chartMetrics?.energyUsed?.push(0);
          chartMetrics?.uniqueCustomers?.push(0);
          chartMetrics?.repeatCustomers?.push(0);
          
        }


        console.log("chart metrics : ", chartMetrics);

        

        for (let i = 0; i < chargingStations.length; i++) {
          const revenue = parseFloat(chargingStations[i]?.revenue);
          const duration = 1;
          const energy = parseFloat(chargingStations[i]?.totalKwh);
          const customer = chargingStations[i]?.email;
          const custom = 1;
          // console.log("Start date : ", chargingStations[i].startDate);
          const startDate = new Date(chargingStations[i]?.startDate);
          // const startTime = new Date(chargingStations[i].startTime);
          const startTime = chargingStations[i]?.startTime;
        
          const stingStartDate = startDate.toISOString().slice(0, 10);
          
          if (stingStartDate >= dateRange2?.startDate && stingStartDate <= dateRange2?.endDate){
            // console.log("Last Month matched")
          } else {
            continue;
          }

          const getIndex = dateArray?.indexOf(chargingStations[i].startDate);

          console.log(`Index of ${chargingStations[i]?.startDate} :` , getIndex);

          chartMetrics.revenue[getIndex] = chartMetrics.revenue[getIndex] + revenue;
          chartMetrics.chargingSessions[getIndex] += 1;
          chartMetrics.customers[getIndex] += 1;
          chartMetrics.uniqueCustomers[getIndex] += uniqueCustomer4(customer);
          chartMetrics.energyUsed[getIndex] += energy;
          
          // console.log("chart metrics 4: ", chartMetrics)
          metrics.revenue += parseFloat(revenue);
          metrics.chargingSessions += parseFloat(duration);
          metrics.customers += 1;
          metrics.uniqueCustomers += uniqueCustomer5(customer);
          metrics.energyUsed += parseFloat(energy);
        
        
        }
        

        // chartMetrics.repeatCustomers = [
        //   chartMetrics.customers[0] - chartMetrics.uniqueCustomers[0], 
        //   chartMetrics.customers[1] - chartMetrics.uniqueCustomers[1],
        //   chartMetrics.customers[2] - chartMetrics.uniqueCustomers[2],
        //   chartMetrics.customers[3] - chartMetrics.uniqueCustomers[3],
        //   chartMetrics.customers[4] - chartMetrics.uniqueCustomers[4],
        //   chartMetrics.customers[5] - chartMetrics.uniqueCustomers[5],
        //   chartMetrics.customers[6] - chartMetrics.uniqueCustomers[6],
        // ]
        
        for(let a = 0; a < dateArray?.length; a++){
          chartMetrics.repeatCustomers[a] = chartMetrics?.customers[a] - chartMetrics?.uniqueCustomers[a];
        }
        
        setStationMatrix(null);  
        setStationChartMatrix(null);
        setCheckPoint(!checkPoint);
        
        console.log("chart metrix 2 : ", chartMetrics)
        // console.log("Today analytic data Matrics : ", metrics);
        // console.log("Today chart data Matrics : ", chartMetrics);
        setLabels(dateArray);     
        setStationMatrix(metrics);
        setStationChartMatrix(chartMetrics)
      
        setTimeout(() => {
          setLoading2(false);
        }, 2000)

      } else {
        setLoading2(true);
        setStationMatrix(null);
        setStationChartMatrix(null);
        setCheckPoint(!checkPoint);
        const matrix = calculateMetrics(chargingStations, dateRange2?.option);
        // console.log("MATRIX data : ", matrix);
        setStationMatrix(matrix);
        const matrix2 = calculateChartMatrics(chargingStations, dateRange2?.option);
        // console.log("Matrix chaart : ", matrix2);
        setStationChartMatrix(matrix2);
        setTimeout(() => {
          setLoading2(false);
        }, 2000)
      }
    }
  }, [dateRange2])





  

  







  // console.log("Date Range two : ", dateRange2);


  const calculateChartMatrics = (dataa, type) => {
    const metrics = {
      revenue: [0,0,0,0,0,0,0,0,0,0,0,0], 
      chargingSessions: [0,0,0,0,0,0,0,0,0,0,0,0],
      customers: [0,0,0,0,0,0,0,0,0,0,0,0],
      uniqueCustomers: [0,0,0,0,0,0,0,0,0,0,0,0],
      repeatCustomers: [0,0,0,0,0,0,0,0,0,0,0,0],
      energyUsed: [0,0,0,0,0,0,0,0,0,0,0,0],
    };

    let customersCheckArray = [];

    function uniqueCustomer(cus){
      let flg = 1;
      for (let index = 0; index < customersCheckArray?.length; index++) {
        if(customersCheckArray[index] === cus){
          flg = 0;
          break;
        }
      }
      customersCheckArray.push(cus);
      return flg;
    }
    
    for (let i = 0; i < dataa.length; i++) {
      const revenue = parseFloat(dataa[i].revenue);
      const duration = parseFloat(dataa[i].duration);
      const energy = parseFloat(dataa[i].totalKwh);
      const customer = dataa[i].email;
      const startDate = new Date(dataa[i].startDate);

      const stingStartDate = startDate.toISOString().slice(0, 10);


      if(type === 'Last Year'){
        if(stingStartDate >= dateRange2?.startDate && stingStartDate <= dateRange2?.endDate){
          // console.log("Matched last year ");
          // console.log("Matched last year ");
          // console.log("Matched last year ");
          // console.log("Matched last year ");
          // console.log("Matched last year ");
          // console.log("Matched last year ");
        } else {
          continue;
        }
      } else {
        if (startDate.getFullYear() !== new Date().getFullYear()) {
          continue;
        } 
      }
      
      
      const month = startDate.getMonth();
      metrics.revenue[month] += revenue;
      metrics.chargingSessions[month] += 1;
      metrics.uniqueCustomers[month] += uniqueCustomer(customer);
      metrics.customers[month] += 1;
      metrics.energyUsed[month] += energy;
    }
    metrics.repeatCustomers = [metrics.customers[0] - metrics.uniqueCustomers[0], 
    metrics.customers[1] - metrics.uniqueCustomers[1],
    metrics.customers[2] - metrics.uniqueCustomers[2],
    metrics.customers[3] - metrics.uniqueCustomers[3],
    metrics.customers[4] - metrics.uniqueCustomers[4],
    metrics.customers[5] - metrics.uniqueCustomers[5],
    metrics.customers[6] - metrics.uniqueCustomers[6],
    metrics.customers[7] - metrics.uniqueCustomers[7],
    metrics.customers[9] - metrics.uniqueCustomers[9],
    metrics.customers[10] - metrics.uniqueCustomers[10],
    metrics.customers[11] - metrics.uniqueCustomers[11],
    ]
    return metrics;
  }

  const calculateMetrics = (dataa, type) => {
    // const { data } = this.state;
    const metrics = {
      revenue: 0,
      chargingSessions: 0,
      customers: 0,
      energyUsed: 0,
      uniqueCustomers: 0,
      pea_users: 0,
    };

    let customersCheckArray = [];

    function uniqueCustomer(cus){
      let flg = 1;
      for (let index = 0; index < customersCheckArray?.length; index++) {
        if(customersCheckArray[index] === cus){
          flg = 0;
          break;
        }
      }
      customersCheckArray.push(cus);
      return flg;
    }

    // dataa && dataa.forEach((item) => {
    //   // metrics.revenue += item.price * item.sessions;revenue
    //   metrics.revenue += Number(item.revenue);
    //   // metrics.chargingSessions += Number(item.duration);
    //   metrics.chargingSessions += 1;
    //   metrics.uniqueCustomers += uniqueCustomer(item.email);
    //   metrics.customers += 1;
    //   // metrics.energyUsed += item.sessions * item.kWh;
    //   metrics.energyUsed += Number(item.totalKwh);
    // });

    for(let c = 0; c < dataa?.length; c++) {

      const startDate = new Date(dataa[c].startDate);
      const stingStartDate = startDate.toISOString().slice(0, 10);


      if(type === "Last Year"){
        if(stingStartDate >= dateRange2?.startDate && stingStartDate <= dateRange2?.endDate){
          // console.log("Matched last year ");
          // console.log("Matched last year ");
          // console.log("Matched last year ");
          // console.log("Matched last year ");
          // console.log("Matched last year ");
          // console.log("Matched last year ");
        } else {
          continue;
        }
      } else {
        if (startDate.getFullYear() !== new Date().getFullYear()) {
          continue;
        } 
      }

      metrics.revenue += Number(dataa[c].revenue);
      metrics.chargingSessions += 1;
      metrics.uniqueCustomers += uniqueCustomer(dataa[c].email);
      metrics.customers += 1;
      metrics.energyUsed += Number(dataa[c].totalKwh);
    };


    return metrics;
  };

  // updateMetricFilter = (event) => {
  //   const { value } = event.target;
  //   this.setState({ metricFilter: value });
  // };

  // updateDateRangeFilters = (event) => {
  //   const { name, value } = event.target;
  //   this.setState((prevState) => ({
  //     dateRangeFilters: {
  //       ...prevState.dateRangeFilters,
  //       [name]: value,
  //     },
  //   }));
  // };

  console.log("DAte reange two : ", dateRange2);


 
  



   



  if(loader){
    return (
      <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '37vh'}}>
        <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#814097"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
        />
      </div>
    )
  } else {
  return (
    <>
      <div className='overview d-flex justify-content-between'>
        <div className='ov-heading-div'>
              <h3 className='ov-heading'>Overview</h3>
              <p className='ov-detail'>The managing and operating system for EV charging stations.</p>
        </div>
        <div className='comp-info-card d-flex'>
          <div>
            {user?.CompanyProfile?.ComparyLogo ? (
              <>
                <img src={user?.CompanyProfile?.ComparyLogo} width="48px" height="48px" alt="" />
                {/* <img src={vectorIcon} width="48px" height="48px" alt="" /> */}
              </>
            ) : (
              <img src={vectorIcon} width="48px" height="48px" alt="" />
            )}
          </div>
          <div className='d-inline-block text-center name-card-div'>
            <h6 className='font-cname-title mb-0'>Company Name</h6>
            {/* <h6 className='font-cname'>{user?.CompanyProfile?.ProviderName ? user?.CompanyProfile?.ProviderName : <Spinner animation="grow" size="md" variant="secondary" />}</h6> */}
            <h6 className='font-cname'>{ user?.CompanyProfile?.ProviderName}</h6>
          </div>
          <div className='pkg-card text-center'>
            <h6 className='font-pkg-title mb-0'>Current Package</h6>
            <h6 className='font-pkg'>{user?.Subscription}</h6>
          </div>
          <div className=' text-center'>
            <h6 className='font-pkg-title mb-0'>Total Charging station</h6>
            <h6 className='font-pkg'>{user?.StationCount}</h6>
          </div>
        </div>
      </div>

      <div className='w-100 d-flex justify-content-end' style={{padding: '0px 32px', paddingBottom: '32px'}}>
        <div className='toggles-graph mt-0'>
          <ul className='graphs-ul mt-0'>
            <li className={tog2 === 1 ? 'graph-active-li' : 'unactive'}
            onClick={() => setTog2(1)}
            >Stations</li>
            <li className={tog2 === 2 ? 'graph-active-li' : 'unactive'}
            onClick={() => setTog2(2)}
            >Connectors</li>
          </ul>
        </div>
        
      </div>
      
      {tog2 === 1 ? (
        <div className='w-100 cards-wrapper d-flex'>
          <div className='cards d-flex'>
            <div>
              <img src={ellipseIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Available</h6>
              <h6 className='font-pkg-title mb-0'>{overview?.StationsByStatus?.AvailableChargers}</h6>
            </div>
          </div>
          <div className='cards d-flex'>
            <div>
              <img src={groupIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Charging</h6>
              <h6 className='font-pkg-title mb-0'>{overview?.StationsByStatus?.OccupiedChargers}</h6>
            </div>
          </div>
          <div className='cards d-flex'>
            <div>
              <img src={penMarkerIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Reserved</h6>
              <h6 className='font-pkg-title mb-0'>0</h6>
            </div>
          </div>
          <div className='cards d-flex'>
            <div>
              <img src={grayPenMarkerIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Offline</h6>
              <h6 className='font-pkg-title mb-0'>{overview?.StationsByStatus?.OfflineChargers}</h6>
            </div>
          </div>
          <div className='cards d-flex'>
            <div>
              <img src={symbolIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Faulted</h6>
              <h6 className='font-pkg-title mb-0'>{overview?.StationsByStatus?.FaultedChargers}</h6>
            </div>
          </div>
        </div>
      ) : (
        <div className='w-100 cards-wrapper d-flex'>
          <div className='cards d-flex'>
            <div>
              <img src={ellipseIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Available</h6>
              <h6 className='font-pkg-title mb-0'>{overview?.connectorsByStatus?.AvailableChargers}</h6>
            </div>
          </div>
          <div className='cards d-flex'>
            <div>
              <img src={groupIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Charging</h6>
              <h6 className='font-pkg-title mb-0'>{overview?.connectorsByStatus?.OccupiedChargers}</h6>
            </div>
          </div>
          {/* <div className='cards d-flex'>
            <div>
              <img src={penMarkerIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Reserved</h6>
              <h6 className='font-pkg-title mb-0'>23</h6>
            </div>
          </div> */}
          <div className='cards d-flex'>
            <div>
              <img src={grayPenMarkerIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Offline</h6>
              <h6 className='font-pkg-title mb-0'>{overview?.connectorsByStatus?.OfflineChargers}</h6>
            </div>
          </div>

          <div className='cards d-flex'>
            <div>
              <img src={symbolIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Faulted</h6>
              <h6 className='font-pkg-title mb-0'>0</h6>
            </div>
          </div>

          {/* <div className='cards d-flex'>
            <div>
              <img src={symbolIcon} width="32px" height="32px" alt="" />
            </div>
            <div className=''>
              <h6 className='font-pkg mb-0'>Maintenance</h6>
              <h6 className='font-pkg-title mb-0'>{overview?.StationsByStatus?.FaultedChargers}</h6>
            </div>
          </div> */}
        </div>

      )}


      <div className='w-100 d-flex section-3rd'>
        <div className='w-75'>
          <h5 className='font-style201 mb-0'>Statistics</h5>
          <h6 className='font-style202 mb-0'>สถิติภาพรวมข้อมูลสถานีชาร์จของฉัน</h6>
        </div>
        <DatePicker dateRange={dateRange2} setDateRange={setDateRange2} />
      </div>
      {loading2 ? (
        <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '30vh', paddingBottom: '30vh'}}>
          <BallTriangle
              height={100}
              width={100}
              radius={5}
              color="#814097"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
          />
        </div>
      ) : (
        <>
          <DashGraph customLabels={labels} statistics={stationMatrix} datee={dateRange2} chartData={stationChartMatrix} type={dateRange2?.option === 'Today' ? 'Today' : dateRange2?.option === 'Yesterday' ? 'Yesterday' : dateRange2?.option === 'This Week' ? 'This Week' : dateRange2?.option === 'Last Week' ? 'Last Week' : dateRange2?.option === 'This Month' ? 'This Month' : dateRange2?.option === 'Last Month' ? 'Last Month' : dateRange2?.option === 'Custom Range' ? 'Custom Range'  : 'Year' } />
          {/* <DashGraph /> */}
          {checkPoint === false && (
            <CustomersOV customLabels={labels} statistics={stationMatrix} datee={dateRange2} chartData={{uniqueCustomers: stationChartMatrix?.uniqueCustomers, repeatCustomers: stationChartMatrix?.repeatCustomers}}  />
          )}
          {checkPoint === true  && (
            <CustomersOV customLabels={labels} statistics={stationMatrix} datee={dateRange2} chartData={{uniqueCustomers: stationChartMatrix?.uniqueCustomers, repeatCustomers: stationChartMatrix?.repeatCustomers}}  />
          )}
        </>
      )}
      
      

      {/* <StackedGraph /> */}
      
      {/* <div className='w-100  d-flex' style={{padding: '16px 32px'}}>
        <div className='w-25'><h4>Filter by Date & Time</h4></div>
        
        <div className='w-75  justify-content-end d-flex'>
            <div className='search-field d-inline-block pe-2' style={{width: '40%'}}>
                <input type="date" placeholder="Search by Name" />
            </div>
            <div className='search-field d-inline-block ps-2' style={{width: '40%'}}>
                <input type="time" placeholder="Search by Address" />
            </div>
        </div>
      </div> */}

      <div className='table-div-home'>
      {overview?.errorMsgs ? (
        <DataTableBase 
          title="Error Message of Station"
          columns={columns}
          data={overview?.errorMsgs}
          fixedHeader
          fixedHeaderScrollHeight="289px"
        />
      ) : (
        <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '2vh'}}>
        <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#814097"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
        />
      </div>
      )}
        
      </div>
      
      <div className='table-div-home'>
        {/* <Table lateastTransactions={overview?.lateastTransactions} columns={columns2} /> */}
      {overview?.lateastTransactions ? (
        <DataTableBase 
          title="Latest Transaction"
          columns={columns2}
          data={overview?.lateastTransactions}
          fixedHeader
          fixedHeaderScrollHeight="289px"
          
        />
      ) : (
        <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '2vh'}}>
        <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#814097"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
        />
      </div>
      )}

      </div>
      {/* <div className='table-div-home'>
        <DataTableBase 
          title="Latest Transaction"
          columns={columns2}
          data={data2}
        />
      </div> */}
      <div className='table2-wrapper-div'>
        <Row>
          <Col md={6}>
          <div className='table-div-home-left'>
            <DataTableBase 
              title={<CustomTableHeader setFilter={setFilter} title="Top 5 Stations" time={filter === "Month" ? getCurrentMonth() : filter === "Year" ? getCurrentYear() : getCurrentWeekDates()} />}
              columns={columns3}
              data={filter === 'Month'? topStations?.stationsMonth : filter === 'Year' ? topStations?.stationsYear : topStations?.stationsWeek}
            />
          </div>
          </Col>
          <Col md={6}>
            <div className='table-div-home-right'>
              <DataTableBase 
                title={<CustomTableHeader setFilter={setFilter2} title="Top 10 Users" time={filter2 === "Month" ? getCurrentMonth() : filter2 === "Year" ? getCurrentYear() : getCurrentWeekDates() } />}
                columns={columns4}
                data={filter2 === 'Month'? topConnectors?.stationsMonth : filter2 === 'Year' ? topConnectors?.stationsYear : topConnectors?.stationsWeek}
                fixedHeader
                fixedHeaderScrollHeight="289px"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  )}
}


export default Overview
