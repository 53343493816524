import React from 'react'
import '../style/Profile.css'

export default function Notification() {
  return (
    <div className='station-overview'>
      <div className='overview-section-heading'>
          <h3>Notification settings</h3>
          <p>We may still send you important notifications about your account outside of your notification settings.</p>
      </div>
      <div className='overview-section'>  
        <div className='station-field notification-field'>
            <div className='station-field-div1'>
                <h5>Feedback from customers</h5>
                <p>These are notifications to remind you of feedback from your customers.</p>
            </div>
            <div className='station-field-div2' style={{width:'35%'}}>
                <div className='notification-toggle'>
                    <input type="checkbox" class="toggle-station"/>
                    Push
                </div>
                <div className='notification-toggle'>
                    <input type="checkbox" class="toggle-station"/>
                    Email
                </div>
            </div>
        </div>
        <div className='station-field notification-field'>
            <div className='station-field-div1'>
                <h5>Charger Status</h5>
                <p>These are notifications for your charger(s), when charger status is changed.</p>
            </div>
            <div className='station-field-div2' style={{width:'35%'}}>
                <div className='notification-toggle'>
                    <input type="checkbox" class="toggle-station"/>
                    Push
                </div>
                <div className='notification-toggle'>
                    <input type="checkbox" class="toggle-station"/>
                    Email
                </div>
            </div>
        </div>
        <div className='station-field notification-field'>
            <div className='station-field-div1'>
                <h5>Charger Setting</h5>
                <p>These are notifications for your charger(s), when charger setting is updated.</p>
            </div>
            <div className='station-field-div2' style={{width:'35%'}}>
                <div className='notification-toggle'>
                    <input type="checkbox" class="toggle-station"/>
                    Push
                </div>
                <div className='notification-toggle'>
                    <input type="checkbox" class="toggle-station"/>
                    Email
                </div>
            </div>
        </div>
        <div className='station-field notification-field'>
            <div className='station-field-div1'>
                <h5>Update and News Letter</h5>
                <p>Receive the latest news and updates from us.</p>
            </div>
            <div className='station-field-div2' style={{width:'35%'}}>
                <div className='notification-toggle'>
                    <input type="checkbox" class="toggle-station"/>
                    Push
                </div>
                <div className='notification-toggle'>
                    <input type="checkbox" class="toggle-station"/>
                    Email
                </div>
            </div>
        </div>
      </div>
      <div className='station-setting-btns' style={{marginTop:'3rem'}}>
        <button className='cancel-btn'>Cancel</button>
        <button className='save-btn'>Save Changes</button>
      </div>
    </div>
  )
}
