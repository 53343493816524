import axios from 'axios';
import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { useParams } from 'react-router-dom';

export default function StationControl() {
    const [maintenance1, setMaintenance1] = useState(false);
    const [firstConn1, setFirstConn1] = useState(true);
    const [secondConn1, setSecondConn1] = useState(null);
    const [station, setStation] = useState(null);

    const [showAlert, setShowAlert] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);

    const {id} = useParams();

    useEffect(() => {
        const token = localStorage.getItem('token');

        axios.get(`https://api.pea-cpo.com/api/getChargerListCpoById/${id}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("Get Charger detail : ", res);
            
            setStation(res?.data?.data[0]);

        }).catch((err) =>{
            console.log("Error get user : ", err);
        })
    }, [])


  return (
    <div className='station-overview'> 
        {showAlert && (
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Soft Reboot"
                confirmBtnBsStyle="soft-boot"
                cancelBtnBsStyle="secondary"
                title="Are you sure?"
                onConfirm={() => setShowAlert(false)}
                onCancel={() => setShowAlert(false)}
                focusCancelBtn
            >
                {/* You will not be able to recover this imaginary file! */}
            </SweetAlert>
        )}

        {showAlert2 && (
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Hard Reboot"
                confirmBtnBsStyle="hard-boot"
                cancelBtnBsStyle="secondary"
                title="Are you sure?"
                onConfirm={() => setShowAlert2(false)}
                onCancel={() => setShowAlert2(false)}
                focusCancelBtn
            >
                {/* You will not be able to recover this imaginary file! */}
            </SweetAlert>
        )}

        <div className='overview-section-heading'>
            <h4>Control Charger Status</h4>
            <p>Select mode to control station status.</p>
        </div>
        <div className='overview-section'>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger Detail</h5>
                </div>
                <div>
                    <p>Charger name</p>
                    <input className='form-control' readOnly defaultValue={station?.locationTH} placeholder='Charger 1'/>
                    <div className='station-field-location'>
                        <div>
                            <p>Charger ID</p>
                            <input className='form-control' readOnly defaultValue={station?.chargerID} placeholder='T54-HU1-3220-008'/>
                        </div>
                        <div>
                            <p>Charger Number</p>
                            <input className='form-control' readOnly defaultValue={station?.number} placeholder='P101'/>
                        </div>
                    </div>
                    <div className='station-field-location'>
                        <div>
                            <p>Brand</p>
                            <input className='form-control' readOnly placeholder='ABB'/>
                        </div>
                        <div>
                            <p>Model</p>
                            <input className='form-control' readOnly placeholder='TERRA CJG-54'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='station-field'>
                <div className='station-field-div1'>
                    <h5>Charger Control</h5>
                </div>
                <div className='station-connector'>
                    <div className='station-connector-details' style={{marginBottom:'0rem'}}>
                        <h5>Reserved</h5>
                        <div className='switch-btn-div'>
                            <input type="checkbox" disabled class="toggle-station"/>
                            <span>Off</span>
                        </div>
                    </div>
                    <div className='station-connector-details'>
                        <h5>Maintenance</h5>
                        <div className='switch-btn-div'>
                            <input type="checkbox" defaultChecked={false} onChange={(e) => setMaintenance1(e.target.checked)} name="maintenance" value={true} class="toggle-station"/>
                            {maintenance1 ? (
                                <span className='text-successsdf'>On</span>
                            ) : (
                                <span className='text-secondary'>Off</span>
                            )}

                        </div>
                    </div>







                    {station?.connectors && station?.connectors.map((item, index) => (
                        <div className='station-connector-section'>
                            <div>
                                <div style={{display:'flex', marginBottom:'0rem'}}>
                                    <h6>{item?.connectorType}</h6>
                                    {item?.connectorStatusCode === "Available" ? (
                                        <div style={{marginLeft:'5px'}} className='status'>
                                            Available
                                        </div>
                                    ) : item?.connectorStatusCode === "Charging" ? (
                                        <div style={{marginLeft:'5px', background:'#175CD3'}} className='status'>
                                            Charging
                                        </div>
                                    ) : (
                                        <div style={{marginLeft:'5px', background:'#C6C6C6'}} className='status text-dark'>
                                            {item?.connectorStatusCode}
                                        </div>                                        
                                    )}
                                </div>
                                <p>Type: {item?.PowerType} <br/> Rated Power: {item?.kW} kW</p>
                            </div>
                            
                            <div className='switch-btn-div'>
                                <input type="checkbox" name='first-conn1' defaultChecked={true} onChange={(e) => setFirstConn1(e.target.checked)} value={true} class="toggle-station"/>
                                {firstConn1 ? (
                                    <span className='text-q'>On</span>
                                ) : (
                                    <span className='text-secondary'>Off</span>
                                )}
                            </div>
                        </div>
                    ))}
                    {/* <div className='station-connector-section'>
                        <div>
                            <div style={{display:'flex', marginBottom:'0rem'}}>
                                <h6>CCS 2</h6>
                                <div style={{marginLeft:'5px', background:'#175CD3'}} className='status'>
                                    Charging
                                </div>
                            </div>
                            <p>Type: DC <br/> Rated Power: 50 kW</p>
                        </div>
                        <div className='switch-btn-div'>
                            <input type="checkbox" defaultChecked={true} onChange={(e) => setSecondConn1(e.target.checked)} class="toggle-station"/>
                            {secondConn1? (
                                <span className='text-successx'>On</span>
                            ) : (
                                <span className='text-secondary'>Off</span>
                            )}
                        </div>
                    </div> */}
                    <div className='station-connector-section-buttons'>
                        <button onClick={() => setShowAlert(true)}>
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.16536 15.457C4.48481 15.2487 3.09259 14.5159 1.9887 13.2587C0.884253 12.002 0.332031 10.5265 0.332031 8.83203C0.332031 7.91536 0.512587 7.03675 0.873698 6.1962C1.23481 5.3562 1.7487 4.6237 2.41536 3.9987L3.60286 5.1862C3.07509 5.65842 2.67592 6.20703 2.40536 6.83203C2.13425 7.45703 1.9987 8.1237 1.9987 8.83203C1.9987 10.0543 2.38759 11.134 3.16536 12.0712C3.94314 13.009 4.94314 13.582 6.16536 13.7904V15.457ZM7.83203 15.457V13.7904C9.04036 13.5681 10.0368 12.9918 10.8212 12.0612C11.6062 11.1306 11.9987 10.0543 11.9987 8.83203C11.9987 7.44314 11.5126 6.26259 10.5404 5.29036C9.56814 4.31814 8.38759 3.83203 6.9987 3.83203H6.9362L7.85286 4.7487L6.6862 5.91536L3.76953 2.9987L6.6862 0.0820312L7.85286 1.2487L6.9362 2.16536H6.9987C8.85981 2.16536 10.4362 2.8112 11.7279 4.10286C13.0195 5.39453 13.6654 6.97092 13.6654 8.83203C13.6654 10.5126 13.1134 11.9815 12.0095 13.2387C10.9051 14.4954 9.51259 15.2348 7.83203 15.457Z" fill="white"/></svg>
                            Soft Reboot
                        </button>
                        <button onClick={() => setShowAlert2(true)}>
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.16667 7.83203V2.83203H8.83333V7.83203H7.16667ZM8 11.1654C7.76389 11.1654 7.56611 11.0854 7.40667 10.9254C7.24667 10.7659 7.16667 10.5681 7.16667 10.332C7.16667 10.0959 7.24667 9.89786 7.40667 9.73786C7.56611 9.57842 7.76389 9.4987 8 9.4987C8.23611 9.4987 8.43417 9.57842 8.59417 9.73786C8.75361 9.89786 8.83333 10.0959 8.83333 10.332C8.83333 10.5681 8.75361 10.7659 8.59417 10.9254C8.43417 11.0854 8.23611 11.1654 8 11.1654ZM10.5 0.332031H15.5V1.9987H13.2083L13.5417 2.29036C14.2222 2.97092 14.7189 3.71036 15.0317 4.5087C15.3439 5.30759 15.5 6.1237 15.5 6.95703C15.5 8.4987 15.0381 9.87009 14.1142 11.0712C13.1908 12.2729 11.9861 13.0681 10.5 13.457V11.707C11.5 11.3459 12.3056 10.7315 12.9167 9.8637C13.5278 8.99536 13.8333 8.02648 13.8333 6.95703C13.8333 6.33203 13.7153 5.72425 13.4792 5.1337C13.2431 4.5437 12.875 3.9987 12.375 3.4987L12.1667 3.29036V5.33203H10.5V0.332031ZM5.5 13.6654H0.5V11.9987H2.79167L2.45833 11.707C1.73611 11.0681 1.22917 10.339 0.9375 9.51953C0.645833 8.70009 0.5 7.8737 0.5 7.04036C0.5 5.4987 0.961667 4.12703 1.885 2.92536C2.80889 1.72425 4.01389 0.929253 5.5 0.540365V2.29036C4.5 2.65148 3.69444 3.26592 3.08333 4.1337C2.47222 5.00203 2.16667 5.97092 2.16667 7.04036C2.16667 7.66536 2.28472 8.27286 2.52083 8.86286C2.75694 9.45342 3.125 9.9987 3.625 10.4987L3.83333 10.707V8.66536H5.5V13.6654Z" fill="white"/></svg>
                            Hard Reboot
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}


