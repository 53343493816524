import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import DataTableBase from "./reuseable/DataTableBase";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';


const CPOList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [login, setLogin] = useState(false);
    const [flag, setFlag] = useState(1);
    const [id, setId] = useState(null);

    const [show, setShow] = useState(false);
    const [idd, setIdd] = useState(null);    

    useEffect(() => {
        const token = localStorage.getItem('master_token');
        
        axios.get("https://api.pea-cpo.com/api/getCpoList", {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("request response cpo list : ", res);
            console.log("request response cpo list : ", res.data);
            console.log("request response cpo list : ", res?.data?.data);
            // setUser(res?.data);
            setLoader(false);
            setData(res?.data?.data);

        }).catch((err) =>{
            console.log("Error get user : ", err);
        })

    }, [flag])

    // function deleteConfirm(id){
    //     setShow(true);
    // }
    const handleClose = () => {
        setIdd(null);
        setShow(false);
    }
    function handleShow(id){
        setIdd(id);
        setShow(true)
    }


    function deleteCPO(cpo_id){
        setLoader(true);
        setIdd(null);
        setShow(false);
        console.log("delte id is : ", cpo_id);
        const token = localStorage.getItem('master_token');
        axios.delete(`https://api.pea-cpo.com/api/deleteCpo/${cpo_id}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("delte request response : ", res);
            console.log("delete request response : ", res.data);
            // console.log("user request response : ", res?.data?.data);
            // setUser(res?.data);
            setFlag(flag + 1);
            setLoader(false);
            
        }).catch((err) =>{
            console.log("Error get user : ", err);
            setLoader(false);
        })
    }

    function formatDate(text){
        // let text = "2023-02-10T15:00:02";
        const myArray = text.split("T");
        return myArray[0];
    }

    function loginAsUser(e, p, i){
        setId(i);
        console.log("Email: ", e)
        console.log("Password: ", p)
        setLogin(true);
        axios.post(`https://api.pea-cpo.com/api/login`, {email: e, password: p })
        .then(res => {
            console.log("response login : : ", res);
            if(res.data.message === 'Successfully Login'){
                setLogin(false);
                // localStorage.setItem('token', res?.data?.token);
                setId(null);
                // navigate(`/verify/${res?.data?.token}`) 
                const newTab = window.open(`https://pea-cpo.com/verify/${res?.data?.token}`, '_blank');  
                if (newTab) {
                    // If the new tab was successfully opened, you can focus on it (optional)
                    newTab.focus();
                } 
            }
        }).catch((err) => {
            setId(null);   
            setLogin(false);
            console.log("error : ", err);            
        })
    }

    const columns = [
        {
            name: "ID",
            selector: row => row?.id,
            maxWidth: '30px'
        },
        {
            name: "Provider Name",
            selector: row => row?.provider_name,
        },
        {
            name: "Email",
            selector: row => row?.email,
        },
        {
            name: "Subscription",
            selector: row => row.Subscription,
        },
        {
            name: "Created On",
            selector: row => formatDate(row.created_at),
        },
        {
            name: "Password",
            selector: row => row?.passStr,
        },
        {
            name: "Update",
            selector: row => <button className='upate-btn' onClick={() => navigate(`/admin/updatecpo/${row.id}`)}>Update</button>,
            // text: 'center',
        },
        {
            name: "Login To",
            selector: row => <button className='upate-btn ' onClick={() => loginAsUser(row?.email, row.passStr, row?.id)}>Login To {(login && id === row?.id) && <Spinner animation="grow" className="ms-2" size="sm" variant="white" />}</button>,
            // text: 'center',
            width: '155px'
        },
        {
            name: "Delete",
            selector: row => <button className='btn btn-danger' style={{padding: '5px 16px'}} onClick={() => handleShow(row.id)}>Delete</button>,
            // text: 'center',
        },
        {
            name: "View",
            selector: row => <button className='btn btn-success' style={{padding: '5px 22px'}} onClick={() => navigate(`/admin/viewcpo/profile/${row.id}`)}>View</button>,
            // text: 'center',
        },
    ]

    return (
        <>
            {loader ? (
                <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '37vh'}}>
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#814097"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <div className="charger-list-page">
                        <div className="w-100 d-flex">
                            <div className="w-75"><h2>CPO List</h2></div>
                            <div className="w-25"><button className="add-charger-btn" onClick={() => navigate('/admin/addcpo')}>Add CPO</button></div>
                        </div>
                        <div className="pt-3">
                        {data && (
                            <DataTableBase 
                                // title="CPO List"
                                columns={columns}
                                data={data && data}
                            />
                        )}
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} style={{marginTop: '27vh'}}>
                        <Modal.Header closeButton>
                        <Modal.Title>Warning!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => deleteCPO(idd)}>
                            Delete
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </>
    )
}

export default CPOList;