import axios from 'axios';
import React, { useState } from 'react';
import '../style/Profile.css';
import SweetAlert from 'react-bootstrap-sweetalert';


export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [error, setError] = useState(null);
  const [showAlert1, setShowAlert1] = useState(false);

  const handleAlert = () => {
    setShowAlert1(true);
    setTimeout(() => {
      setShowAlert1(false);
    }, 2000); // Replace 3000 with the desired time in milliseconds
  };

  function udpatePassword() {
    if(newPassword && confirmNewPassword && currentPassword){
        if(newPassword === confirmNewPassword){
            const data = {
                current_password: currentPassword,
                new_password: newPassword
            }
    
            const access_token = localStorage.getItem('token');
            axios.post('https://api.pea-cpo.com/api/updateUserPassword',data, {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
                // console.log("Cpo request res : ", res);
                console.log("Password updated Response : ", res);
                // console.log("overviewPageTopCustomers Response Data : ", res?.data);

                handleAlert();
                
            }).catch((err) => {
                console.log("Password update Error : ", err);
                setError(err?.response?.data?.message);
            })

        } else {

        }
        
    }
  }

  return (
    <div className='station-overview'>
    <form>
        {showAlert1 && (
            <SweetAlert success title="congratulations" onConfirm={() => setShowAlert1(false)} onCancel={() => setShowAlert1(false)}>
                Password Updated successfully.
            </SweetAlert>
        )}
    <div className='overview-section-heading'>
        <h3>Change New Password</h3>
        <p>Please enter your current password to change your password.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Current password</h5>
            </div>
            <div style={{width:'35%'}}>
                <input type="password" required defaultValue={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} className='form-control' placeholder='*****'/>
                <p>  </p>
            </div>
        </div>
        <div className='station-field mb-1 mt-4 pt-2'>
            <div className='station-field-div1'>
                <h5>New password</h5>
            </div>
            <div style={{width:'35%'}}>
                <input type="password" defaultValue={newPassword} required onChange={(e) => setNewPassword(e.target.value)} className='form-control' placeholder='*****'/>
                <p style={{fontSize:'14px'}} className="mb-2">Your new password must be more than 8 characters.</p>
            </div>
        </div>
        <div className='station-field mt-1'>
            <div className='station-field-div1'>
                <h5>Confirm new password</h5>
            </div>
            <div style={{width:'35%'}}>
                <input type="password" required defaultValue={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} className='form-control' placeholder='*****'/>
                {newPassword && confirmNewPassword && (
                    newPassword !== confirmNewPassword ? (
                        <p className='text-danger'>New and Confirm Password does not match!</p>
                    ) : newPassword === confirmNewPassword && newPassword  && confirmNewPassword  ? (
                        <p className='text-success'>New and Confirm Password matched!</p>
                    ) : ('')
                )}
                {error && <p className='text-danger'>{error}</p>}
            </div>            
        </div>
    </div>
    <div className='station-setting-btns' style={{marginTop:'3rem'}}>
      <button className='cancel-btn text-white' type='reset' onClick={() => { setError(null); setNewPassword(null); setConfirmNewPassword(''); setCurrentPassword(null); }}>Cancel</button>
      <button className='save-btn' onClick={() => udpatePassword()}>Save Changes</button>
    </div>
    </form>
  </div>
  )
}
