// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Overview from '../components/Overview';
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Stations from '../pages/Stations';
import StationDetail from '../pages/StationDetail';
import StationLog from '../components/StationLog';
import StationOverview from '../components/StationOverview';
import StationRevenue from '../components/StationRevenue';
import StationTransaction from '../components/StationTransaction';
import StationEnergyUse from '../components/StationEnergyUse';
import StationCustomer from '../components/StationCustomer';
import StationSetting from '../components/StationSetting';
import Report from '../pages/Report';
import StationInsight from '../components/StationInsight';
import Map from '../pages/Map';
import StationControl from '../components/StationControl';
import AccountSetting from '../pages/AccountSetting';
import MyProfile from '../components/MyProfile';
import Subscription from '../components/Subscription';
import Notification from '../components/Notification';
import ChangePassword from '../components/ChangePassword';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import MASidebar from '../components/MASidebar';
import MANavbar from '../components/MANavbar';
// import ChargerList from '../components/ChargerList';
import AddACharger from '../components/AddACharger';

function AddCharger() {
  const navigate = useNavigate();
  const [access_token, setAccessToken] = useState(localStorage.getItem('master_token'));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const token = localStorage.getItem('master_token');
    if (token === null) {
      navigate('/admin/login');
    }else{     
      setLoading(true)
    }
  }, [])

  const token = localStorage.getItem('master_token');
  useEffect(() => {
    if(!access_token){
      setAccessToken(localStorage.getItem('master_token'));
    }
    console.log("token found value is : ", token);
    axios.get("https://api.pea-cpo.com/api/getAdminProfile", {headers: {'Authorization' : `Bearer ${access_token}`}}).then((res) => {
      console.log("user request response : ", res);
      setUser(res?.data?.username);
    }).catch((err) =>{
      console.log("Error get user : ", err);
    })
  }, [access_token])

  

  if(loading){
  return (
    <div className='d-flex'>
      <MASidebar />
      <div className='content-div'>
        <MANavbar user={user} />  
        <div className='main-div' style={{padding: '0px 32px'}}>
        <AddACharger />
        </div>
      </div>
    </div>
  );
  }else{
    return(<div></div>);
  }
}

export default AddCharger;
