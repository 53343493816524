import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';

import {useForm} from 'react-hook-form';
import { BallTriangle } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';

export default function UpdateCPO({user}) {
    const [imgPreview, setImgPreview] = useState([]);
    const [profile, setProfile] = useState(null);
    const [message, setMessage] = useState(null);
    const [data, setData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [flag, setFlag] = useState(false);
    const navigate = useNavigate();

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const { id } = useParams();

    useEffect(() => {
        const access_token = localStorage.getItem('master_token');
        axios.get(`https://api.pea-cpo.com/api/getCpoDetail/${id}`, {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
            console.log("Response logout : ", res);
            console.log("Response data : ", res.data);
            setData(res?.data);
            setLoader(false);
            setImgPreview([res?.data?.CompanyProfile?.ComparyLogo]);
            if(res?.data?.status === 200){
            }
        }).catch((err) => {
            console.log("Error : ", err);
        })
    }, [])


    function handleFile(event){
        if(event.target.files){
            setFlag(true);
            setProfile(event.target.files[0]);
            const files = event.target.files
            let array = []
            for(var i=0;i<files.length;i++){
                array.push(URL.createObjectURL(files[i]))
                // console.log("url checking : : ", URL.createObjectURL(files[i]));
            }
            setImgPreview([...array])
        }
    }

    function removeImg(index){
        const array = []
        for(var i=0;i<imgPreview.length;i++){
            if(i !== index){
                array.push(imgPreview[i])
            }
        }
        setImgPreview([...array])
        setProfile(null);
    }

    const onSubmit = (data) => {
        setLoader(true);
        console.log("DATA DATA : ", data);
        console.log("profile : ", profile);
        const formData = new FormData();
        formData.append("cpo_id", id);
        formData.append("companyProfile_ProviderName", data.companyProfile_ProviderName);
        formData.append("companyProfile_ComparyLogo", profile);
        formData.append("companyProfile_TaxId", data.companyProfile_TaxId);
        formData.append("companyProfile_PartnerId", 112121);
        formData.append("personalProfile_Prename", "dummmy pre");
        formData.append("accountantContact_Prename", "dummmy pre");
        formData.append("maintenancePersonalContact_Prename", "dummmy pre");
        formData.append("onSiteServiceContact_Prename", "dummmy pre");
        formData.append("companyProfile_Address_AddressNo", data.companyProfile_Address_AddressNo);
        formData.append("companyProfile_Address_Subdistrict", data.companyProfile_Address_Subdistrict);
        formData.append("companyProfile_Address_District", data.companyProfile_Address_District);
        formData.append("companyProfile_Address_Province", data.companyProfile_Address_Province);
        formData.append("companyProfile_Address_ZipCode", data.companyProfile_Address_ZipCode);
        formData.append("companyProfile_TelephoneNumber", data.companyProfile_TelephoneNumber);
        formData.append("personalProfile_Name", data.personalProfile_Name);
        formData.append("personalProfile_Surname", data.personalProfile_Surname);
        formData.append("personalProfile_Address_AddressNo", data.personalProfile_Address_AddressNo);
        formData.append("personalProfile_Address_Subdistrict", data.personalProfile_Address_Subdistrict);
        formData.append("personalProfile_Address_District", data.personalProfile_Address_District);
        formData.append("personalProfile_Address_Province", data.personalProfile_Address_Province);
        formData.append("personalProfile_Address_ZipCode", data.personalProfile_Address_ZipCode);
        formData.append("personalProfile_Email", data.personalProfile_Email);
        formData.append("personalProfile_MobilePhoneNumber", data.personalProfile_MobilePhoneNumber);
        formData.append("accountantContact_Name", data.accountantContact_Name);
        formData.append("accountantContact_Surname", data.accountantContact_Surname);
        formData.append("accountantContact_PhoneNumber", data.accountantContact_PhoneNumber);
        formData.append("maintenancePersonalContact_Name", data.maintenancePersonalContact_Name);
        formData.append("maintenancePersonalContact_Surname", data.maintenancePersonalContact_Surname);
        formData.append("maintenancePersonalContact_Email", data.maintenancePersonalContact_Email);
        formData.append("maintenancePersonalContact_PhoneNumber", data.maintenancePersonalContact_PhoneNumber);
        formData.append("onSiteServiceContact_Name", data.onSiteServiceContact_Name);
        formData.append("onSiteServiceContact_Surname", data.onSiteServiceContact_Surname);
        formData.append("onSiteServiceContact_Email", data.onSiteServiceContact_Email);
        formData.append("onSiteServiceContact_PhoneNumber", data.onSiteServiceContact_PhoneNumber);
        formData.append("subscription", data.subscription);

        
        const token = localStorage.getItem('master_token');


        axios.post(`https://api.pea-cpo.com/api/updateCpo`, formData, {headers: {'Authorization' : `Bearer ${token}`, 'Accept' : 'application/json'}}).then((res) => {
            console.log("user request response : ", res);
            console.log("user request response.data : ", res.data);
            // reset({companyProfile_ProviderName: '', onSiteServiceContact_PhoneNumber: "", onSiteServiceContact_Email: "",
            // onSiteServiceContact_Surname: '', onSiteServiceContact_Name: '', maintenancePersonalContact_PhoneNumber: '',
            // maintenancePersonalContact_Email: '', maintenancePersonalContact_Surname: '', maintenancePersonalContact_Name: '',
            // accountantContact_PhoneNumber: '', accountantContact_Surname: '', accountantContact_Name: '', personalProfile_MobilePhoneNumber: '',
            // personalProfile_Email: '', personalProfile_Address_ZipCode: '', personalProfile_Address_Province: '', personalProfile_Address_District: '',
            // personalProfile_Address_Subdistrict: '', personalProfile_Address_AddressNo: '', personalProfile_Surname: '', personalProfile_Name: '', 
            // companyProfile_TelephoneNumber: '', companyProfile_Address_ZipCode: '', companyProfile_Address_Province: '', companyProfile_Address_District: '',
            // companyProfile_Address_Subdistrict: '', companyProfile_Address_AddressNo: '', companyProfile_TaxId: ''
            // });

            setLoader(false);
            
            setProfile(null);
            setMessage("Charger Updated successfully.");
            navigate('/admin/cpolist');
            
        }).catch((err) =>{
            setLoader(false);
            console.log("Error get user : ", err);
        })

    }

  return (
<>
{loader ? (
    <div className='bg-white w-100' style={{ height: '100vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '33vh'}}>
        <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#814097"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
        />
    </div>
) : (
<div className='station-overview'>
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className='overview-section-heading'>
        <h3>Update CPO</h3>
        {/* <p>Update your company details and address.</p> */}
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Provider name</h5>
                <p>This will be displayed to customer.</p>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("companyProfile_ProviderName", {required: true})} defaultValue={data?.CompanyProfile?.ProviderName} placeholder='Charge Wai Co., Ltd.'/>
            </div>
        </div>
        <div className='station-field location'>
            <div className='station-field-div1'>
                <h5>Company Logo</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='img-preview'>
                <div>
                {imgPreview.length > 0 && imgPreview.map((url, index)=>
                    <div key={index}>
                        <span style={{cursor:'pointer'}} onClick={()=>removeImg(index)}>&times;</span>
                        <img src={url} alt={url}/>
                    </div>
                )}
                </div>
                <input type='file'  className='form-control' accept="image/*"  onChange={handleFile}/>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Tax ID</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("companyProfile_TaxId", {required: true})} defaultValue={data?.CompanyProfile?.TaxId} placeholder='1-3859-00043-33-1'/>
            </div>
        </div>
        <div className='station-field' style={{marginTop:'2rem'}}>
            <div className='station-field-div1'>
                <h5>Company Address</h5>
                <p>Address that available for contact</p>
            </div>
            <div>
                <div style={{width:'100%'}}>
                    <p style={{marginBottom:'0.3rem'}}>Street address</p>
                    <input className='form-control' {...register("companyProfile_Address_AddressNo")}  defaultValue={data?.CompanyProfile?.AddressNo} placeholder='888 Soi Rangsit - Nakornnayok 49, Prachathipat'/>
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Apartment, Suite, etc (optional)</p>
                        <input className='form-control' defaultValue={data?.CompanyProfile?.Subdistrict} {...register("companyProfile_Address_Subdistrict")} placeholder='-'/>
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>City</p>
                        <input className='form-control' {...register("companyProfile_Address_District")}  defaultValue={data?.CompanyProfile?.District} placeholder='Thunyaburi'/>
                    </div>
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Province</p>
                        <input className='form-control' {...register("companyProfile_Address_Province")} defaultValue={data?.CompanyProfile?.Province} placeholder='Pathumthani '/>
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Zip code</p>
                        <input className='form-control' {...register("companyProfile_Address_ZipCode")} defaultValue={data?.CompanyProfile?.ZipCode} placeholder='12130'/>
                    </div>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Telephone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("companyProfile_TelephoneNumber", {required: true})} defaultValue={data?.CompanyProfile?.TelephoneNumber} placeholder='02-236-0192' />
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Subscription</h5>
            </div>
            <div style={{width:'35%'}} className="mt-3 d-flex">
                
            <div class="form-check">
                <input class="form-check-input" defaultChecked={data?.Subscription === "Basic" ? true : false}  {...register("subscription")} type="radio" value="Basic" name="subscription" id="flexRadioDefault1" />
                <label class="form-check-label" for="flexRadioDefault1">
                    Basic
                </label>
            </div>
            <div class="form-check ms-3">
                <input class="form-check-input" defaultChecked={data?.Subscription === "Advance" ? true : false} {...register("subscription")} type="radio" value="Advance" name="subscription" id="flexRadioDefault2" />
                <label class="form-check-label" for="flexRadioDefault2">
                    Advance
                </label>
            </div>
                
            </div>
        </div>
    </div>
    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>Contact Profile</h3>
        <p>Update your contact details and address.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input className='form-control' {...register("personalProfile_Name")} defaultValue={data?.PersonalProfile?.Name} placeholder='Charge'/>
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input className='form-control' {...register("personalProfile_Surname")} defaultValue={data?.PersonalProfile?.Prename} placeholder='Wai'/>
                </div>
            </div>
        </div>
        <div className='station-field' style={{marginTop:'2rem'}}>
            <div className='station-field-div1'>
                <h5>Address</h5>
                <p>Address that available for contact</p>
            </div>
            <div>
                <div style={{width:'100%'}}>
                    <p style={{marginBottom:'0.3rem'}}>Street address</p>
                    <input className='form-control' {...register("personalProfile_Address_AddressNo")} defaultValue={data?.PersonalProfile?.AddressNo} placeholder='888 Soi Rangsit - Nakornnayok 49, Prachathipat'/>
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Apartment, Suite, etc (optional)</p>
                        <input className='form-control' defaultValue={data?.PersonalProfile?.Subdistrict} {...register("personalProfile_Address_Subdistrict")} placeholder='-'/>
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>City</p>
                        <input className='form-control' {...register("personalProfile_Address_District")} defaultValue={data?.PersonalProfile?.District} placeholder='Thunyaburi'/>
                    </div>
                </div>
                <div className='station-field-location'>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Province</p>
                        <input className='form-control' {...register("personalProfile_Address_Province")} defaultValue={data?.PersonalProfile?.Province} placeholder='Pathumthani '/>
                    </div>
                    <div>
                        <p style={{marginBottom:'0.3rem'}}>Zip code</p>
                        <input className='form-control' {...register("personalProfile_Address_ZipCode")} defaultValue={data?.PersonalProfile?.ZipCode} placeholder='12130'/>
                    </div>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" disabled {...register("personalProfile_Email")} className='form-control' defaultValue={data?.PersonalProfile?.Email} placeholder='charge.wai@peavolta.com'/>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("personalProfile_MobilePhoneNumber")} defaultValue={data?.PersonalProfile?.MobilePhoneNumber} placeholder='099-234-9877'/>
            </div>
        </div>
    </div>

    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>Accountant Contact</h3>
        <p>Update your accountant contact.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input className='form-control' {...register("accountantContact_Name")} defaultValue={data?.AccountantContact?.Name} placeholder='Charge'/>
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input className='form-control' {...register("accountantContact_Surname")} defaultValue={data?.AccountantContact?.Surname} placeholder='Wai'/>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email"  className='form-control' placeholder='charge.wai@peavolta.com'/>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("accountantContact_PhoneNumber")} defaultValue={data?.AccountantContact?.PhoneNumber} placeholder='099-234-9877'/>
            </div>
        </div>
    </div>

    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>Maintenance Personnel Contact</h3>
        <p>Update your maintenance personnel contact.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input className='form-control' {...register("maintenancePersonalContact_Name")} defaultValue={data?.MaintenancePersonalContact?.Name} placeholder='Charge'/>
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input className='form-control' {...register("maintenancePersonalContact_Surname")} defaultValue={data?.MaintenancePersonalContact?.Surname} placeholder='Wai'/>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" {...register("maintenancePersonalContact_Email")} className='form-control' defaultValue={data?.MaintenancePersonalContact?.Email} placeholder='charge.wai@peavolta.com'/>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("maintenancePersonalContact_PhoneNumber")} defaultValue={data?.MaintenancePersonalContact?.PhoneNumber} placeholder='099-234-9877'/>
            </div>
        </div>
    </div>

    <div className='overview-section-heading' style={{marginTop:'5rem'}}>
        <h3>On-site Service Contact</h3>
        <p>Update your On-site service contact.</p>
    </div>
    <div className='overview-section'>  
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Full name</h5>
                <p>This will be displayed on your profile.</p>
            </div>
            <div className='station-field-location' style={{marginTop:'0'}}>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Name</p>
                    <input {...register("onSiteServiceContact_Name")} className='form-control' defaultValue={data?.OnSiteServiceContact?.Name} placeholder='Charge'/>
                </div>
                <div>
                    <p style={{marginBottom:'0.3rem'}}>Surname</p>
                    <input {...register("onSiteServiceContact_Surname")} className='form-control' defaultValue={data?.OnSiteServiceContact?.Surname} placeholder='Wai'/>
                </div>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Email address</h5>
                <p>Invoices will be sent to this email address.</p>
            </div>
            <div className='station-field-email' style={{width:'35%'}}>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3346 2.9987C18.3346 2.08203 17.5846 1.33203 16.668 1.33203H3.33464C2.41797 1.33203 1.66797 2.08203 1.66797 2.9987M18.3346 2.9987V12.9987C18.3346 13.9154 17.5846 14.6654 16.668 14.6654H3.33464C2.41797 14.6654 1.66797 13.9154 1.66797 12.9987V2.9987M18.3346 2.9987L10.0013 8.83203L1.66797 2.9987" stroke="#393939" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <input type="email" {...register("onSiteServiceContact_Email")} className='form-control' defaultValue={data?.OnSiteServiceContact?.Email} placeholder='charge.wai@peavolta.com'/>
            </div>
        </div>
        <div className='station-field'>
            <div className='station-field-div1'>
                <h5>Mobile Phone Number</h5>
            </div>
            <div style={{width:'35%'}}>
                <input className='form-control' {...register("onSiteServiceContact_PhoneNumber")} defaultValue={data?.OnSiteServiceContact?.PhoneNumber} placeholder='099-234-9877'/>
            </div>
        </div>
    </div>
    <div className='station-setting-btns' style={{marginTop:'3rem'}}>
        <button className='cancel-btn'>Cancel</button>
        <button className='save-btn' type='submit'>Submit</button>
    </div>
    <p className='text-success py-2'>{message}</p>
</form>
</div>)}
</>
  )
}
