import React, {useState, useEffect} from 'react';
import DataTableBase from './reuseable/DataTableBase';
import DatePicker from './reuseable/DatePicker';
import StationGraphCard from './reuseable/StationGraphCard';

import Select from 'react-select';
import axios from 'axios';
import StationGraphCard2 from './reuseable/StationGraphCard2';
import { BallTriangle } from 'react-loader-spinner';

import XLSX from 'xlsx';



const ReportCustomer = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);

    const [data, setData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [loader, setLoader] = useState(true);

    const [dateRange2, setDateRange2] = useState(null);


    const [checkPoint, setCheckPoint] = useState(true);

    const [filter, setFilter] = useState(null);



    
    useEffect(() => {
        function filterDataByDateRange(startDateStr, endDateStr, data) {
            let customersCheckArray = [];

            function uniqueCustomer(cus){
                let flg = 1;
                for (let index = 0; index < customersCheckArray?.length; index++) {
                    if(customersCheckArray[index] === cus){
                        flg = 0;
                        break;
                    }
                }
                customersCheckArray.push(cus);
                return flg;
            }


            const startDate = new Date(startDateStr);
            const endDate = new Date(endDateStr);
        
            const filteredData = data.filter((item) => {
            const itemDate = new Date(item.startDate);
            return itemDate >= startDate && itemDate <= endDate;
            });

            // console.log("Filterd data set : ", filteredData);


            // for(let a= 0; a < filteredData?.length; a++){
                // console.log("date : ", filteredData[a]?.startDate)
            // }
        
            const dateRevenueSessionMap = {};
        
            filteredData.forEach((item) => {
            const itemDate = new Date(item.startDate).toLocaleDateString();

        
            if (!dateRevenueSessionMap[itemDate]) {
                // console.log("(item.energy) : ", item.totalKwh);
                dateRevenueSessionMap[itemDate] = {
                date: itemDate,
                customers: 1,
                unique: 1,
                repeated: 0,
                };
            } else {
                dateRevenueSessionMap[itemDate].customers += 1;
                dateRevenueSessionMap[itemDate].unique += uniqueCustomer(item?.email);
                dateRevenueSessionMap[itemDate].repeated += dateRevenueSessionMap[itemDate].customers - dateRevenueSessionMap[itemDate].unique;
            }
            });
        
            // Convert the map into an array of objects
            const result = Object.values(dateRevenueSessionMap);
        
            return result;
        }

        if(dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today'){
            // Assuming your data is stored in the 'data' array
            
            // Example usage:
            // const startDateStr = "2023-01-01";
            // const endDateStr = "2023-03-01";
            const filteredData = filterDataByDateRange(dateRange2?.startDate, dateRange2?.endDate, data);
            console.log(filteredData);
            setTableData(filteredData);

            
        } else {
            const tabData = calculateTableData(data);

            // console.log("Table Data : ", tabData);

            const revTabData = tabData.reverse();

    

            setTableData(revTabData);
        }
    }, [dateRange2?.startDate])



    const caseInsensitiveSort = (rowA, rowB) => {
        const a = rowA?.month?.toLowerCase();
        const b = rowB?.month?.toLowerCase();

        if(a){
            return 1;
        } else {
            if (a > b) {
                return 1;
            }
    
            if (b > a) {
                return -1;
            }
            
        }

        return 0;
    };

    const caseInsensitiveSort2 = (rowA, rowB) => {
        const a = rowA?.customers;
        const b = rowB?.customers;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };

    const caseInsensitiveSort3 = (rowA, rowB) => {
        const a = rowA?.unique;
        const b = rowB?.unique;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };

    const caseInsensitiveSort4 = (rowA, rowB) => {
        const a = rowA?.customers - rowA?.unique;
        const b = rowB?.customers - rowB?.unique;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };

 
    const columns = [
        {
            name: dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? "Date" : "Month",
            selector: row => dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? row?.date : row?.month,
            sortable: true,
            sortFunction: caseInsensitiveSort
        },
        {
            name: "Total Customer",
            selector: row => (row?.customers).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort2
        },
        {
            name: "Total New Customer",
            selector: row => (row?.unique).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort3
        },
        {
            name: "Total Repeat Customer",
            selector: row => (row?.customers - row?.unique).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort4
        }        
    ];


    useEffect(() => {
        const token = localStorage.getItem('token');
        // console.log("token found value is : ", token);
        axios.get("https://api.pea-cpo.com/api/getChargerListCpo", {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            // console.log("Get Charger list : ", res);
            const opArr = [];

            res?.data?.data && res?.data?.data?.map((item) => {
                opArr.push({value: item?.locationEN, label: item?.locationTH});
            })

            // console.log("Options options : ", opArr)
            setOptions(opArr);
            // setStations(res?.data?.data);
            
        }).catch((err) =>{
        console.log("Error get user : ", err);
        })
    }, [])

    


    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://api.pea-cpo.com/api/overviewPageChargingList', {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
        // console.log("Get Charger detail : ", res);
        
        setData(res?.data?.data);

        const ans= calculateMetrics(res?.data?.data);
        
        setMetrics(ans);
        
        const ans2 = calculateChartData(res?.data?.data);
        // console.log("Ans Two data : : ", ans2)
        
        setChartData(ans2);

        const tabData = calculateTableData(res?.data?.data);

        // console.log("Table data : ", tabData)

        const revTabData = tabData.reverse();

        setTableData(revTabData);

        setLoader(false);
        
        }).catch((err) =>{
            setLoader(false);
            console.log("Error get user : ", err);
        })
    }, [])


    useEffect(() => {
        // console.log("Filter val 1: ", filter)
        if(filter === 'Today'){
            // console.log("Filter val : ", filter);
            const customerArr = {
                customers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                unique: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                repeated: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            }
            const met = {
                customers: 0,
                unique: 0,
            };
    
            let customersCheckArray = [];
    
            function uniqueCustomer(cus){
                let flg = 1;
                for (let index = 0; index < customersCheckArray?.length; index++) {
                    if(customersCheckArray[index] === cus){
                        flg = 0;
                        break;
                    }
                }
                customersCheckArray.push(cus);
                return flg;
            }

            let customersCheckArray2 = [];
    
            function uniqueCustomer2(cus){
                let flg = 1;
                for (let index = 0; index < customersCheckArray2?.length; index++) {
                    if(customersCheckArray2[index] === cus){
                        // console.log("mail : ", customersCheckArray2[index]);
                        // console.log(cus);
                    flg = 0;
                    break;
                    }
                }
                customersCheckArray2.push(cus);
                return flg;
            }

            for (let i = 0; i < data.length; i++) {
                const email = data[i]?.email;
                const startDate = new Date(data[i]?.startDate);
                const startTime = data[i]?.startTime;

                const today = new Date();
                if(filter === 'Today'){
                    if (
                      startDate.getFullYear() !== today.getFullYear() ||
                      startDate.getMonth() !== today.getMonth() ||
                      startDate.getDate() !== today.getDate()
                    ) {
                      // console.log("Wrong today");
                      continue;
                    }
                }

                if(selectedOption){
                    if(selectedOption?.value !== data[i]?.locationEN){
                        continue;
                        // console.log("Working false ...")
                    }  
                }

                const hour = Number(startTime.slice(0,2));

                 

                customerArr.customers[hour] += 1; 
                customerArr.unique[hour] += uniqueCustomer(data[i].email); 

                met.unique += uniqueCustomer2(email);
                met.customers += 1;
            }

            // console.log("Today Ans data : ", customerArr);
            setMetrics(met);

            // console.log("Today Ans Two data : : ", met)

            setChartData(customerArr);
            // setCheckPoint(true);
            setCheckPoint(false);
            
        } else {
            // if(filter !== null){
                const ans= calculateMetrics(data);
                // console.log("Ans data : ", ans);
                setMetrics(ans);
                
                const ans2 = calculateChartData(data);
                // console.log("Ans Two data : : ", ans2)
                
                setChartData(ans2);
                // setCheckPoint(false);
                // console.log("Else part working ... ");

                setCheckPoint(true);

            // }
        }

        // console.log("Use Effect working ...", filter);
    }, [filter, selectedOption])

    function calculateMetrics(para){
        const met = {
            customers: 0,
            // new: 0,
            unique: 0,
        };

        let customersCheckArray = [];

        function uniqueCustomer(cus){
            let flg = 1;
            for (let index = 0; index < customersCheckArray?.length; index++) {
                if(customersCheckArray[index] === cus){
                    flg = 0;
                    break;
                }
            }
            customersCheckArray.push(cus);
            return flg;
        }

        // para && para.forEach((item) => {
        //     met.unique += uniqueCustomer(item?.email);
        //     met.customers += 1;
        // })
        
        
        for(let i = 0; i < para?.length; i++){
            const startDate = new Date(para[i].startDate);
                        
            if (startDate.getFullYear() !== new Date().getFullYear()) {
                continue;
            }

            if(selectedOption){
                if(selectedOption?.value !== para[i]?.locationEN){
                    continue;
                } 
            }
            
            met.unique += uniqueCustomer(para[i]?.email);
            met.customers += 1;
        }


        return met;
    }

    function calculateChartData(para){
        const customerArr = {
            customers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            unique: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            repeated: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        }
        
        let customersCheckArray = [];

        function uniqueCustomer(cus){
            let flg = 1;
            for (let index = 0; index < customersCheckArray?.length; index++) {
                if(customersCheckArray[index] === cus){
                    // console.log("mail : ", customersCheckArray[index]);
                    // console.log(cus);
                flg = 0;
                break;
                }
            }
            customersCheckArray.push(cus);
            return flg;
        }

        for(let i = 0; i < para?.length; i++){
            const env = parseFloat(para[i]?.totalKwh);
            const startDate = new Date(para[i].startDate);

            // console.log("For loop working")
            
            if (startDate.getFullYear() !== new Date().getFullYear()) {
                continue;
            }

            if(selectedOption){
                if(selectedOption?.value !== para[i]?.locationEN){
                    continue;
                    // console.log("false")
                }  
                // else {
                //     console.log("True")
                // }
            }
            
            // console.log("For loop True true ")

            const month = startDate.getMonth();
            customerArr.customers[month] += 1; 
            customerArr.unique[month] += uniqueCustomer(para[i].email); 
        }

        for(let a = 0; a < 7; a++){
            customerArr.repeated[a] = customerArr.customers[a] - customerArr.unique[a];
        }

        return customerArr;
    }

    // console.log("Selected options : : ", selectedOption);

    function calculateTableData(para){
        const dumArr = [
            {
                month: 'January',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'February',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'March',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'April',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'May',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'June',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'July',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'August',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'September',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'October',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'November',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
            {
                month: 'December',
                customers: 0,
                unique: 0,
                repeated: 0,
            },
        ];

        let customersCheckArray = [];

        function uniqueCustomer(cus){
            let flg = 1;
            for (let index = 0; index < customersCheckArray?.length; index++) {
                if(customersCheckArray[index] === cus){
                    flg = 0;
                    break;
                }
            }
            customersCheckArray.push(cus);
            return flg;
        }

        for(let i = 0; i < para?.length; i++){
            const email = para[i]?.email;
            const startDate = new Date(para[i].startDate);
            const getMon = startDate.getMonth();


            if(dateRange2?.option){
                const startRange = new Date(dateRange2?.startDate);
                const endRange = new Date(dateRange2?.endDate);

                if(startDate >= startRange && startDate <= endRange){

                } else {
                    continue;
                }

            } else {
                if (startDate.getFullYear() !== new Date().getFullYear()) {
                  continue;
                }
            }

            dumArr[getMon].customers +=1;
            
            dumArr[getMon].unique += uniqueCustomer(email);
            
            dumArr[getMon].repeated = dumArr[getMon].customers - dumArr[getMon].unique;

            
        }

        const newArr = [];

        dumArr && dumArr.map(item => {
            if(item?.customers > 0){
                newArr.push(item);
            }
        })

        return newArr;
    }


    function convertToWorksheet(tableData) {
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        return worksheet;
    }

    function downloadExcelFile(tableData, fileName) {
        const worksheet = convertToWorksheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }

    const now = new Date();

    const isoDateTime = now.toISOString();
    
    function handleDownloadExcel(fileData) {
        // if(dateRange2?.option){
        //     const dumArr = [
        //         {
        //             month: null,
        //             customers: 0,
        //             unique: 0,
        //             repeated: 0,
        //         }
        //     ];


        //     let customersCheckArray = [];

        //     function uniqueCustomer(cus){
        //         let flg = 1;
        //         for (let index = 0; index < customersCheckArray?.length; index++) {
        //             if(customersCheckArray[index] === cus){
        //                 flg = 0;
        //                 break;
        //             }
        //         }
        //         customersCheckArray.push(cus);
        //         return flg;
        //     }

        //     const start = new Date(dateRange2?.startDate);
        //     const end = new Date(dateRange2?.endDate);

        //     data && data.map(obj => {
        //         // console.log("obj : ", obj)
        //         const currentDate = new Date(obj?.startDate);
        //         // const rev = parseFloat(obj?.revenue);

        //         const email = obj?.email;

        //         if(currentDate >= start && currentDate <= end){
        //             dumArr[0].customers +=1;            
        //             dumArr[0].unique += uniqueCustomer(email);    
        //         }

        //     });

        //     dumArr[0].repeated = dumArr[0].customers - dumArr[0].unique;

        //     dumArr[0].month = `${dateRange2?.startDate}-${dateRange2?.endDate}`
            
        //     console.log("dumArr data : ", dumArr);

        //     downloadExcelFile(dumArr, `Customer Report ${isoDateTime}`);
        // } else {
        // }
        const structuredData2 = [];
        const StructureData = fileData?.filter(item => {
            const obj = {"Month": item?.month ? item?.month : item?.date, "Total Customers": Number(item?.customers), "Unique Customers": Number(item?.unique), "Total Repeated Customers": Number(item?.customers - item?.unique) };
            structuredData2?.push(obj);
            return obj;
            }
        )

        downloadExcelFile(structuredData2, `Customer Report ${isoDateTime}`);
        // downloadExcelFile(fileData, `Revenue Report ${isoDateTime}`);
    }




    if(loader){
        return (
            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '17vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
            </div>
        )
    } else {

    return (
        <div className='station-revenue'>
            <div className='w-100 d-flex'>
                <div className='w-50'>
                    <h3 className='font-style27 mb-0'>Customer</h3>
                    <h6 className='font-style28'>ข้อมูลด้านลูกค้า</h6>
                </div>
                <div className='w-50 d-flex justify-content-end'>
                    <Select
                        className="d-inline-block w-50 mt-1" style={{width: '250px', height: '46px'}}
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                    />

                    {/* <button className='btn-style1'>Download</button> */}
                </div>
            </div>
            <StationGraphCard2 
                val1={<h3 className='graph-card-number'>{(metrics?.customers).toLocaleString()} <b className='font-style29'></b></h3>} 
                val2={<h3 className='graph-card-number'>{(metrics?.unique).toLocaleString()} <b className='font-style29'></b></h3>} 
                val3={<h3 className='graph-card-number'>{(metrics?.customers - metrics?.unique).toLocaleString()} <b className='font-style29'></b></h3>} 
                val4={<h3 className='graph-card-number'>{metrics?.customers > 0 ? Math.ceil((((metrics?.customers - metrics?.unique) / metrics?.customers ) * 100) * 100) / 100 : 0}%<b className='font-style29'></b></h3>} 
                l1="Total Customer" l2="Total New Customer" l3="Total Repeat Customer" l4="Retention Rate"  
                cols={4}
                statRev={true}
                stacked={true}
                label="Customers"
                data2={chartData && chartData}
                setFilter={setFilter}
                filter={filter}
                checkPoint={checkPoint}
                symbol={"No. of Customers"}
                />
            <div>
            
            <div className='w-100 d-flex justify-content-end'>
                <div className='w-251 mb-4 pb-2' >
                    <DatePicker dateRange={dateRange2} setDateRange={setDateRange2} />

                </div>
                <div className='ps-3'>
                    <button className='btn-style1 d-inline-block' onClick={() => handleDownloadExcel(tableData)}>Download</button>

                </div>
            </div>
            {tableData && (
                <DataTableBase 
                    columns={columns}
                    data={tableData}
                    title="Summary"
                    pagination
                />
            )}
            
            </div>
        </div>
    )
    }
}

export default ReportCustomer;