import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReportCustomer from "../components/ReportCustomer";
import ReportEnergyUsed from "../components/ReportEnergyUsed";
import ReportRevenue from "../components/ReportRevenue";
import ReportTransaction from "../components/ReportTransaction";
import ReportBilling from "../components/ReportBilling";

const Report = () => {
  const [tog, setTog] = useState(1);

    return (
        <div className="reports-page-div">
            <h1 className="font-style37">Reports</h1>
            <div className="Station-toggle " style={{borderTop: 'none'}}>
                <ul>
                    <li onClick={() => setTog(1)} className={tog === 1? 'mt-0 active-tog' : 'mt-0'} >
                        <Link to="">Revenue</Link>
                    </li>
                    <li onClick={() => setTog(2)} className={tog === 2? 'mt-0 active-tog' : 'mt-0'} >
                        <Link to="">Transaction</Link>
                    </li>
                    <li onClick={() => setTog(3)} className={tog === 3? 'mt-0 active-tog' : 'mt-0'} >
                    <Link to="">Energy Use</Link>
                    </li>
                    <li onClick={() => setTog(4)} className={tog === 4? 'mt-0 active-tog' : 'mt-0'} >
                    <Link to="">Customer</Link>
                    </li>
                    <li onClick={() => setTog(5)} className={tog === 5? 'mt-0 active-tog' : 'mt-0'}>
                        e-billing
                    </li>
                </ul>
            </div>
            {tog === 1 && (<ReportRevenue />)}
            {tog === 2 && (<ReportTransaction />)}
            {tog === 3 && (<ReportEnergyUsed />)}
            {tog === 4 && (<ReportCustomer />)}
            {tog === 5 && (<ReportBilling />)}
            
        </div>
    )
}


export default Report;