import React, { useEffect, useState } from 'react';
import DataTableBase from './reuseable/DataTableBase';
import StationGraphCard from './reuseable/StationGraphCard';

import DatePicker from './reuseable/DatePicker';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';


const StationRevenue = () => {
    const [toggleModel, setToggleModel] = useState(false)

    const [display, setDisplay] = useState({
        display:'none'
    })

    const [dateRange, setRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })

    const [data, setData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [checkPoint, setCheckPoint] = useState(false);

    const [filter, setFilter] = useState(null);

    
    const { id } = useParams();


    const [dateRange2, setDateRange2] = useState(null);




    useEffect(() => {

        console.log("Date range 2 : : ", dateRange2)
        function filterDataByDateRange(startDateStr, endDateStr, data) {
            const startDate = new Date(startDateStr);
            const endDate = new Date(endDateStr);
        
            const filteredData = data.filter((item) => {
            const itemDate = new Date(item.startDate);
            return itemDate >= startDate && itemDate <= endDate;
            });

            console.log("Filterd data set : ", filteredData);


            for(let a= 0; a < filteredData?.length; a++){
                console.log("date : ", filteredData[a]?.startDate)
            }
        
            const dateRevenueSessionMap = {};
        
            filteredData.forEach((item) => {
            const itemDate = new Date(item.startDate).toLocaleDateString();
        
            if (!dateRevenueSessionMap[itemDate]) {
                dateRevenueSessionMap[itemDate] = {
                date: itemDate,
                revenue: parseFloat(item.revenue),
                session: 1,
                };
            } else {
                dateRevenueSessionMap[itemDate].revenue += parseFloat(item.revenue);
                dateRevenueSessionMap[itemDate].session += 1;
            }
            });
        
            // Convert the map into an array of objects
            const result = Object.values(dateRevenueSessionMap);
        
            return result;
        }

        if(dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today'){
            // Assuming your data is stored in the 'data' array
            
            // Example usage:
            const startDateStr = "2023-01-01";
            const endDateStr = "2023-03-01";
            const filteredData = filterDataByDateRange(dateRange2?.startDate, dateRange2?.endDate, data);
            console.log(filteredData);
            setTableData(filteredData);

            
        } else {
            const tabData = calculateTableData(data);

            console.log("Table Data : ", tabData);

            const revTabData = tabData.reverse();

    

            setTableData(revTabData);
        }
    }, [dateRange2?.startDate])


    // https://api.pea-cpo.com/api/overviewPageChargingListByID/1

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`https://api.pea-cpo.com/api/overviewPageChargingListByID/${id}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("Get Charger detail : ", res);
            
            setData(res?.data?.data);

            const ans= calculateMetrics(res?.data?.data);
            // console.log("Ans data : ", ans);
            setMetrics(ans);

            const ans2 = calculateChartData(res?.data?.data);
            console.log("Ans Two data : : ", ans2)

            setChartData(ans2);

            const tabData = calculateTableData(res?.data?.data);

            console.log("Table Data : ", tabData);

            const revTabData = tabData.reverse();

    

            setTableData(revTabData);
            
            setLoader(false);
        
        }).catch((err) =>{
            console.log("Error get user : ", err);
            setLoader(false);
        })
    }, [])

    useEffect(() => {
        console.log("Filter val 1: ", filter)
        if(filter === 'Today'){
            console.log("Filter val : ", filter);
            const revenueArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            const metrics = {
                session: 0,
                revenue: 0,
            };

            for (let i = 0; i < data.length; i++) {
                const revenue = parseFloat(data[i]?.revenue);
                const startDate = new Date(data[i]?.startDate);
                const startTime = data[i]?.startTime;

                const today = new Date();
                if(filter === 'Today'){
                    if (
                      startDate.getFullYear() !== today.getFullYear() ||
                      startDate.getMonth() !== today.getMonth() ||
                      startDate.getDate() !== today.getDate()
                    ) {
                      // console.log("Wrong today");
                      continue;
                    }
                }

                const hour = Number(startTime.slice(0,2));

                revenueArr[hour] += revenue; 

                metrics.session += 1;
                metrics.revenue += revenue;
            }

            console.log("Today Ans data : ", revenueArr);
            setMetrics(metrics);

            console.log("Today Ans Two data : : ", metrics)

            setChartData(revenueArr);
            setCheckPoint(true);
            
        } else {
            const ans= calculateMetrics(data);
            // console.log("Ans data : ", ans);
            setMetrics(ans);
            
            const ans2 = calculateChartData(data);
            console.log("Ans Two data : : ", ans2)
            
            setChartData(ans2);
            setCheckPoint(false);
        }
    }, [filter])
    
    function toggleDate(){
      if(display.display === 'none')
      setDisplay({display: 'block'})
      else
      setDisplay({display: 'none'})
    }

    function handleSelect(date){
        setRange({ 
            startDate: date.selection.startDate,
            endDate: date.selection.endDate,
            key: date.selection.key
        })
    }

    function calculateMetrics(para){
        const met = {
            energy: 0,
            revenue: 0,
            session: 0
        };

        // para && para.forEach((item) => {
        //     met.energy += Number(item?.totalKwh);
        //     met.revenue += Number(item?.revenue);
        //     met.session += 1;
        // })

        for(let i = 0; i < para?.length; i++){
            const rev = parseFloat(para[i]?.revenue);
            const energy = parseFloat(para[i]?.totalKwh);
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }

            met.energy += energy;
            met.revenue += rev;
            met.session += 1;
        }

        return met;
    }

    function calculateChartData(para){
        const revenueArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for(let i = 0; i < para?.length; i++){
            const rev = parseFloat(para[i]?.revenue);
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }

            const month = startDate.getMonth();
            revenueArr[month] += rev; 
        }

        return revenueArr;
    }

//     const date = new Date();
// const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
// const monthNumber = date.getMonth(); // Returns a zero-based integer (0 for January, 1 for February, etc.)
// const monthName = monthNames[monthNumber];

// console.log("Month number:", monthNumber);
// console.log("Month name:", monthName);

    function calculateTableData(para){
        const dumArr = [
            {
                month: 'January',
                session: 0,
                revenue: 0,
            },
            {
                month: 'February',
                session: 0,
                revenue: 0,
            },
            {
                month: 'March',
                session: 0,
                revenue: 0,
            },
            {
                month: 'April',
                session: 0,
                revenue: 0,
            },
            {
                month: 'May',
                session: 0,
                revenue: 0,
            },
            {
                month: 'June',
                session: 0,
                revenue: 0,
            },
            {
                month: 'July',
                session: 0,
                revenue: 0,
            },
            {
                month: 'August',
                session: 0,
                revenue: 0,
            },
            {
                month: 'September',
                session: 0,
                revenue: 0,
            },
            {
                month: 'October',
                session: 0,
                revenue: 0,
            },
            {
                month: 'November',
                session: 0,
                revenue: 0,
            },
            {
                month: 'December',
                session: 0,
                revenue: 0,
            },
        ];

        for(let i = 0; i < para?.length; i++){
            const rev = parseFloat(para[i]?.revenue);
            const startDate = new Date(para[i].startDate);
            const getMon = startDate.getMonth();


            if(dateRange2?.option){
                const startRange = new Date(dateRange2?.startDate);
                const endRange = new Date(dateRange2?.endDate);

                if(startDate >= startRange && startDate <= endRange){

                } else {
                    continue;
                }

            } else {
                if (startDate.getFullYear() !== new Date().getFullYear()) {
                  continue;
                }
            }

            dumArr[getMon].session +=1;
            dumArr[getMon].revenue += rev;

            // const month = startDate.getMonth();
            // dumArr[month] += rev; 
        }

        const newArr = [];

        dumArr && dumArr.map(item => {
            if(item?.session > 0){
                newArr.push(item);
            }
        })
        return newArr;
    }
   
    const columns = [
        {
            name: dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? "Date" : "Month",
            selector: row => dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? row?.date : row?.month,
            sortable: true,
        },
        {
            name: "Total Sessions(Times)",
            selector: row => (row?.session).toLocaleString(),
            sortable: true,
        },
        {
            name: "Total Revenue(THB)",
            selector: row => (Math.ceil(row?.revenue * 100) / 100).toLocaleString(),
            sortable: true,
        },
        {
            name: "Avg. Price per Session (THB)",
            selector: row => row?.session > 0 ? (Math.ceil(row?.revenue / row?.session * 100) / 100).toLocaleString() : 0,
            sortable: true,
        },
    ]

    

    if(loader){
        return (
          <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '17vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
          </div>
        )
    } else {
    return (
        <div className='station-revenue'>
            <div className='w-100 d-flex'>
                <div className='w-50'>
                    <h3 className='font-style27 mb-0'>Revenue</h3>
                    <h6 className='font-style28'>ภาพรวมข้อมูลรายได้</h6>
                </div>
                <div className='w-50'>
                    {/* <button onClick={()=>setToggleModel(true)} className='btn-style1'>Download</button> */}
                </div>
            </div>
            
                <StationGraphCard 
                    val1={<h3 className='graph-card-number'>{(metrics?.session).toLocaleString()} <b className='font-style29'>Times</b></h3>} 
                    val2={<h3 className='graph-card-number'>{(Math.ceil((metrics?.revenue * 100) / 100)).toLocaleString()} <b className='font-style29'>THB</b></h3>} 
                    val3={<h3 className='graph-card-number'>{metrics?.session === 0 ? 0 : (Math.ceil(metrics?.revenue / metrics?.session * 100) / 100).toLocaleString()} <b className='font-style29'>THB</b></h3>} 
                    l1="Total Sessions" l2="Total Revenue" l3="Average Price per Session"  
                    cols={3}
                    statRev={true}
                    label="Revenue"
                    data2={chartData}
                    setFilter={setFilter}
                    filter={filter}
                    symbol={"BAHT ฿"}
                    />

            <div className='w-100 d-flex my-4 justify-content-end'>
               <div className='mx-3'>
                    {/* <button onClick={()=>setToggleModel(true)} className='btn-style1'>Download</button> */}
               </div>
                <div className='section-3rdds'>
                    <DatePicker dateRange={dateRange2} setDateRange={setDateRange2} /> 
                </div>
            </div>
            <div>
                <DataTableBase 
                    columns={columns}
                    data={tableData && tableData}
                    title="Summary"
                />
            </div>
            {toggleModel &&
            <div className='modal-container'>
                <div className='modal-wrapper'>
                    <div className='modal-wrapper-header'>
                        <h4>Download</h4>
                    </div>
                    <div style={{marginTop:'1rem'}} className='modal-wrapper-section'>
                        <div className='modal-wrapper-section-header'>
                            <h6 style={{fontWeight:'400'}}>Date Range</h6>
                        </div>
                        <div className='modal-wrapper-section-input'>
                            <select>
                                <option selected>This Month</option>
                                <option>This Week</option>
                                <option>Today</option>
                            </select>
                        </div>
                    </div>
                    <div className='modal-wrapper-section'>
                        <div className='modal-wrapper-section-header'>
                            <h6 style={{fontWeight:'400'}}>File formate</h6>
                        </div>
                        <div className='modal-wrapper-section-input'>
                            <select>
                                <option selected>Exel (.xlsx)</option>
                                <option>PDF</option>
                                <option>CSV</option>
                            </select>
                        </div>
                    </div>
                    <div className='station-setting-btns'>
                        <button className='cancel-btn model-btn' onClick={()=>setToggleModel(false)}>Cancel</button>
                        <button className='save-btn model-btn'>Download</button>
                    </div>
                </div>
                </div>
            }
        </div>
    )}
}

export default StationRevenue;