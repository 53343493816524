import React from 'react'
import { Col, Row } from "react-bootstrap";
import arrowIcon from '../assets/Icon2.svg'
import Anc from './Anc';
import DataTableBase from './reuseable/DataTableBase';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';

export default function StationLog() {
    const [data, setData] = useState(null);
    const [loader, setLoader] = useState(true);

    const {id} = useParams();

    useEffect(() => {
        const access_token = localStorage.getItem('token');
        axios.get(`https://api.pea-cpo.com/api/viewPageStationLog/${id}`, {headers: {'Authorization': `Bearer ${access_token}`}}).then((res) => {
            // console.log("Cpo request res : ", res);
            console.log("Overview page status Response : ", res?.data);
            // console.log("Overview page status Response : ", res?.data?.data);
            setData(res?.data?.data);
  
            setLoader(false);
            
        }).catch((err) => {
            console.log("Error : ", err);
            setLoader(false);
        })
    }, [])
    const columns = [
        {
            name: 'Date Time',
            selector: row => row.date,
            sortable: true,
        },
        {
            name: 'Connector Type',
            selector: row => row.type,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Error Code',
            selector: row => row.code,
            sortable: true,
        },
        {
            name: 'Error Description',
            selector: row => row.description,
        },
        {
            name: 'Updated by',
            selector: row => row.update,
            sortable: true,
        },
    ];

    const columns2 = [
        {
            name: "Date Time",
            selector: row => row?.timestamp,
            sortable: true,
        },
        {
            name: "Error Message",
            selector: row => row?.reasonErrorMsg ? row?.reasonErrorMsg : "null",
            sortable: true,
        }
    ]; 

    const data3 = [
        {
            id: 1,
            datetime: '2022-06-01 09:52:33',
            error: 'Null'
        },
        {
            id: 2,
            datetime: '2022-06-04 09:52:33',
            error: 'Null'
        },
        {
            id: 3,
            datetime: '2022-05-025 09:52:33',
            error: 'Null'
        },
        {
            id: 4,
            datetime: '2022-06-07 09:52:33',
            error: 'Null'
        },
        {
            id: 5,
            datetime: '2022-06-13 09:52:33',
            error: 'Null'
        },
    ]

    const data2 = [
        {
            id: 1,
            date: '2022-06-01 09:52:33',
            type: 'CHAdeMO',
            status: 'Available',
            code: 'No Error',
            description: 'Not found',
            update: '444000',
        },        
        {
            id: 2,
            date: '2022-06-01 09:52:33',
            type: 'CHAdeMO',
            status: 'Available',
            code: 'No Error',
            description: 'Not found',
            update: '444000',
        },        
        {
            id: 3,
            date: '2022-06-01 09:52:33',
            type: 'CHAdeMO',
            status: 'Unavailable',
            code: 'No Error',
            description: 'Not found',
            update: '444000',
        },        
        {
            id: 4,
            date: '2022-06-01 09:52:33',
            type: 'CHAdeMO',
            status: 'Available',
            code: 'No Error',
            description: 'Not found',
            update: '232260',
        },        
        {
            id: 5,
            date: '2022-06-01 09:52:33',
            type: 'CHAdeMO',
            status: 'Available',
            code: 'No Error',
            description: 'Not found',
            update: '444000',
        },        
        {
            id: 6,
            date: '2022-06-01 09:52:33',
            type: 'CHAdeMO',
            status: 'Unavailable',
            code: 'No Error',
            description: 'Not found',
            update: '453433',
        },        
        {
            id: 7,
            date: '2022-06-01 09:52:33',
            type: 'JAKsJXga',
            status: 'Available',
            code: 'No Error',
            description: 'Not found',
            update: '453433',
        },        
        {
            id: 8,
            date: '2022-06-01 09:52:33',
            type: 'IAaSEsa',
            status: 'Available',
            code: 'Two Error',
            description: 'Not found',
            update: '4473922',
        },        
    ]


    if(loader){
        return (
          <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '17vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
          </div>
        )
    } else {

    return (
      <>
        <div className="stat-detail-log d-flex w-100">
        <div className="w-75">
            <h4 className="font-style20 mb-0">Charger Log</h4>
            <p className="font-style21">Operating charging charger activity log.</p>
        </div>
        <div className="w-25">
            {/* <select className="form-select mt-2" aria-label="Default select example">
                <option selected>Select option</option>
                <option value="1">Charger Name : 01</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select> */}
        </div>
    </div>
    <div className="stat-detail-sec3 w-100">
        <Row>
            <Col md={6}>
                <h6 className="font-style22 mb-0">Last Boot</h6>
                <h6 className="font-style23">2022-05-11 09:11:33</h6>
            </Col>
            <Col md={6}>
                <div className="d-flex justify-content-between">
                    <div>
                        <h6 className="font-style22 mb-0">Heartbeat Last Stamp </h6>
                        <h6 className="font-style23">2022-05-11 09:11:33</h6>
                    </div>
                    <div>
                        <h6 className="font-style22 mb-0 ">Go Station Control<img src={arrowIcon} className="ms-3" width="10.33px" height="10.33px" alt="pic"/> </h6>
                    </div>
                </div>
            </Col>
        </Row>
    </div>
    <div className="mt-24">
        <DataTableBase 
            title="Status Notification"
            columns={columns}
            data={data2}
            fixedHeader
            fixedHeaderScrollHeight="292px"

        />
        {/* <Anc /> */}
    </div>
    {/* <div className='w-100  d-flex' style={{padding: '0px 32px', paddingTop: '32px'}}>
        <div className='w-25'><h4>Filter by date and time</h4></div>        
        <div className='w-75  justify-content-end d-flex'>
            <div className='search-field d-inline-block pe-2' style={{width: '40%'}}>
                <input type="date" placeholder="Search by Name" />
            </div>
            <div className='search-field d-inline-block ps-2' style={{width: '40%'}}>
                <input type="time" placeholder="Search by Address" />
            </div>
        </div>
    </div> */}
    <div className="mt-24">
        {data && (
            <DataTableBase 
                title="Error Message After Stop Charging"
                columns={columns2}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="289px"

            />
        )}
    </div>
    </>
    )}
}
