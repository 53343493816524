import React, {useState, useEffect} from 'react';
import DataTableBase from './reuseable/DataTableBase';
import DatePicker from './reuseable/DatePicker';
import StationGraphCard from './reuseable/StationGraphCard';

import Select from 'react-select';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import XLSX from 'xlsx';


const ReportEnergyUsed = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);

    const [data, setData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [loader, setLoader] = useState(true);

    const [filter, setFilter] = useState(null);

    const [dateRange2, setDateRange2] = useState(null);


    useEffect(() => {
        function filterDataByDateRange(startDateStr, endDateStr, data) {
            const startDate = new Date(startDateStr);
            const endDate = new Date(endDateStr);
        
            const filteredData = data.filter((item) => {
            const itemDate = new Date(item.startDate);
            return itemDate >= startDate && itemDate <= endDate;
            });

            console.log("Filterd data set : ", filteredData);


            for(let a= 0; a < filteredData?.length; a++){
                console.log("date : ", filteredData[a]?.startDate)
            }
        
            const dateRevenueSessionMap = {};
        
            filteredData.forEach((item) => {
            const itemDate = new Date(item.startDate).toLocaleDateString();


        
            if (!dateRevenueSessionMap[itemDate]) {
                console.log("(item.energy) : ", item.totalKwh);
                dateRevenueSessionMap[itemDate] = {
                date: itemDate,
                energy: Number(item.totalKwh),
                offPeak: Number(item.offpeakKwh),
                onPeak: Number(item.onpeakKwh),
                session: 1,
                };
            } else {
                dateRevenueSessionMap[itemDate].energy += Number(item.totalKwh);
                dateRevenueSessionMap[itemDate].offPeak += Number(item.offpeakKwh);
                dateRevenueSessionMap[itemDate].onPeak += Number(item.onpeakKwh);
                dateRevenueSessionMap[itemDate].session += 1;
            }
            });
        
            // Convert the map into an array of objects
            const result = Object.values(dateRevenueSessionMap);
        
            return result;
        }

        if(dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today'){
            // Assuming your data is stored in the 'data' array
            
            // Example usage:
            const startDateStr = "2023-01-01";
            const endDateStr = "2023-03-01";
            const filteredData = filterDataByDateRange(dateRange2?.startDate, dateRange2?.endDate, data);
            console.log(filteredData);
            setTableData(filteredData);

            
        } else {
            const tabData = calculateTableData(data);

            console.log("Table Data : ", tabData);

            const revTabData = tabData.reverse();

    

            setTableData(revTabData);
        }
    }, [dateRange2?.startDate])


    const caseInsensitiveSort = (rowA, rowB) => {
        const a = rowA?.month?.toLowerCase();
        const b = rowB?.month?.toLowerCase();

        if(a){
            return 1;
        } else {
            if (a > b) {
                return 1;
            }
    
            if (b > a) {
                return -1;
            }
            
        }

        return 0;
    };


    const caseInsensitiveSort2 = (rowA, rowB) => {
        const a = Math.ceil(rowA?.energy * 100) / 100;
        const b = Math.ceil(rowB?.energy * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };


    const caseInsensitiveSort3 = (rowA, rowB) => {
        const a = Math.ceil(rowA?.offPeak * 100) / 100;
        const b = Math.ceil(rowB?.offPeak * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };

    const caseInsensitiveSort4 = (rowA, rowB) => {
        const a = Math.ceil(rowA?.onPeak * 100) / 100;
        const b = Math.ceil(rowB?.onPeak * 100) / 100;
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };


    const caseInsensitiveSort5 = (rowA, rowB) => {
        const a = Math.ceil(((rowA?.energy / rowA?.session) * 100) / 100);
        const b = Math.ceil(((rowB?.energy / rowB?.session) * 100) / 100);
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };




    const columns = [
        {
            name: dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? "Date" : "Month",
            selector: row => dateRange2?.option === 'Custom Range' || dateRange2?.option === 'Last Month' || dateRange2?.option === 'This Month' || dateRange2?.option === 'Last Week' || dateRange2?.option === 'This Week' || dateRange2?.option === 'Yesterday' || dateRange2?.option === 'Today' ? row?.date : row?.month,
            sortable: true,
            sortFunction: caseInsensitiveSort,
        },
        {
            name: "Total Sessions(Times)",
            selector: row => Math.ceil(row?.session * 100) / 100,
            sortable: true,
        },
        {
            name: "Total Energy Usage (kWh)",
            selector: row => (Math.ceil(row?.energy * 100) / 100).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort2,
        },
        {
            name: "Off Peak (kWh)",
            selector: row => (Math.ceil(row?.offPeak * 100) / 100).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort3,
        },
        {
            name: "On Peak (kWh)",
            selector: row => (Math.ceil(row?.onPeak * 100) / 100).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort4,
        },
        {
            name: "Avg. Energy (kWh)",
            selector: row => (Math.ceil(((row?.energy / row?.session) * 100) / 100)).toLocaleString(),
            sortable: true,
            sortFunction: caseInsensitiveSort5,
        },        
    ];




    useEffect(() => {
        const token = localStorage.getItem('token');
        console.log("token found value is : ", token);
        axios.get("https://api.pea-cpo.com/api/getChargerListCpo", {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("Get Charger list : ", res);
            const opArr = [];

            res?.data?.data && res?.data?.data?.map((item) => {
                opArr.push({value: item?.locationEN, label: item?.locationTH});
            })

            console.log("Options options : ", opArr)
            setOptions(opArr);
            // setStations(res?.data?.data);
            
        }).catch((err) =>{
        console.log("Error get user : ", err);
        })
    }, []) 


    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://api.pea-cpo.com/api/overviewPageChargingList', {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
        console.log("Get Charger detail : ", res);
        
        setData(res?.data?.data);

        const ans= calculateMetrics(res?.data?.data);
        // console.log("Ans data : ", ans);
        setMetrics(ans);
        
        const ans2 = calculateChartData(res?.data?.data);
        console.log("Ans Two data : : ", ans2)
        
        setChartData(ans2);

        const tabData = calculateTableData(res?.data?.data);

        const revTabData = tabData.reverse();

        setTableData(revTabData);

        console.log("table data : ", revTabData)

        setLoader(false);
        
        }).catch((err) =>{
            setLoader(false);
            console.log("Error get user : ", err);
        })
    }, [])


    function calculateMetrics(para){
        const met = {
            energy: 0,
            offPeak: 0,
            onPeak: 0,
            session: 0,
        };

        // para && para.forEach((item) => {
        //     met.energy += Number(item?.totalKwh);
        //     met.offPeak += Number(item?.offpeakKwh);
        //     met.onPeak += Number(item?.onpeakKwh);
        //     met.session += 1;
        // })
        
        
        for(let i=0; i < para?.length; i++){
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }


            if(selectedOption){
                if(selectedOption?.value !== para[i]?.locationEN){
                    continue;
                } 
            }

            met.energy += Number(para[i]?.totalKwh);
            met.offPeak += Number(para[i]?.offpeakKwh);
            met.onPeak += Number(para[i]?.onpeakKwh);
            met.session += 1;

        }

        return met;
    }

    function calculateChartData(para){
        const energyArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        
        for(let i = 0; i < para?.length; i++){
            const env = parseFloat(para[i]?.totalKwh);
            const startDate = new Date(para[i].startDate);

            if (startDate.getFullYear() !== new Date().getFullYear()) {
              continue;
            }

            if(selectedOption){
                if(selectedOption?.value !== para[i]?.locationEN){
                    continue;
                } 
                // else {
                //     // console.log("selected op : ", selectedOption?.value)
                //     // console.log("data name : ", para[i].locationEN)
                //     console.log("data name true...");
                    
                // }
            }

            const month = startDate.getMonth();
            energyArr[month] += env; 
        }

        return energyArr;
    }
    

    useEffect(() => {
        console.log("Filter val 1: ", filter)
        if(filter === 'Today'){
            console.log("Filter val : ", filter);
            const revenueArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            const metrics = {
                energy: 0,
                offPeak: 0,
                onPeak: 0,
                session: 0,
            };

            for (let i = 0; i < data.length; i++) {
                const energy = parseFloat(data[i]?.totalKwh);
                const offpeakKwh = parseFloat(data[i]?.offpeakKwh);
                const onpeakKwh = parseFloat(data[i]?.onpeakKwh);
                const startDate = new Date(data[i]?.startDate);
                const startTime = data[i]?.startTime;

                const today = new Date();
                if(filter === 'Today'){
                    if (
                      startDate.getFullYear() !== today.getFullYear() ||
                      startDate.getMonth() !== today.getMonth() ||
                      startDate.getDate() !== today.getDate()
                    ) {
                      // console.log("Wrong today");
                      continue;
                    }
                }

                if(selectedOption){
                    if(selectedOption?.value !== data[i]?.locationEN){
                        continue;
                    } 
                }

                const hour = Number(startTime.slice(0,2));

                revenueArr[hour] += energy; 

                // metrics.session += 1;
                // metrics.revenue += revenue;

                metrics.energy += energy;
                metrics.offPeak += offpeakKwh;
                metrics.onPeak += onpeakKwh;
                metrics.session += 1;
            }

            console.log("Today Ans data : ", revenueArr);
            setMetrics(metrics);

            console.log("Today Ans Two data : : ", metrics)

            setChartData(revenueArr);
            // setCheckPoint(true);
            
        } else {
            const ans= calculateMetrics(data);
            // console.log("Ans data : ", ans);
            setMetrics(ans);
            
            const ans2 = calculateChartData(data);
            console.log("Ans Two data : : ", ans2)
            
            setChartData(ans2);
            // setCheckPoint(false);
        }
    }, [filter, selectedOption])


    function calculateTableData(para){
        const dumArr = [
            {
                month: 'January',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'February',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'March',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'April',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'May',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'June',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'July',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'August',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'September',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'October',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'November',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
            {
                month: 'December',
                session: 0,
                energy: 0,
                offPeak: 0,
                onPeak: 0,
            },
        ];

        for(let i = 0; i < para?.length; i++){
            const energy = parseFloat(para[i]?.totalKwh);
            const offpeak = parseFloat(para[i]?.offpeakKwh);
            const onpeak = parseFloat(para[i]?.onpeakKwh);
            const startDate = new Date(para[i].startDate);
            const getMon = startDate.getMonth();


            if(dateRange2?.option){
                const startRange = new Date(dateRange2?.startDate);
                const endRange = new Date(dateRange2?.endDate);

                if(startDate >= startRange && startDate <= endRange){

                } else {
                    continue;
                }

            } else {
                if (startDate.getFullYear() !== new Date().getFullYear()) {
                  continue;
                }
            }

            dumArr[getMon].session +=1;
            dumArr[getMon].energy += energy;
            dumArr[getMon].offPeak += offpeak;
            dumArr[getMon].onPeak += onpeak;

            // const month = startDate.getMonth();
            // dumArr[month] += rev; 
        }

        const newArr = [];

        dumArr && dumArr.map(item => {
            if(item?.session > 0){
                newArr.push(item);
            }
        })

        return newArr;
    }



    
    function convertToWorksheet(tableData) {
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        return worksheet;
    }

    function downloadExcelFile(tableData, fileName) {
        const worksheet = convertToWorksheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }

    const now = new Date();

    const isoDateTime = now.toISOString();
    
    function handleDownloadExcel(fileData) {
        // if(dateRange2?.option){
        //     const dumArr = [
        //         {
        //             month: null,
        //             session: 0,
        //             energy: 0,
        //             offPeak: 0,
        //             onPeak: 0,
        //         }
        //     ];


        //     const start = new Date(dateRange2?.startDate);
        //     const end = new Date(dateRange2?.endDate);

        //     data && data.map(obj => {
        //         // console.log("obj : ", obj)
        //         const currentDate = new Date(obj?.startDate);
        //         // const rev = parseFloat(obj?.revenue);

        //         const energy = parseFloat(obj?.totalKwh);
        //         const offpeak = parseFloat(obj?.offpeakKwh);
        //         const onpeak = parseFloat(obj?.onpeakKwh);

        //         if(currentDate >= start && currentDate <= end){
        //             dumArr[0].session +=1;
        //             dumArr[0].energy += energy;
        //             dumArr[0].offPeak += offpeak;
        //             dumArr[0].onPeak += onpeak;    
        //         }

        //     });

        //     dumArr[0].month = `${dateRange2?.startDate}-${dateRange2?.endDate}`
            
        //     console.log("dumArr data : ", dumArr);

        //     downloadExcelFile(dumArr, `Energy Used Report ${isoDateTime}`);
        // } else {
        // }

        const structuredData2 = [];
        const StructureData = fileData?.filter(item => {
            const obj = {"Month": item?.month ? item?.month : item?.date, "Total Sessions(Times)": Number(item?.session), "Total Energy Usage (kWh)": parseFloat((Math.ceil(item?.energy * 100)) / 100), "Off Peak (kWh)": parseFloat((Math.ceil(item?.offPeak * 100) / 100)), "On Peak (kWh)": parseFloat((Math.ceil(item?.onPeak * 100) / 100)), "Avg Price Per Session": parseFloat(Math.ceil((item?.energy / item?.session) * 100) / 100) };
            structuredData2?.push(obj);
            return obj;
            }
        )

        downloadExcelFile(structuredData2, `Energy Used Report ${isoDateTime}`);
        // downloadExcelFile(fileData, `Revenue Report ${isoDateTime}`);
    }




    if(loader){
        return (
            <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '17vh'}}>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#814097"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
            </div>
        )
    } else {


    return (
        <div className='station-revenue'>
            <div className='w-100 d-flex'>
                <div className='w-50'>
                    <h3 className='font-style27 mb-0'>Energy Use</h3>
                    <h6 className='font-style28'>ภาพรวมข้อมูลการใช้พลังงานไฟฟ้า</h6>
                </div>
                <div className='w-50 d-flex justify-content-end'>
                        <Select
                            className="d-inline-block w-50 mt-1" style={{width: '250px', height: '46px'}}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                        />
                    {/* <button className='btn-style1'>Download</button> */}
                </div>
            </div>
            <StationGraphCard 
                val1={<h3 className='graph-card-number'>{(Math.ceil(metrics?.energy * 100) / 100).toLocaleString()} <b className='font-style29'>kWh</b></h3>} 
                val2={<h3 className='graph-card-number'>{(Math.ceil(metrics?.offPeak * 100) / 100).toLocaleString()} <b className='font-style29'>kWh</b></h3>} 
                val3={<h3 className='graph-card-number'>{(Math.ceil(metrics?.onPeak * 100) / 100).toLocaleString()} <b className='font-style29'>kWh</b></h3>} 
                val4={<h3 className='graph-card-number'>{metrics?.session > 0 ? (Math.ceil(((metrics?.energy / metrics?.session) * 100) * 100) / 100).toLocaleString() : 0}<b className='font-style29'>kWh</b></h3>} 
                l1="Total Energy Usage" l2="Off Peak" l3="On Peak" l4="Average Energy per session"  
                cols={4}
                statRev={true}
                label="Energy"
                data2={chartData}
                setFilter={setFilter}
                filter={filter}
                symbol={"kWh"}
                />
            <div>

            <div className='w-100 d-flex justify-content-end'>

            
                <div className='w-252 mb-4 pb-2'>
                    <DatePicker  dateRange={dateRange2} setDateRange={setDateRange2} />

                </div>
                <div className='ps-3'>
                    <button className='btn-style1' onClick={() => handleDownloadExcel(tableData)}>Download</button>
                </div>
            </div>
                {tableData && (
                    <DataTableBase 
                        columns={columns}
                        data={tableData}
                        title="Summary"
                        pagination
                    />
                )}
            </div>
        </div>
    )}
}

export default ReportEnergyUsed;