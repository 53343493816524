import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import ColumnGraph from './ColumnGraph';
import DataTableBase from './reuseable/DataTableBase';
import StationGraphCard from './reuseable/StationGraphCard';
import { format } from 'date-fns';
import calenderIcon from '../assets/calendar.svg';
import LineGraph from './reuseable/LineGraph';
import HeatMapChart from './reuseable/HeatMapChart';
import ColumnGraph2 from './ColumnGraph2';


const StationInsight = () => {
    const [tog, setTog] = useState(1);
    const [display, setDisplay] = useState({
      display:'none'
    })
    const [dateRange, setRange] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
  })
  function toggleDate(){
    if(display.display === 'none')
    setDisplay({display: 'block'})
    else
    setDisplay({display: 'none'})
  }
  function handleSelect(date){
      setRange({ 
          startDate: date.selection.startDate,
          endDate: date.selection.endDate,
          key: date.selection.key
      })
    }

    const columns = [
        {
            name: "Month",
            selector: row => row.month,
            sortable: true,
        },
        {
            name: "Total Energy Usage (kWh)",
            selector: row => row.total_enegry,
            sortable: true,
        },
        {
            name: "Off Peak (kWh)",
            selector: row => row.off_peak,
            sortable: true,
        },
        {
            name: "On Peak (kWh)",
            selector: row => row.on_peak
        },
        {
            name: "Avg. Energy (kWh)",
            selector: row => row.avg_energy,
            sortable: true,
        },        
    ];

    const data = [
        {
            id: 1,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 2,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 3,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 4,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 5,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 6,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
        {
            id: 7,
            month: 'December 2022',
            total_enegry: '500',
            off_peak: '100,000',
            on_peak: '4.680',
            avg_energy: '97,000',
        },        
    ];

    return (
        <div className='station-revenue'>
            <div className='w-100 d-flex'>
                <div className='w-50'>
                    <h3 className='font-style27 mb-0'>Insight</h3>
                    <h6 className='font-style28'>ข้อมูลที่น่าสนใจของสถานี</h6>
                </div>
                <div className='w-50'>
                    {/* <div className='year-filter2' onClick={toggleDate} style={{cursor:'pointer'}}>                    
                        <span className='mb-0 font-style303'>
                        <img src={calenderIcon} className="me-2 ms-3" width="20px" height="20px" alt="" /> 
                        {format(dateRange.startDate,'dd/MM/yyyy')} - {format(dateRange.endDate,'dd/MM/yyyy')}
                        </span>
                    </div>
                    <div style={{...display, position:'absolute', top:'100px', zIndex:'2'}}>
                    <DateRangePicker
                            ranges={[dateRange]}
                            onChange={handleSelect}
                        /> 
                    </div> */}
                    <button className='btn-style1'>Download</button>
                </div>
            </div>
            
            <div className='graph-div2'>
                  
                <div className='w-100 d-flex justify-content-end' style={{position:'relative'}}>
                    {/* <div className='toggles-graph'>
                    <ul className='graphs-ul'>
                        <li className={tog === 1 ? 'graph-active-li' : 'unactive'}
                        onClick={() => setTog(1)}
                        >Today</li>
                        <li className={tog === 2 ? 'graph-active-li' : 'unactive'}
                        onClick={() => setTog(2)}
                        >This Month</li>
                        <li className={tog === 3 ? 'graph-active-li' : 'unactive'}
                        onClick={() => setTog(3)}
                        >This Year</li>
                    </ul>
                    </div> */}
                    
                </div>
                <h2 className='mb-0 font-style39'>Growth Statistics</h2>
                <h4 className='font-style40'>Last 7 days: Jan 1, 2022 - Jan 7, 2022</h4>
                <div className='w-100 d-flex graph-card-wrapper'>
                    <div className={"graph-card border-right"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Total Revenue</h6>
                        {/* <h3 className='graph-card-number'>{val1} </h3> */}
                        <h3 className='graph-card-number'>1,30,200 <b className='font-style29'>THB</b></h3>
                    </div>
                    <div className={"graph-card border-right pl-24"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Total Sessions</h6>
                        {/* <h3 className='graph-card-number'>{val2}</h3> */}
                        <h3 className='graph-card-number'>40,000 <b className='font-style29'>Times</b></h3>
                    </div>
                    <div className={"graph-card border-right pl-24"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Average Price per Session</h6>
                        {/* <h3 className='graph-card-number'>{val3}</h3> */}
                        <h3 className='graph-card-number'>800 <b className='font-style29'>THB</b></h3>
                    </div>
                    <div className={"graph-card pl-24"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Average Time per session</h6>
                        {/* <h3 className='graph-card-number'>{val4}</h3> */}
                        <h3 className='graph-card-number'>78.45<b className='font-style29'>mins</b></h3>
                    </div>
                </div>
                <div className='w-100 d-flex graph-card-wrapper'>
                    <div className={"graph-card border-right"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Total Customers</h6>
                        {/* <h3 className='graph-card-number'>{val1} </h3> */}
                        <h3 className='graph-card-number'>410</h3>
                    </div>
                    <div className={"graph-card border-right pl-24"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Total New Customers</h6>
                        {/* <h3 className='graph-card-number'>{val2}</h3> */}
                        <h3 className='graph-card-number'>40</h3>
                    </div>
                    <div className={"graph-card border-right pl-24"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Total Repeat Customers</h6>
                        {/* <h3 className='graph-card-number'>{val3}</h3> */}
                        <h3 className='graph-card-number'>180 <b className='font-style29'>THB</b></h3>
                    </div>
                    <div className={"graph-card pl-24"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Retention Rate</h6>
                        {/* <h3 className='graph-card-number'>{val4}</h3> */}
                        <h3 className='graph-card-number'>58<b className='font-style29'>%</b></h3>
                    </div>
                </div>
                <div className='w-100'>
                {/* <ColumnGraph tog={tog} /> */}
                <LineGraph labs={false} />
                </div>
            </div>
            
            <div className='graph-div2'>
                  
                <div className='w-100 d-flex justify-content-end' style={{position:'relative'}}>
                </div>
                <h2 className='mb-0 font-style39'>Energy Usage</h2>
                <h4 className='font-style40'>Last 7 days: Jan 1, 2022 - Jan 7, 2022</h4>
                <div className='w-100 d-flex graph-card-wrapper'>
                    <div className={"graph-card border-right"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Average Power</h6>
                        {/* <h3 className='graph-card-number'>{val1} </h3> */}
                        <h3 className='graph-card-number'>60 <b className='font-style29'>kW</b></h3>
                    </div>
                    <div className={"graph-card border-right pl-24"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Peak Power</h6>
                        {/* <h3 className='graph-card-number'>{val2}</h3> */}
                        <h3 className='graph-card-number'>40 <b className='font-style29'>kW</b></h3>
                    </div>
                    <div className={"graph-card border-right pl-24"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Total Energy Usage</h6>
                        {/* <h3 className='graph-card-number'>{val3}</h3> */}
                        <h3 className='graph-card-number'>800 <b className='font-style29'>kW</b></h3>
                    </div>
                    <div className={"graph-card pl-24"}>
                        <h6 className='mb-0 pb-20 graph-card-title'>Utilization Factor</h6>
                        {/* <h3 className='graph-card-number'>{val4}</h3> */}
                        <h3 className='graph-card-number'>40<b className='font-style29'>%</b></h3>
                    </div>
                </div>
                
                <div className='w-100'>
                {/* <ColumnGraph tog={tog} /> */}
                <LineGraph labs={true}  />
                </div>
            </div>
            
            <div className='graph-div2'>                  
                <div className='w-100 d-flex justify-content-end' style={{position:'relative'}}>
                </div>
                <div className='d-flex'>
                    <div className='w-25'>
                        <h2 className='mb-0 font-style39'>Popular Charging Time</h2>
                        <h4 className='font-style40'>Last 7 days: Jan 1, 2022 - Jan 7, 2022</h4>
                    </div>
                    <div className='w-75'>
                        <div>
                            <div>
                                <h4 className='font-style40a d-block'>Charging Time</h4>
                            </div><br/>
                            <div>
                                <div className='d-flex' style={{float: 'right', position: 'relative', right: '-80px'}}>
                                    <div>
                                        <div className='timeline-clrs'></div>
                                        <div>0</div>
                                    </div>
                                    <div >
                                        <div className='timeline-clrs timeline-clrs2'></div>
                                        <div>25</div>
                                    </div>
                                    <div >
                                        <div className='timeline-clrs timeline-clrs3'></div>
                                        <div>50</div>
                                    </div>
                                    <div >
                                        <div className='timeline-clrs timeline-clrs4'></div>
                                        <div>75</div>
                                    </div>
                                    <div >
                                        <div className='timeline-clrs5'></div>
                                        <div>100</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                
                <div className='w-100'>
                <HeatMapChart />
                </div>
            </div>
            
            <div className='graph-div2'>                  
                <div className='w-100 d-flex justify-content-end' style={{position:'relative'}}>
                </div>
                <div className='d-flex'>
                    <div className='w-25'>
                        <h2 className='mb-0 font-style39'>Charging Time Statistics</h2>
                        <h4 className='font-style40'>Last 7 days: Jan 1, 2022 - Jan 7, 2022</h4>
                    </div>
                    <div className='w-75'>
                        
                    </div>
                </div>
                                
                <div className='w-100'>
                    <ColumnGraph2 />
                </div>
            </div>

            {/* <div>
                <DataTableBase 
                    columns={columns}
                    data={data}
                    title="Summary"
                    pagination
                />
            </div> */}
        </div>
    )
}

export default StationInsight;