import React, { useEffect, useState } from "react";
import img1 from '../assets/profile photo.png'
import evStationIcon from '../assets/ev_station1.svg'
import eleServicesIcon from '../assets/electrical_services.svg'
import { Link, Outlet, useParams } from "react-router-dom";
import axios from "axios";
import { BallTriangle } from "react-loader-spinner";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { FiCamera } from 'react-icons/fi';

import SweetAlert from 'react-bootstrap-sweetalert';

const StationDetail = () => {
    const [focusElements, setFocusElements] = useState(['active-tog']);

    const [station, setStation] = useState(null);

    const [image, setImage] = useState(null);

    const [showAlert, setShowAlert] = useState(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { id } = useParams();

    function handleClick(value) { 
        const data = []
        for(var i = 0; i < 9; i++) {
            if(i !== value){
                data[i] = '';
            }else{
                data[value] =  'active-tog'
            }
        }
        setFocusElements(data)
    };

    useEffect(() => {
        const token = localStorage.getItem('token');

        axios.get(`https://api.pea-cpo.com/api/getChargerListCpoById/${id}`, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
        console.log("Get Charger detail : ", res);
        
        setStation(res?.data?.data[0]);

        }).catch((err) =>{
        console.log("Error get user : ", err);
        })
    }, [])

    function handleAlert(){
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 2000)
    }

    function uploadImage(){
        console.log("button clicked : ", image)
        if(image){
            const token = localStorage.getItem('token');
    
            const formData = new FormData();
            formData.append("image", image);
            formData.append("charger_id", id);

            axios.post('https://api.pea-cpo.com/api/updateStationProfileImage', formData, {headers: {'Authorization' : `Bearer ${token}`}}).then((res) => {
            console.log("Update Image : ", res);
            handleClose();
            handleAlert();
    
            }).catch((err) =>{
                handleClose();
                console.log("Error Image update : ", err);
            })
        } 

    }

    

    // console.log("Station : ", station);

    return (
        <>
        <div className="Station-detail">
        {showAlert && (
            <SweetAlert success title="Congratulations!" onConfirm={() => setShowAlert(false)} onCancel={() => setShowAlert(false)}>
                Image Updated Successfully!
            </SweetAlert>
        )}
        <span className="pages-routes1">Chargers List</span>
        <span className="pages-routes2">&#62;</span>
        <span className="pages-routes3">{station?.locationTH}</span>
        <h2 className="station-detail-title">{station?.locationTH}</h2>
        <div className="station-detail-card">
            <div className="d-flex w-100">
                <Modal show={show} style={{marginTop: '25vh'}} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center">Update Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        <label className="mb-2">Upload Profile</label>
                        <input type="file" name="profile" onChange={(e) => setImage(e.target.files[0])} className="form-control" />
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" style={{background: '#814097', border: 'none'}} onClick={uploadImage}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
                <div className="staion-detail-cardimg">
                    {station?.chargerPhoto ? (
                        <>
                            <div className="edit-img-div2" onClick={handleShow}>
                                <FiCamera size={"0.5cm"} color="#000" />
                            </div>
                            <img src={station?.chargerPhoto} style={{marginTop: '-38px'}} height="164px" width="164px" alt="picc" />
                        </>
                    ) : (
                        <div className='w-100' style={{height: '10vh', paddingLeft: 'calc(50% - 50px)', paddingTop: '3vh'}}>
                            <BallTriangle
                                height={70}
                                width={70}
                                radius={5}
                                color="#814097"
                                ariaLabel="ball-triangle-loading"
                                wrapperClass={{}}
                                wrapperStyle=""
                                visible={true}
                            />
                        </div>
                    )}
                </div>
                <div className="staion-detail-carddetail">
                    <h6 className="font-style10">{station?.chargerID}</h6>
                    <h4 className="font-style11">{station?.locationTH}</h4>
                    <h6 className="font-style12">{station?.descriptionTH}</h6>
                    {/* <span className="font-style13">Open</span> */}
                    <span className="font-style14 ms-0">Opening time: {station?.opening?.startTime} - {station?.opening?.endTime}.</span>
                    <div className="mt-24">
                    <span className="font-style15"><img src={evStationIcon} width="15px" height="15px"  alt="picc"/> <span className="font-style16">Total Charger</span><span className="font-style17">0</span> </span> <span className="vertical-line1"></span>
                    <span className="font-style15"><img src={eleServicesIcon} width="15px" height="15px"  alt="picc"/> <span className="font-style16">Total Connector</span><span className="font-style17">{station?.connectors ? station?.connectors.length : 0}</span> </span><span className="vertical-line1"></span>
                    <span className="font-style18">Fee: <span className="font-style19">TOU</span></span>
                    </div>
                </div>
            </div>
            <div className="Station-toggle">
                <ul>
                    <li className={focusElements[0]} >
                        <Link to="overview" onClick={() => handleClick(0)}>Overview</Link>
                    </li>
                    <li className={focusElements[1]} >
                        <Link to="station-setting" onClick={() => handleClick(1)}>Charger Setting</Link>
                    </li>
                    <li className={focusElements[2]} >
                        <Link to="station-control" onClick={() => handleClick(2)}>Charger Control</Link>
                    </li>
                    <li className={focusElements[3]} >
                        <Link to="station-log" onClick={() => handleClick(3)}>Charger Log</Link>
                    </li>
                    <li className={focusElements[4]} >
                        <Link to="revenue" onClick={() => handleClick(4)}>Revenue</Link>
                    </li>
                    <li className={focusElements[5]} >
                        <Link to="transaction" onClick={() => handleClick(5)}>Transaction</Link>
                    </li>
                    <li className={focusElements[6]} >
                    <Link to="energy-use" onClick={() => handleClick(6)}>Energy Use</Link>
                    </li>
                    <li className={focusElements[7]} >
                    <Link to="customer" onClick={() => handleClick(7)}>Customer</Link>                    
                    </li>
                    {/* <li className={focusElements[8]} >
                    <Link to="station-insight" onClick={() => handleClick(8)}>Insight</Link>
                    </li> */}
                </ul>
            </div>
        </div>
        <Outlet/>
        </div>
    </>
    )
}

export default StationDetail;